import { motion } from "framer-motion";
import React from "react";
import { BiMap } from "react-icons/bi";
import { BsBuilding, BsMap } from "react-icons/bs";
import AnimatedText from "../AnimatedText";

function LandingSlide({ activeSlide }) {
  return (
    <div
      className="flex card flex-col justify-around"
      style={{ height: "60vh" }}
    >
      <div className="flex  justify-center  items-center">
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          animate={activeSlide == 0 ? { opacity: 1 } : {}}
          transition={{ duration: 0.4, delay: 1 }}
          className="-mt-3 mr-2"
        >
          <BsBuilding size={20} />
        </motion.span>
        <span>
          <AnimatedText
            className="primary-text-color font-bold"
            animate={activeSlide == 0}
            activeSlide={activeSlide}
            text="Contains 52 sites"
            type="heading1"
          />
        </span>
      </div>

      <div className="flex  justify-center   w-full items-center">
        <motion.span
          initial={{ opacity: 0 }}
          animate={activeSlide == 0 ? { opacity: 1 } : {}}
          transition={{ duration: 0.4, delay: 1 }}
          className="-mt-3 mr-2"
        >
          <BsMap size={20} />
        </motion.span>
        <span>
          <AnimatedText
            className="primary-text-color font-bold"
            animate={activeSlide == 0}
            activeSlide={activeSlide}
            text="Spans over 2,624,837 ft2  of area"
            type="heading1"
          />
        </span>
      </div>

      <div className="flex  justify-center w-full items-center">
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          animate={activeSlide == 0 ? { opacity: 1 } : {}}
          transition={{ duration: 0.4, delay: 1 }}
          className="-mt-3 mr-2"
        >
          <BiMap size={20} />
        </motion.span>
        <span>
          <AnimatedText
            className="primary-text-color font-bold"
            animate={activeSlide == 0}
            activeSlide={activeSlide}
            text="Located at Richmond, VA"
            type="heading1"
          />
        </span>
      </div>
    </div>
  );
}

export default LandingSlide;
