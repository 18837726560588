import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Login from "./components/Login/Login.jsx";
import Dashboard from "./components/Dashboard/Dashboard.jsx";
import Utilities from "./components/Utilities/Utilities";
import MeterChart from "./components/MeterChart/MeterChart";
import {
  BuildingsContext,
  BuildingsProvider,
} from "./Contexts/BuildingsContext";

import ProtectedRoute from "./helpers/ProtectedRoute";
import Signup from "./components/Signup/Signup.jsx";
import Sidebar from "./components/Sidebar/Sidebar";
import Status from "./components/Status/Status.jsx";
import Flow from "./components/Flow/Flow.jsx";
import NotionMainPage from "./components/NotionUx/Notion.MainPage.jsx";
import Notion from "./components/NotionRender/Notion.jsx";
import Display from "./components/Display/Display.jsx";

function App() {
  return (
    <div className="App">
      <BuildingsProvider>
        <Router>
          <Switch>
            <Route exact path="/">
              <Login />
            </Route>
            <Route exact path="/signup">
              <Signup />
            </Route>

            <ProtectedRoute exact path="/dashboard/status/">
              <Status />
            </ProtectedRoute>

            <ProtectedRoute exact path="/dashboard/flow/">
              <Flow />
            </ProtectedRoute>

            <ProtectedRoute exact path="/dashboard/pages/">
              <NotionMainPage />
            </ProtectedRoute>

            <ProtectedRoute exact path="/dashboard/:meter/">
              <MeterChart />
            </ProtectedRoute>

            <ProtectedRoute path="/dashboard/utilities/:utilityMeter">
              <Utilities />
            </ProtectedRoute>

            <ProtectedRoute exact path="/dashboard/:meter/:siteId">
              <MeterChart />
            </ProtectedRoute>

            <ProtectedRoute exact path="/dashboard">
              <Dashboard />
            </ProtectedRoute>
            <ProtectedRoute path={"/notionrender/:notionid"}>
              <Notion />
            </ProtectedRoute>
            <Route exact path="/display">
              <Display />
            </Route>
          </Switch>
        </Router>
      </BuildingsProvider>
    </div>
  );
}

export default App;
