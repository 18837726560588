import React, { useState } from "react";
import { useDrop } from "react-dnd";
import { MdClose, MdRemove } from "react-icons/md";
import { connect } from "react-redux";
import ItemCard from "./ItemCard";

function Lane({
  lane,
  handleDrop,
  handleLaneDelete,
  handleLaneNameChange,
  handleNewItemAdd,
  handleItemClicked,
  handleItemDelete,
  lanes,
  tasks,
  handleItemMove,
  loadingView,
}) {
  const [inputVal, setInputVal] = useState("");

  // const [{ isOver }, dropRef] = useDrop(() => ({
  //   accept: "task",
  //   drop: (item) => {
  //     handleDrop(item, lane, tasks);
  //   },
  //   collect: (monitor) => {
  //     return {
  //       isOver: !!monitor.isOver(),
  //     };
  //   },
  // }));

  return (
    <div
      key={lane.id}
      // ref={dropRef}
      className="lane flex-1 border mx-2 p-2  max-w-sm"
    >
      <div className="lane-name text-gray-400 flex justify-between items-center">
        <span>
          <input
            onChange={(e) => handleLaneNameChange(e, lane)}
            type="text"
            placeholder="Lane Name"
            value={lane.name}
            className="p-1 outline-none"
          />
        </span>
        <span onClick={() => handleLaneDelete(lane)}>
          <MdClose />
        </span>
      </div>

      <ul>
        {tasks.length
          ? tasks
              .filter((x) => x.laneId == lane.value)
              .map((task) => (
                <ItemCard
                  loadingView={loadingView}
                  handleItemDelete={handleItemDelete}
                  handleItemClicked={handleItemClicked}
                  task={task}
                  lanes={lanes}
                  handleItemMove={handleItemMove}
                />
              ))
          : null}
        <li className={"border my-2  cursor-move"}>
          <input
            value={inputVal}
            onChange={(e) => setInputVal(e.target.value)}
            onKeyUp={(e) => {
              console.log(e.key);
              if (e.key == "Enter") {
                setInputVal("");
              }
              handleNewItemAdd(e, lane);
            }}
            type="text"
            placeholder="+ NEW"
            className="outline-none px-1 text-xs text-gray-400"
          />
        </li>
      </ul>
    </div>
  );
}

export default Lane;
