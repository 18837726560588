import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Chart, registerables } from "chart.js";
import { CategoryScale } from "chart.js";
import "./MetersCard.css";
import { connect } from "react-redux";
import { FaRegBuilding } from "react-icons/fa";
import { AiOutlineNodeIndex } from "react-icons/ai";
import { BiCube } from "react-icons/bi";
const allChartTypes = ["line", "pie", "bar"];

Chart.register(...registerables);

export const initialChartOptions = {
  plugins: {
    legend: {
      display: false,
    },
    chartAreaBorder: {
      borderWidth: 0,
    },
  },

  maintainAspectRatio: false,

  scales: {
    x: {
      ticks: {
        display: false,
      },
      beginAtZero: true,

      grid: {
        display: false,
        drawBorder: false,
      },
    },

    y: {
      ticks: {
        display: false,
      },

      beginAtZero: true,
      grid: {
        display: false,
        drawBorder: false,
      },
    },
  },

  layout: {
    padding: 0,
  },
};

function MetersCard({
  meter,
  meterName,
  timeFrameLabel,
  equipmentCounts,
  selectedSite,
  allSites,
  color,
  chartBgColor,
  points,
  loadingSiteData,
  loading,
}) {
  const [chartType, setChartType] = useState(
    // allChartTypes[Math.floor(Math.random() * 3)]
    meter == "kwh" ? "bar" : meter == "steam" ? "line" : "line"
  );

  const [chartInstance, setChartInstance] = useState({});

  const [chartOptions, setChartOptions] = useState({ ...initialChartOptions });

  const generateRandomGraph = () => {
    let dataForMeter = [];
    const data = new Array(12).fill("");

    if (Object.keys(chartInstance).length) {
      try {
        chartInstance.destroy();
      } catch (e) {
        console.log("Cannot clear Instance");
      }
    }

    const ctxDom = document.getElementById(`chart-${meter}`);

    if (!ctxDom) return;
    const ctx = ctxDom.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 450);
    gradient.addColorStop(0, chartBgColor);
    gradient.addColorStop(0.5, "rgba(255, 255, 255, 0.2)");
    gradient.addColorStop(1, "rgba(255, 0, 0, 0)");

    for (let i in data) {
      data[i] = Math.abs(Math.floor(Math.random() * (0 - 10 + 1)) + 0);
    }

    dataForMeter = {
      labels: [
        "12:00 am",
        "1:00 am",
        "2:00 am",
        "3:00 am",
        "4:00am",
        "5:00 am",
        "6:00 m",
        "7:00 am",
        "8:00 am",
        "9:00 m",
        "10:00 am",
        "11:00 am",
      ],
      datasets: [
        {
          label: `${meter}`,
          data: data,
          fillColor: color,
          fill: true,
          lineTension: 0.2,
          backgroundColor: gradient,
          borderColor: color,
          // pointBackgroundColor: color,
          // pointBorderColor: "#fff",
          // pointBorderWidth: 5,
          pointRadius: 0,
          pointHoverRadius: 5,
          borderWidth: 3,
          lineTension: 0.5,
          fill: true,
        },
      ],
    };

    const chart = new Chart(ctx, {
      type: "line",
      options: chartOptions,
      data: dataForMeter,
    });

    setChartInstance(chart);
  };

  useEffect(() => {
    if (!(loadingSiteData || loading)) {
      generateRandomGraph();
    }
  }, [loadingSiteData, loading]);

  const metercardInfo = [
    {
      icon: <FaRegBuilding color="#0EA4E7" size="25px" />,
      label: "Buildings",
      value:
        meter == "kwh"
          ? allSites?.filter((x) => x.elecMeterId).length
          : meter == "steam"
          ? allSites?.filter((x) => x.steamMeterId).length
          : null,
    },
    {
      icon: <BiCube color="#0EA4E7" size="25px" />,

      label: "Equipments",
      value: equipmentCounts[`${meter + "Count"}`],
    },

    // {
    //   icon: <AiOutlineNodeIndex color="#0EA4E7" size="25px" />,
    //   label: "Points",
    //   value: points[`${meter + "Points"}`]
    //     ? points[`${meter + "Points"}`].aggregate.count
    //     : "",
    // },
  ];

  if (loadingSiteData || loading) {
    return (
      <div className="bg-white metercard text-left flex flex-col justify-between">
        <div className="flex justify-between">
          <h1 className="uppercase primary-text-color">
            <span className="mr-2 font-bold text-transparent bg-gray-300 animate-pulse">
              sknfksfnd{" "}
            </span>
            <span className="text-transparent bg-gray-300 animate-pulse rounded rounded-full badge  text-xs">
              {equipmentCounts[`${meter + "Count"}`]}
            </span>
          </h1>
          <p className="m-0 text-sm  text-transparent bg-gray-300 animate-pulse">
            Today
          </p>
        </div>

        <div className="chart -mb-8 mt-2">
          <div className="h-full text-transparent bg-gray-300 animate-pulse">
            <canvas></canvas>
          </div>
        </div>

        <div style={{ border: "1px solid #DBDFF1" }} className="mb-2" />

        <div className="flex items-center h-10 justify-between">
          {metercardInfo.map((info) => (
            <div className="info flex items-center">
              <div className="icon m-0 text-transparent bg-gray-300 animate-pulse">
                {/* {info.icon} */}
              </div>
              <div>
                <h1 className=" value m-0 font-bold text-transparent bg-gray-300 animate-pulse">
                  {info.value}
                </h1>
                <p className="label m-0  text-transparent bg-gray-300 animate-pulse">
                  {info.label}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <Link
      to={{
        pathname: `/dashboard/${meter}/${
          selectedSite.id ? selectedSite.id : ""
        }`,
      }}
    >
      <div className="bg-white metercard text-left flex flex-col justify-between ">
        <div className="flex justify-between">
          <h1 className="uppercase primary-text-color">
            <span className="mr-2 font-bold"> {meterName} </span>
            <span
              style={{ backgroundColor: color }}
              className="rounded rounded-full badge text-white text-xs"
            >
              {equipmentCounts[`${meter + "Count"}`]}
            </span>
          </h1>
          <p className="m-0 text-sm secondary-text-color">Today</p>
        </div>

        <div className="chart -mb-8 mt-2">
          <div className="h-full">
            <canvas id={`chart-${meter}`}></canvas>
          </div>
        </div>

        <div style={{ border: "1px solid #DBDFF1" }} className="mb-2" />

        <div className="flex items-center h-10 justify-between">
          {metercardInfo.map((info) => (
            <div className="info flex items-center">
              <div className="icon m-0">{info.icon}</div>
              <div>
                <h1 className="primary-text-color value m-0 font-bold">
                  {info.value}
                </h1>
                <p className="label m-0 secondary-text-color ">{info.label}</p>
              </div>
            </div>
          ))}

          <div className="info flex items-center">
            <div>
              <button
                type="button"
                className="inline-flex items-center px-3.5 py-2 border border-transparent text-xs leading-4 font-medium rounded-full shadow-sm text-white "
              >
                Details
              </button>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

const mapStateToProps = (state) => {
  return {
    allSites: state.buildings.allSites,
    equipmentCounts: state.buildings.equipmentCounts.count,
    points: state.buildings.equipmentCounts.points,
    selectedSite: state.buildings.selectedSite,
    loadingSiteData: state.buildings.loadingSiteData,
  };
};

export default connect(mapStateToProps)(MetersCard);
