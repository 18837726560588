import React, { useEffect, useState } from "react";
import { Progress } from "antd";
import { eqOccStatus } from "../../API/API";
import { motion } from "framer-motion";

function EqOccupancy({ activeSlide, index }) {
  const [equipments, setEquipments] = useState([]);

  useEffect(() => {
    (async () => {
      const eqRes = await eqOccStatus();
      const eqData = eqRes.data.data;

      // Extract equipment response
      let equipments = [
        {
          name: "VAV",
          total: eqData.vavTotal.aggregate.count,
          occ: eqData.vavOcc.aggregate.count,
        },
        {
          name: "FCU",
          total: eqData.fcuTotal.aggregate.count,
          occ: eqData.fcuOcc.aggregate.count,
        },
        {
          name: "AHU",
          total: eqData.ahuTotal.aggregate.count,
          occ: eqData.ahuOcc.aggregate.count,
        },
        {
          name: "Boiler",
          total: eqData.boilerTotal.aggregate.count,
          occ: eqData.boilerOcc.aggregate.count,
        },
        {
          name: "Chiller",
          total: eqData.chillerTotal.aggregate.count,
          occ: eqData.chillerOcc.aggregate.count,
        },
      ];
      setEquipments(equipments);
    })();
  }, []);

  return (
    <div style={{ height: "60vh" }} className="flex card p-3 flex-wrap">
      {equipments.map((x, idx) => (
        <motion.div
          animate={activeSlide == index ? { opacity: 1, y: 0 } : {}}
          initial={{ opacity: 0, y: "100px" }}
          transition={{ duration: 1, delay: idx / 3 + 1 }}
          className="my-5"
          style={idx < 2 ? { width: "50%" } : { width: "33%" }}
        >
          <>
            <h2 className="text-3xl">No of {x.name} Occupied</h2>
            <Progress
              type="circle"
              width="200px"
              size={100}
              strokeWidth={10}
              strokeColor={{
                "0%": "#108ee9",
                "100%": "#87d068",
              }}
              percent={Math.floor((x.occ / x.total) * 100)}
            />
          </>
        </motion.div>
      ))}
    </div>
  );
}

export default EqOccupancy;
