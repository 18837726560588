import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { getKwh } from "../../API/API";

function OldVsNewDorm({ index, sites, activeSlide }) {
  useEffect(() => {
    (async () => {
      if (activeSlide == index) {
        console.log(sites);
        const dennisHall = sites.filter((x) => x.navName == "Dennis Hall");
        const jeterHall = sites.filter((x) => x.navName == "Jeter Hall");

        console.log(dennisHall, jeterHall);
        const dennishallKwh = await getKwh(
          { startDate: "2022-02-24", endDate: "2022-03-02", breakdown: "day" },
          dennisHall[0].id,
          null
        );
        const jeterHallKwh = await getKwh(
          { startDate: "2022-02-24", endDate: "2022-03-02", breakdown: "day" },
          jeterHall[0].id,
          null
        );

        console.log(jeterHallKwh, dennishallKwh);
      }
    })();
  }, []);

  return (
    <motion.div
      animate={activeSlide == index ? { opacity: 1, scaleY: 1 } : {}}
      initial={{ opacity: 0, scaleY: 0 }}
      transition={{ duration: 1 }}
      className="card p-3"
      style={{ height: "50vh", originY: 1 }}
    >
      skdnf
    </motion.div>
  );
}

export default OldVsNewDorm;
