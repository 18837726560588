import React from "react";
import BuildingDetails from "../../Slides/BuildingDetails";
import CostCons from "../../Slides/CostCons";
import EfficentSitesByKwh from "../../Slides/EfficentSitesByKwh";
import EqOccupancy from "../../Slides/EqOccupancy";
import KwhToday from "../../Slides/KwhToday";
import KwhVsWeatherForWeek from "../../Slides/KwhVsWeatherForWeek";
import LandingSlide from "../../Slides/LandingSlide";
import OldVsNewDorm from "../../Slides/OldVsNewDorm";
import SteamToday from "../../Slides/SteamToday";

function DisplaySlideContent({ id, activeSlide, sites, index }) {
  if (id == "landingSlide") return <LandingSlide activeSlide={activeSlide} />;
  if (id == "kwhToday")
    return <KwhToday index={index} sites={sites} activeSlide={activeSlide} />;
  if (id == "steamToday")
    return <SteamToday index={index} sites={sites} activeSlide={activeSlide} />;
  if (id == "costCons")
    return <CostCons index={index} sites={sites} activeSlide={activeSlide} />;
  if (id == "buildingDetails")
    return (
      <BuildingDetails index={index} sites={sites} activeSlide={activeSlide} />
    );
  if (id == "efficientSites")
    return <EfficentSitesByKwh index={index} activeSlide={activeSlide} />;
  if (id == "kwhVsWeather")
    return <KwhVsWeatherForWeek index={index} activeSlide={activeSlide} />;
  if (id == "eqOcc")
    return <EqOccupancy index={index} activeSlide={activeSlide} />;
  if (id == "oldVsNewDorm")
    return (
      <OldVsNewDorm sites={sites} index={index} activeSlide={activeSlide} />
    );
  // if (id == "oldVsNewDorm")
  //   return <OldVsNewDorm index={index} activeSlide={activeSlide} />;

  return <div className="card p-3">No Content Found</div>;
}

export default DisplaySlideContent;
