import React, { useEffect, useState } from "react";
import Header from "../../Resueables/Header/Header";
import SingleChart from "../SingleChart/SingleChart";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useLocation, useParams } from "react-router-dom";
import MultipleCharts from "../MultipleCharts/MultipleCharts";
import {
  processEquipmentData,
  processEquipmentPoints,
} from "../../helpers/processEquipmentData";
import { getAllEquipCountsAndOccupyStatus } from "../../helpers/getAllEquipCounts";
import UniversalSearch from "../../Resueables/UniversalSearch";
import "./MeterChart.css";
import BuildingDetailsScreen from "../BuildingDetailsScreen/BuildingDetailsScreen";
import { classNames } from "../../helpers/classNames";
import { CgDetailsMore } from "react-icons/cg";
import LeftSidePanel from "./LeftSidePanel";
import {
  getAhuDataForADay,
  getBoilerDataForADay,
  getChillerDataForADay,
  getEqStatus,
  getFcuDataForADay,
  getKwh,
  getSteam,
  getVavDataForADay,
} from "../API/API";
import { connect } from "react-redux";
import MultipleSitesChart from "../MultipleSitesChart/MultipleSitesChart";
import RightSidePanel from "./RightSidePanel";
import ForcedGraph from "../ForcedGraph/ForcedGraph";
import NotionMainPage from "../NotionUx/Notion.MainPage";
import ChartToggleBtn from "./ChartToggleBtn";
import { columns } from "../NotionUx/configs";
import EquipmentDetailsModal from "./EquipmentDetailsModal";
import Modal from "antd/lib/modal/Modal";

function MeterChart({ selectedSite, allSites, time }) {
  const params = useParams();

  const location = useLocation();

  // const [meterOptions, setMeterOptions] = useState([...allMeterOptions]);

  const [isEquipment, setIsEquipment] = useState(false);
  const [meterData, setMeterData] = useState({});

  const [equipCharts, setEquipCharts] = useState([]);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    label: "Today",
  });

  const [weatherData, setWeatherData] = useState({});

  const [showTimePanel, setShowTimePanel] = useState(false);

  const [detailsPanelOpen, setDetailsPanelOpen] = useState(false);

  const [occupyAndCount, setOccupyAndCount] = useState({});

  const [loadingSomething, setLoadingSomething] = useState(false);

  const [resize, setResize] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [forcedGraphView, setForcedGraphView] = useState(false);
  const [detailsPanelFull, setDetailsPanelFull] = useState(false);

  // ------ FUNCTIONS FOR THE SIDEPANELS ------ ///
  useEffect(() => {
    (async () => {
      if (detailsPanelOpen && !Object.keys(occupyAndCount).length) {
        const eqOccAndCount = await getEqStatus(selectedSite.id);
        setOccupyAndCount(
          getAllEquipCountsAndOccupyStatus(eqOccAndCount.data.data.equip)
        );
      }
    })();
  }, [detailsPanelOpen]);

  ////------FUNCTIONS FOR SIDEPANELS ENDS --------///

  useEffect(() => {
    (async () => {
      setLoadingSomething(true);
      let meterData, weather;

      const startDate = time.startDate;
      const endDate = time.endDate;

      if (params.meter == "kwh") {
        setIsEquipment(false);
        const kwh = await getKwh(
          time,
          selectedSite.id,
          selectedSite.isAGroup ? selectedSite.value : null
        );

        if (selectedSite.isAGroup) {
          meterData = kwh.data.data.meterData;
        } else {
          meterData = kwh.data.data.meterData[0];
        }

        setLoadingSomething(false);
      }

      if (params.meter == "steam") {
        setIsEquipment(false);
        const steamAndWeather = await getSteam(
          time,
          selectedSite.id,
          selectedSite.isAGroup ? selectedSite.value : null
        );

        if (selectedSite.isAGroup) {
          meterData = steamAndWeather.data.data.meterData;
        } else {
          meterData = steamAndWeather.data.data.meterData[0];
        }
        setLoadingSomething(false);
      }

      if (params.meter == "vav") {
        setIsEquipment(true);
        setLoadingSomething(true);
        console.log(startDate, endDate);
        const vavData = await getVavDataForADay(
          startDate,
          endDate,
          selectedSite.id,
          selectedSite.isAGroup ? selectedSite.value : null,
          ["vav"]
        );

        const vavCharts = processEquipmentPoints(vavData.data.data);

        setEquipCharts(vavCharts);
        setLoadingSomething(false);
      }

      if (params.meter == "ahu") {
        setIsEquipment(true);
        setLoadingSomething(true);
        const ahuData = await getAhuDataForADay(
          startDate,
          endDate,
          selectedSite.id,
          selectedSite.isAGroup ? selectedSite.value : null,
          ["ahu"]
        );

        console.log("AHU DATA", ahuData);
        const ahuCharts = processEquipmentPoints(ahuData.data.data);
        // console.log(ahuCharts);
        setEquipCharts(ahuCharts);
        setLoadingSomething(false);
      }

      if (params.meter == "fcu") {
        setIsEquipment(true);
        setLoadingSomething(true);
        const fcuData = await getFcuDataForADay(
          startDate,
          endDate,
          selectedSite.id,
          selectedSite.isAGroup ? selectedSite.value : null,
          ["fcu"]
        );
        const fcuCharts = processEquipmentPoints(fcuData.data.data);
        setEquipCharts(fcuCharts);
        setLoadingSomething(false);
      }

      if (params.meter == "chiller") {
        setIsEquipment(true);
        setLoadingSomething(true);
        const chillerData = await getChillerDataForADay(
          startDate,
          endDate,
          selectedSite.id,
          selectedSite.isAGroup ? selectedSite.value : null,
          ["chiller"]
        );
        const chillerCharts = processEquipmentPoints(chillerData.data.data);

        setEquipCharts(chillerCharts);
        setLoadingSomething(false);
      }

      if (params.meter == "boiler") {
        setLoadingSomething(true);
        setIsEquipment(true);
        const heatExchangeData = await getBoilerDataForADay(
          startDate,
          endDate,
          selectedSite.id,
          selectedSite.isAGroup ? selectedSite.value : null,
          ["boilerPlant"]
        );
        const heatExchangeCharts = processEquipmentPoints(
          heatExchangeData.data.data
        );
        setEquipCharts(heatExchangeCharts);
        setLoadingSomething(false);
      }

      setMeterData(meterData);
    })();
  }, [params, selectedSite, time]);

  const handleEquipmentDetailsShow = () => {
    setModalOpen(true);
  };

  return (
    <div className="flex">
      <div className="container mx-auto w-10/12">
        <Header />

        <div className="">
          <div>
            <div className="mt-10" />

            <UniversalSearch
              auotSwitch={true}
              forcedGraphView={forcedGraphView}
              detailsPanelOpen={detailsPanelOpen}
              showTimePanel={showTimePanel}
              setShowTimePanel={setShowTimePanel}
            />
            <div className="mt-10" />
            <div className="flex  justify-center w-full relative">
              {detailsPanelOpen && !detailsPanelFull && (
                <div
                  className="panel  p-4 left mr-2 slide-in-left bg-white rounded rounded-xl"
                  style={{ width: "25%" }}
                >
                  <LeftSidePanel
                    allSites={allSites}
                    selectedSite={selectedSite}
                    occupyAndCount={occupyAndCount}
                  />
                </div>
              )}

              <div
                className={classNames(
                  "transition-all",
                  detailsPanelOpen ? "w-1/2" : "w-full",
                  detailsPanelFull ? "w-full" : null
                )}
              >
                <div
                  className={classNames(
                    "rounded  rounded-2xl bg-white p-5 mb-20",
                    params.meter == "buildingdetails" ? "w-9/12 mx-auto" : ""
                  )}
                >
                  <div
                    className={classNames(
                      "header flex justify-around items-center"
                    )}
                  >
                    {!forcedGraphView && (
                      <div className="color-darkblue font-bold text-lg mr-auto">
                        {params.meter == "buildingdetails" ? (
                          <>Building Details</>
                        ) : (
                          <>{params.meter.toUpperCase() + " Usage"}</>
                        )}
                      </div>
                    )}

                    {!forcedGraphView && !detailsPanelFull && (
                      <div className="flex items-center">
                        {params.meter != "buildingdetails" && (
                          <div className="mr-5">
                            <button
                              type="button"
                              onClick={() =>
                                !detailsPanelOpen
                                  ? setDetailsPanelOpen(true)
                                  : setDetailsPanelFull((prev) => !prev)
                              }
                              className="inline-flex items-center text-xs px-3 py-2 border border-gray-300 shadow-sm font-medium  leading-4 font-medium rounded-md  bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-black"
                            >
                              {detailsPanelOpen ? "Full Page" : "Details"}
                              <CgDetailsMore
                                className="ml-3 -mr-1 h-5 w-5 text-xs"
                                color="#000"
                              />
                            </button>
                          </div>
                        )}

                        <div className="mr-5">
                          <button
                            onClick={handleEquipmentDetailsShow}
                            type="button"
                            className="inline-flex items-center text-xs px-3 py-2 border border-gray-300 shadow-sm font-medium  leading-4 font-medium rounded-md  bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-black"
                          >
                            Equipment Detiails
                            <CgDetailsMore
                              className="ml-3 -mr-1 h-5 w-5 text-xs"
                              color="#000"
                            />
                          </button>
                          {/* <ChartToggleBtn
                            forcedGraphView={forcedGraphView}
                            setForcedGraphView={setForcedGraphView}
                          /> */}
                        </div>
                      </div>
                    )}
                  </div>

                  {!forcedGraphView && !detailsPanelFull && (
                    <div className="mt-20" />
                  )}

                  <div className="chart-wrapper">
                    <>
                      {/* {forcedGraphView ? (
                        <div>
                          <div className="flex  absolute right-10 z-10 top-5 justify-end ml-auto">
                            <ChartToggleBtn
                              forcedGraphView={forcedGraphView}
                              setForcedGraphView={setForcedGraphView}
                            />
                          </div>
                          <ForcedGraph />{" "}
                        </div>
                      ) : null} */}

                      {isEquipment && !forcedGraphView ? (
                        <MultipleCharts
                          loadingSomething={loadingSomething}
                          equipCharts={equipCharts}
                          detailsPanelOpen={detailsPanelOpen}
                        />
                      ) : (
                        params.meter !== "buildingdetails" &&
                        !forcedGraphView &&
                        params.siteId && (
                          <SingleChart
                            detailsPanelOpen={detailsPanelOpen}
                            loadingSomething={loadingSomething}
                            meterData={meterData}
                            selectionRange={selectionRange}
                          />
                        )
                      )}

                      {params.siteId == null &&
                        selectedSite.isAGroup &&
                        !forcedGraphView &&
                        params.meter != "buildingdetails" && (
                          <h1>
                            <MultipleSitesChart
                              loadingSomething={loadingSomething}
                              isEquipment={isEquipment}
                              meterData={meterData}
                            />
                          </h1>
                        )}

                      {params.meter == "buildingdetails" && (
                        <BuildingDetailsScreen />
                      )}
                    </>
                  </div>
                </div>
              </div>

              {detailsPanelOpen && !detailsPanelFull && (
                <div
                  style={{ width: "25%" }}
                  className="panel  p-4 right ml-2 slide-in-right  bg-white  rounded rounded-xl"
                >
                  <RightSidePanel />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        width="60%"
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        title={`${params.meter.toUpperCase()} Details`}
        visible={modalOpen}
        footer={() => null}
      >
        <EquipmentDetailsModal opnen={modalOpen} selectedSite={selectedSite} />
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    allSites: state.buildings.allSites,
    selectedSite: state.buildings.selectedSite,
    time: state.search.universalSearchState.time,
  };
};

export default connect(mapStateToProps)(MeterChart);
