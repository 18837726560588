import {
  ADD_LANE,
  ADD_TASK,
  CHANGE_UNIVERSAL_SEARCH_DATA,
  DELETE_LANE,
  DELETE_TASK,
  EDIT_LANE,
  EDIT_TASK,
  GET_ALL_TASKS,
  HANDLE_METER_CHANGE,
  HANDLE_METRIC_MULTI_SELECT,
  HANDLE_TIME_CHANGE,
  RESET_SEARCH_STATE,
  SET_ALL_EQUIPMENTS,
  SET_ALL_POINTS,
  SET_ALL_SITES,
  SET_ALL_UTILITY_METERS,
  SET_COST_ACTIVE,
  SET_ERROR_MODAL,
  SET_LANES,
  SET_LOADING_SITE_DATA,
  SET_SELECTED_SITE,
  SET_SPLASH_SCREEN,
  SET_SPLASH_SHOWN,
  SET_TASKS,
} from "./actionTypes";

export const setAllSites = (payload) => {
  return {
    type: SET_ALL_SITES,
    payload: payload,
  };
};

export const setAllEquipments = (payload) => {
  return {
    type: SET_ALL_EQUIPMENTS,
    payload: payload,
  };
};

export const setAllPoints = (payload) => {
  return {
    type: SET_ALL_POINTS,
    payload: payload,
  };
};

export const handleSiteSelected = (payload) => {
  return {
    type: SET_SELECTED_SITE,
    payload: payload,
  };
};
export const setErrorModal = (payload) => {
  return {
    type: SET_ERROR_MODAL,
    payload: payload,
  };
};

export const handleMeterStateChange = (payload) => {
  return {
    type: HANDLE_METER_CHANGE,
    payload: payload,
  };
};

export const handleTimeStateChange = (payload) => {
  return {
    type: HANDLE_TIME_CHANGE,
    payload: payload,
  };
};

export const setLoadingSiteData = (payload) => {
  return {
    type: SET_LOADING_SITE_DATA,
    payload: payload,
  };
};

export const setCostActive = (payload) => {
  return {
    type: SET_COST_ACTIVE,
    payload: payload,
  };
};

export const setShowSplashScreen = (payload) => {
  return {
    type: SET_SPLASH_SCREEN,
    payload: payload,
  };
};
export const setSplashShown = (payload) => {
  return {
    type: SET_SPLASH_SHOWN,
    payload: payload,
  };
};

export const handleMetricMultiSelect = (payload) => {
  return {
    type: HANDLE_METRIC_MULTI_SELECT,
    payload: payload,
  };
};

export const resetSearchState = () => {
  return {
    type: RESET_SEARCH_STATE,
  };
};

export const setAllUtilityMeters = (payload) => {
  return {
    type: SET_ALL_UTILITY_METERS,
    payload: payload,
  };
};

// LANE ACTIONS
export const addLane = (payload) => {
  return {
    type: ADD_LANE,
    payload: payload,
  };
};

export const editLane = (payload) => {
  return {
    type: EDIT_LANE,
    payload: payload,
  };
};

export const deleteLane = (payload) => {
  return {
    type: DELETE_LANE,
    payload: payload,
  };
};

export const setLanes = (payload) => {
  return {
    type: SET_LANES,
    payload: payload,
  };
};

// TASK ACTIONS

export const addTask = (payload) => {
  return {
    type: ADD_TASK,
    payload: payload,
  };
};

export const editTask = (payload) => {
  return {
    type: EDIT_TASK,
    payload: payload,
  };
};

export const deleteTask = (payload) => {
  return {
    type: DELETE_TASK,
    payload: payload,
  };
};

export const setTasks = (payload) => {
  return {
    type: SET_TASKS,
    payload: payload,
  };
};
