import React, { useState } from "react";
import { dbOptions, tableData } from "./configs";
import TableCustom from "./Layouts/TableLayout/Table";
import List from "./Layouts/List";
import BoardLayout from "./Layouts/BoardLayout/BoardLayout";
import CalendarLayout from "./Layouts/Calendar";
import { Select } from "antd";
import { Option } from "antd/lib/mentions";

function Databases({ selectedDB, selectedPage, setSelectedDB, allSites }) {
  return (
    <div className="w-5/6 relative">
      <div className="flex-2   relative transition mx-10">
        {selectedDB !== null && (
          <div>
            <div
              className="close text-right flex text-4xl cursor-pointer  ml-auto w-10"
              onClick={() => setSelectedDB(null)}
            >
              &times;
            </div>
          </div>
        )}
        {selectedDB && (
          <div className="absolute top-0 left-0">
            <Select
              defaultValue={selectedDB}
              style={{ width: 120 }}
              onChange={(e) => setSelectedDB(e)}
            >
              {dbOptions.map((x) => (
                <Option value={x.value}>{x.name}</Option>
              ))}
            </Select>
          </div>
        )}

        {selectedDB !== null ? (
          selectedDB == "table" ? (
            <TableCustom selectedPage={selectedPage} />
          ) : selectedDB == "list" ? (
            <List selectedPage={selectedPage} list={true} />
          ) : selectedDB == "gallery" ? (
            <List selectedPage={selectedPage} gallery={true} />
          ) : selectedDB == "board" ? (
            <BoardLayout selectedPage={selectedPage} />
          ) : selectedDB == "calendar" ? (
            <CalendarLayout selectedPage={selectedPage} />
          ) : null
        ) : (
          <>
            <div className="flex">
              {Object.keys(selectedPage).length ? (
                <div className="flex-2  transition mx-10">
                  <h1 className="mb-4">Select a Databse Type </h1>
                  <div className="flex flex-wrap">
                    {dbOptions.map((db) => (
                      <div
                        onClick={() => setSelectedDB(db.value)}
                        key={db.name}
                        className="flex w-1/2  items-center px-2 py-2 my-2 cursor-pointer bg-transparent hover:bg-gray-100 transition  text-gray-600 hover:text-gray-900"
                      >
                        <div className="icon mr-2">{db.icon}</div>
                        <p className="m-0">{db.name}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="w-full absolute left-0 top-0  h-full flex items-center justify-center">
                  Select or Create a new page from the menu, to get started
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Databases;
