import React from "react";

function MaxMinAvgFeed({ chart, unit }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <ul className="-mb-8">
      {chart &&
        chart.metaData &&
        chart.metaData.map((event, eventIdx) => (
          <li key={eventIdx}>
            <div className="relative pb-8">
              {eventIdx !== chart.metaData.length - 1 ? (
                <span
                  className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <span
                    className={classNames(
                      event.iconBackground,
                      "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                    )}
                  >
                    <event.icon
                      className="h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                  <div>
                    <p className="text-sm text-gray-500">
                      <span className="font-medium text-gray-900">
                        {event.label} :
                      </span>
                    </p>
                  </div>

                  <div className="text-right text-sm whitespace-nowrap text-gray-500">
                    {Math.round(event.val)} {chart.unit ? chart.unit : unit}
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
    </ul>
  );
}

export default MaxMinAvgFeed;
