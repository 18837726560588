import React, { Fragment, useEffect, useState } from "react";
import { Popover, Transition, Dialog } from "@headlessui/react";
import { classNames } from "../../../helpers/classNames";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import { Menu } from "@headlessui/react";
import { DatePicker } from "antd";
import "antd/dist/antd.css";
import useSelection from "antd/lib/table/hooks/useSelection";
import moment from "moment";
import { useLocation } from "react-router-dom";
import UniversalSearch from "..";

interface Props {}

function TimePopOver(props: Props) {
  const location = useLocation();
  const {
    panelToggleHandler,
    timeBtnRef,
    checkIfPanelIsOpen,
    // timeOptions,
    handleTimeSelect,
    timeLabel,
    // btnLabel,
    // showPanel,
    // setShowPanel,
    showTimePicker,
    setShowTimePicker,
    panelOpen,

    timeFrames,
    isUtilitySearchBar,
    universalSearchState,
  } = props;

  const onTimeChange = (momVal, dateStr, time) => {
    const currentYear = moment().format("YYYY");
    const currentMonth = moment().format("MM");
    timeBtnRef.current.click();

    let startDate, endDate, labelToShow, range, breakdown;

    if (time.datePickerVal == "year") {
      range = "year";
      breakdown = "month";
      labelToShow = moment(momVal).format("YYYY");

      if (moment(momVal).format("YYYY") == currentYear) {
        startDate = `${currentYear}-01-01`;
        endDate = moment().format("YYYY-MM-DD");
      } else {
        startDate = `${moment(momVal).format("YYYY")}-01-01`;
        endDate = `${moment(momVal).format("YYYY")}-12-31`;
      }
    }

    if (time.datePickerVal == "month") {
      range = "month";
      breakdown = "day";
      labelToShow = moment(momVal).format("MMMM");
      if (
        moment(momVal).format("MM") == currentMonth &&
        moment(momVal).format("YYYY") == currentYear
      ) {
        startDate = `${currentYear}-${currentMonth}-01`;
        endDate = moment().format("YYYY-MM-DD");
      } else {
        startDate = `${moment(momVal).format("YYYY")}-${moment(momVal).format(
          "MM"
        )}-01`;
        endDate = `${moment(momVal).format("YYYY")}-${moment(momVal).format(
          "MM"
        )}-31`;
      }
    }

    if (time.datePickerVal == "date") {
      labelToShow = moment(momVal).format("DD-MM-YYYY");
      startDate = moment(momVal).format("YYYY-MM-DD");
      endDate = moment(momVal).add("1", "days").format("YYYY-MM-DD");
      breakdown = "hour";
    }
    if (time == "today") {
      labelToShow = "Today";
      startDate = moment().format("YYYY-MM-DD");
      endDate = moment().add("1", "days").format("YYYY-MM-DD");
      breakdown = "hour";
    }

    handleTimeSelect(startDate, endDate, labelToShow, range, breakdown);
  };

  return (
    <Popover className="selection time">
      {({ open }) => (
        <>
          {/*  */}
          <Popover.Button
            className={`group bg-white popover-btn  inline-flex rounded rounded-lg items-center text-base font-medium  opacity-30 primary-text-color opacity-100 p-2`}
          >
            <span
              ref={timeBtnRef}
              onClick={() => {
                panelToggleHandler(open, "time");
              }}
            >
              {timeLabel}
            </span>
            <span className="ml-1">
              <FiChevronDown />
            </span>
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-1"
          >
            <Popover.Panel
              static
              style={{
                zIndex: "-10",
                borderRadius: "0px 0px 20px 20px",
                width: "200px",
              }}
              className="absolute panel timepopover-panel transform -translate-x-1/2 mt-3  top-4/5  text-left"
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-visible">
                <div
                  className="relative bg-white w-full"
                  style={{ padding: "40px 30px" }}
                >
                  {!universalSearchState.meter.isUtility && (
                    <Menu
                      as="div"
                      className="relative inline-block text-left w-full my-2"
                    >
                      <Menu.Button style={{ margin: "16px 0" }}>
                        <span
                          onClick={() => {
                            onTimeChange(
                              moment,
                              moment().format("DD-MM-YYYY"),
                              "today"
                            );
                            timeBtnRef.current.click();
                          }}
                        >
                          Today
                        </span>
                      </Menu.Button>
                    </Menu>
                  )}

                  {universalSearchState.meter.isUtility && (
                    <Menu
                      as="div"
                      className="relative inline-block w-full  text-center"
                    >
                      {new Array(5).fill("").map((val, i) => (
                        <Menu.Button className="my-5 cursor-pointer" as="div">
                          <span
                            onClick={() => {
                              onTimeChange(
                                moment().subtract(i, "year"),
                                moment().format("DD-MM-YYYY"),
                                { datePickerVal: "year" }
                                // moment().subtract(i, "year").format("YYYY")
                              );
                              timeBtnRef.current.click();
                            }}
                          >
                            {moment().subtract(i, "year").format("YYYY")}
                          </span>
                        </Menu.Button>
                      ))}
                    </Menu>
                  )}

                  {!universalSearchState.meter.isUtility &&
                    timeFrames.map((time) => (
                      <Menu
                        as="div"
                        className="relative inline-block text-left w-full my-2"
                        key={time.value}
                      >
                        <Menu.Button
                          style={{ margin: "16px 0" }}
                          className="flex items-center justify-between w-full rounded-md"
                        >
                          <div
                            className="flex items-center justify-between w-full rounded-md"
                            onClick={() => setShowTimePicker(time.value)}
                          >
                            <div className="add-line"> {time.label}</div>
                            <div>
                              <FiChevronRight className="font-bold text-lg" />
                            </div>
                          </div>
                        </Menu.Button>

                        {showTimePicker == time.value && (
                          <Menu.Items
                            static
                            style={{
                              left: "109%",
                              top: "0%",
                              position: "absolute",
                              zIndex: 100,
                            }}
                            className="right-0 relative rounded-md flex"
                          >
                            <Menu.Item>
                              <DatePicker
                                onChange={(p1, p2) =>
                                  onTimeChange(p1, p2, time)
                                }
                                picker={time.value}
                                className={`datepicker datepicker-${time.value}`}
                                open={true}
                              />
                            </Menu.Item>
                          </Menu.Items>
                        )}
                      </Menu>
                    ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

export default TimePopOver;
