import React from "react";
import { HiCheckCircle } from "react-icons/hi";
import { connect } from "react-redux";
import { handleMetricMultiSelect } from "../../../redux/actions";

function MeterSelectorCard({
  equipmentCounts,
  option,
  handleMeterChange,
  loadingSiteData,
  forcedGraphView,
  selectedMetrics,
  setSelectedMetrics,
}) {
  if (loadingSiteData) {
    return (
      <div className="w-60 rounded-sm overflow-hidden shadow-lg cursor-pointer mx-2  bg-gray-300 animate-pulse">
        <div
          className="h-36 bg-cover relative hover:bg-gray   bg-gray-300 animate-pulse"
          style={{
            backgroundSize: "100%",
            backgroundPosition: "center",
            backgroundColor: "rgba(255, 255, 255,1)",
            backgroundBlendMode: "overlay",
          }}
        ></div>
        <div className="mx-6  mt-3 border-gray-light bg-gray-300 animate-pulse">
          <div className="font-medium text-base text-transparent mb-4">
            skdnfkjsdf
          </div>
          <p className="font-normal text-gray-dark text-sm bg-gray-300 animate-pulse text-transparent">
            skdfnskdjfn
          </p>
        </div>
      </div>
    );
  }

  if (!equipmentCounts[option.value + "Count"]) {
    return <></>;
  }

  return (
    <div
      onClick={() => {
        handleMeterChange(option);
      }}
      className="max-w-sm rounded-sm overflow-hidden metercard inside-searchbar cursor-pointer mx-2"
    >
      <div
        className="h-36 bg-cover relative hover:bg-gray "
        style={{
          backgroundSize: "100%",
          backgroundPosition: "center",
          backgroundColor: "rgba(255, 255, 255,1)",
          backgroundBlendMode: "overlay",
        }}
      >
        <img
          src={option.image}
          alt="image"
          className="border border-1 h-full mx-auto"
        />

        {forcedGraphView ? (
          <>
            {selectedMetrics.filter((x) => x.value == option.value).length ? (
              <div className="absolute  right-2 top-2 inline-flex items-center justify-center px-2 py-1 text-lg ">
                <HiCheckCircle size="30px" color="#0fa4e7"></HiCheckCircle>
              </div>
            ) : null}
          </>
        ) : equipmentCounts[option.value + "Count"] ? (
          <div className="absolute count-badge text-white right-2 top-2 inline-flex items-center justify-center px-2 py-1 text-lg  leading-none  badge bg-indigo-700 rounded">
            {equipmentCounts[option.value + "Count"]}
          </div>
        ) : null}
      </div>
      <div className="mx-6  mt-3 border-gray-light">
        <div className="font-medium text-base text-gray-darker mb-4">
          {option.label}
        </div>
        <p className="font-normal text-gray-dark text-xs">
          {option.description}
        </p>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    selectedMetrics: state.search.selectedMetrics,
  };
};
const mapDispatchToProps = {
  setSelectedMetrics: handleMetricMultiSelect,
};
export default connect(mapStateToProps, mapDispatchToProps)(MeterSelectorCard);
