import React, { Fragment, useRef, useState } from "react";
import { Popover, Transition, Dialog } from "@headlessui/react";
import { classNames } from "../../../helpers/classNames";
import SitesGroupPlaceholder from "../../../images/siteGroupPlaceholder.png";
import BuildingSelector from "../../../components/BuildingSelector/BuildingSelector";
import {
  handleSiteSelected,
  setAllEquipments,
  setAllPoints,
  setLoadingSiteData,
} from "../../../redux/actions";
import { FiChevronDown } from "react-icons/fi";
import { getEquipmentCount, getPointCounts } from "../../../components/API/API";
import { connect } from "react-redux";
import { getAllEquipCounts } from "../../../helpers/getAllEquipCounts";
import { FaRegBuilding } from "react-icons/fa";

interface Props {}

export const siteCategories = [
  {
    value: null,
    label: "All Buildings",
    navName: "All Buildings",
    isAGroup: true,
  },
  {
    value: "utilitiesSite",
    label: "Utility Buildings",
    navName: "Utilities Buildings",
    isAGroup: true,
  },
  {
    value: "dormSite",
    label: "Dorm Buildings",
    navName: "Dorm Buildings",
    isAGroup: true,
  },
  {
    value: "auxSite",
    label: "Auxiliary Buildings",
    navName: "Auxiliary Buildings",
    isAGroup: true,
  },
  {
    value: "athleticSite",
    label: "Athelietic Buildings",
    navName: "Athelietic Buildings",
    isAGroup: true,
  },
  {
    value: "acadSite",
    label: "Academic Buildings",
    navName: "Academic Buildings",
    isAGroup: true,
  },
];

function SitesPopOver(props: Props) {
  const {
    auotSwitch,
    panelToggleHandler,
    siteBtnRef,
    meterBtnRef,
    timeBtnRef,
    showTimePanel,
    selectedSite,
    setZoom,
    setLoadingSiteData,
    panelOpen,
    currentlyOpenPanel,
    setAllEquipmentsToGlobalState,
    setAllPoints,
    setSelectedSite,
    buildings,
  } = props;

  const handleBuildingSelectFromPopOver = async (site) => {
    try {
      setSelectedSite(site);
      if (auotSwitch) {
        meterBtnRef.current.click();
      } else {
        siteBtnRef.current.click();
      }

      setLoadingSiteData(true);

      if (site.isAGroup) {
        const eqRes = await getEquipmentCount(null, site.value);
        const pointsRes = await getPointCounts(null, site.value);
        const eqCounts = getAllEquipCounts(
          eqRes.data.data.equip_aggregate.nodes
        );
        setAllEquipmentsToGlobalState(eqCounts);
        setAllPoints(pointsRes.data.data);
      } else {
        const eqRes = await getEquipmentCount(site, null);
        const pointsRes = await getPointCounts(site, null);
        const eqCounts = getAllEquipCounts(
          eqRes.data.data.equip_aggregate.nodes
        );
        setAllEquipmentsToGlobalState(eqCounts);
        setAllPoints(pointsRes.data.data);
      }

      setLoadingSiteData(false);
    } catch (e) {
      console.log(e);
    }

    // handleCategoryClick(e);
  };

  return (
    <Popover className="selection site">
      {({ open }) => (
        <>
          {/*  */}
          <Popover.Button
            className={`group  popover-btn bg-white  inline-flex rounded rounded-lg items-center text-base font-medium  opacity-30 primary-text-color opacity-100 p-2`}
          >
            <span
              ref={siteBtnRef}
              onClick={() => {
                panelToggleHandler(open, "site");
              }}
            >
              {selectedSite.navName}
            </span>
            <span className="ml-1">
              <FiChevronDown />
            </span>
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-1"
          >
            <Popover.Panel
              className="absolute inset-x-0 transform shadow-lg panel"
              style={{
                zIndex: "-10",

                borderRadius: "0px 0px 20px 20px",
              }}
            >
              <div className="absolute inset-0 flex" aria-hidden="true">
                <div className="bg-white w-1/2" />
                <div className="bg-white w-1/2" />
              </div>

              <div className="relative mx-auto ">
                <nav
                  className=" px-10 py-8 bg-white  sm:py-12 sm:px-6 lg:px-8 xl:pr-12"
                  aria-labelledby="solutions-heading"
                >
                  <div className="section site-groups">
                    <h2 className="font-bold text-lg mx-7">Group</h2>
                    <div className="mb-8"></div>
                    <div className="cards flex ">
                      {siteCategories.map((category) => (
                        <div
                          onClick={() => {
                            handleBuildingSelectFromPopOver(category);
                          }}
                          className={`card  bg-white pt-4 pl-4 pb-1 pr-4 text-center mx-5 cursor-pointer relative`}
                        >
                          <div className="count text-white absolute border border-1 p-2 rounded rounded-full text-xs -right-2 -top-2">
                            {
                              buildings[
                                `${
                                  category.value ? category.value : "allSites"
                                }`
                              ]?.length
                            }
                          </div>
                          <div className="img">
                            <FaRegBuilding
                              className="mx-auto"
                              size="32px"
                              color="#0fa4e7"
                            />
                          </div>
                          <h4 className="font-bold mt-3 mb-2">
                            {category.label}
                          </h4>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="border border-1 mt-20 mb-10" />

                  <div className="section">
                    <h2 className="font-bold text-lg mx-7">Buildings</h2>
                    <div className="mb-8"></div>
                    <div className="cards flex flex-wrap h-96 overflow-scroll">
                      <BuildingSelector
                        handleBuildingSelectFromPopOver={
                          handleBuildingSelectFromPopOver
                        }
                      />
                    </div>
                  </div>
                </nav>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

const mapStateToProps = (state) => {
  return {
    buildings: state.buildings,
  };
};

const mapDispatchToProps = {
  setLoadingSiteData: setLoadingSiteData,
  setAllEquipmentsToGlobalState: setAllEquipments,
  setAllPoints: setAllPoints,
  setSelectedSite: handleSiteSelected,
};

export default connect(mapStateToProps, mapDispatchToProps)(SitesPopOver);
