export const notionConfig = {
  allSites: "aec49dbf7c1e451cb6555dd0f7ff3506",
  dormSite: "24bf6a14cf1e48cc9e34824a710629b4",
  acadSite: "f15aef70f6ee4496ada2b08589d0f8d6",
  athleticSite: "6a3f16cd5b4a4e4693d53c358370f622",
  auxSite: "5111310e53394863a453e5dada27eb1b",
  utilitiesSite: "066d07d99c984df5b7794c3bdb63c72e",
};

export const integrationKey =
  "secret_boCIR7r6UGyPqpUCegBpcU7hGX2zM1atqF9awnj7LCC";
