import auth0 from "auth0-js";

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
const auth0ClinetId = process.env.REACT_APP_AUTH0_CLIENT_ID;

const webAuth = new auth0.WebAuth({
  domain: auth0Domain,
  clientID: auth0ClinetId,
  responseType: "token id_token",
  scope: "openid profile email",
  redirectUri: window.location.origin + "/dashboard",
});

export default webAuth;
