import {
  SET_ALL_EQUIPMENTS,
  SET_ALL_POINTS,
  SET_ALL_SITES,
  SET_SELECTED_SITE,
  SET_LOADING_SITE_DATA,
  SET_ERROR_MODAL,
  SET_COST_ACTIVE,
  SET_SPLASH_SCREEN,
  SET_SPLASH_SHOWN,
  RESET_SEARCH_STATE,
} from "../actionTypes";

const initialState = {
  allSites: [],
  utilitiesSite: [],
  dormSite: [],
  auxSite: [],
  athleticSite: [],
  acadSite: [],
  equipmentCounts: {
    count: {},
    equips: {},
    points: {},
  },
  selectedSite: {
    navName: "All Buildings",
    id: null,
    value: null,
    isAGroup: true,
  },
  loadingSiteData: false,

  errorModal: false,
  isCostActive: true,
  showSplashScreen: false,
  splashShown: false,
};

export default function buildingReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ALL_SITES:
      const allSites = action.payload;
      state.allSites = action.payload;

      for (let i in allSites) {
        if (Object.keys(allSites[i].source).includes("utilitiesSite")) {
          state.utilitiesSite.push(allSites[i]);
        }

        if (Object.keys(allSites[i].source).includes("dormSite")) {
          state.dormSite.push(allSites[i]);
        }
        if (Object.keys(allSites[i].source).includes("auxSite")) {
          state.auxSite.push(allSites[i]);
        }
        if (Object.keys(allSites[i].source).includes("athleticSite")) {
          state.athleticSite.push(allSites[i]);
        }
        if (Object.keys(allSites[i].source).includes("acadSite")) {
          state.acadSite.push(allSites[i]);
        }
      }
      return { ...state };

    case SET_ALL_EQUIPMENTS:
      return {
        ...state,
        equipmentCounts: {
          ...state.equipmentCounts,
          count: {
            ...action.payload.count,
          },
          equips: {
            ...action.payload.equips,
          },
        },
      };

    case SET_ALL_POINTS:
      return {
        ...state,
        equipmentCounts: {
          ...state.equipmentCounts,
          points: { ...action.payload },
        },
      };

    case SET_SELECTED_SITE:
      return {
        ...state,
        selectedSite: action.payload,
      };

    case SET_LOADING_SITE_DATA:
      return {
        ...state,
        loadingSiteData: action.payload,
      };

    case SET_ERROR_MODAL:
      return {
        ...state,
        errorModal: action.payload,
      };

    case SET_COST_ACTIVE:
      return {
        ...state,
        isCostActive: action.payload,
      };

    case SET_SPLASH_SCREEN:
      return {
        ...state,
        showSplashScreen: action.payload,
      };

    case SET_SPLASH_SHOWN:
      return {
        ...state,
        splashShown: action.payload,
      };

    case RESET_SEARCH_STATE:
      return {
        ...state,
        selectedSite: initialState.selectedSite,
      };
    default:
      return state;
  }
}
