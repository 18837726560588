import React, { useState } from "react";
import { Button, message, Tabs, Upload } from "antd";
import Picker, { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";
import { AiOutlineUpload } from "react-icons/ai";

const { TabPane } = Tabs;

function EmojiAdnImagePicker({ handleLinkUpload, handleImageUpload }) {
  const [chosenEmoji, setChosenEmoji] = useState(null);

  const [link, setLink] = useState("");

  const [uploading, setUploading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }

    return isJpgOrPng;
  }

  return (
    <div className="absolute z-10 bg-white px-2 py-2 shadow">
      <Tabs
        className="w-auto"
        defaultActiveKey="1"
        onChange={(e) => console.log(e)}
      >
        <TabPane className="p-1" tab="Link" key="1">
          <input
            value={link}
            onChange={(e) => setLink(e.target.value)}
            type="text"
            className="shaodw shadow-xs outline border border-1 p-1 w-96"
            placeholder="Enter your image link"
          />
          <button
            type="button"
            className="border secondary-bg-color text-white p-1 text-sm my-2 text-center mx-auto"
            onClick={() => handleLinkUpload(link)}
          >
            Submit
          </button>
        </TabPane>
        <TabPane tab="Upload" key="2">
          <Upload onChange={handleImageUpload} beforeUpload={beforeUpload}>
            <Button className="w-96">Click to Upload</Button>
          </Upload>
        </TabPane>
      </Tabs>
    </div>
  );
}

export default EmojiAdnImagePicker;
