import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { randomNumber } from "../../../helpers/getRandomNumber";

function BuildingDetails({ activeSlide, sites, index }) {
  const [curr, setCurrSite] = useState({});

  useEffect(() => {
    if (sites) {
      const rand10Sites = sites
        .map((x) => ({ x, r: Math.random() }))
        .sort((a, b) => a.r - b.r)
        .map((a) => a.x)
        .slice(0, 10);

      setCurrSite(rand10Sites[0]);
    }
  }, [sites]);

  const item = {
    hidden: {
      y: "200%",
      opacity: 0,

      transition: {
        ease: [0.455, 0.03, 0.515, 0.955],
        duration: 0.85,
      },
    },
    visible: {
      y: 0,
      opacity: 1,

      transition: {
        ease: [0.455, 0.03, 0.515, 0.955],
        duration: 0.75,
      },
    },
  };

  const container = {
    visible: {
      transition: {
        staggerChildren: 0.025,
      },
    },
  };

  return (
    <div
      initial="hidden"
      animate={activeSlide == index ? "visible" : ""}
      variants={container}
      className="w-full card mx-auto p-5"
    >
      <motion.div
        initial="hidden"
        animate={activeSlide == index ? "visible" : ""}
        variants={item}
        whileInView="visible"
        className="avatar rounded-full text-5xl mb-6"
      >
        🏢
      </motion.div>

      <motion.div
        initial="hidden"
        animate={activeSlide == index ? "visible" : ""}
        variants={item}
        whileInView="visible"
        className="flex text-left"
      >
        <div className=" w-1/2">
          <p className="secondary-text-color uppercase p-0 m-0 mb-1 text-xs font-semibold tracking-wide">
            NAME
          </p>
          <h2 className="primary-text-color text-left font-bold text-lg">
            {curr?.navName}
          </h2>
        </div>

        <div className=" w-1/2">
          <p className="secondary-text-color uppercase p-0 m-0 mb-1 text-xs font-semibold tracking-wide">
            Floors
          </p>
          <h2 className="primary-text-color font-bold text-lg">
            {curr?.floors?.length}
          </h2>
        </div>
      </motion.div>
      <motion.div
        initial="hidden"
        animate={activeSlide == index ? "visible" : ""}
        variants={item}
        whileInView="visible"
        className="flex text-left mt-5"
      >
        <div className=" w-1/2">
          <p className="secondary-text-color uppercase p-0 m-0 mb-1 text-xs font-semibold tracking-wide">
            Sqft
          </p>
          <h2 className="primary-text-color font-bold text-lg">
            {" "}
            {curr?.area}
          </h2>
        </div>

        <div className=" w-1/2">
          <p className="secondary-text-color uppercase p-0 m-0 mb-1 text-xs font-semibold tracking-wide">
            Location
          </p>
          <h2 className="primary-text-color font-bold text-lg">Richmond, VA</h2>
        </div>
      </motion.div>

      <motion.div
        initial="hidden"
        animate={activeSlide == index ? "visible" : ""}
        variants={item}
        whileInView="visible"
        className="flex text-left mt-5"
      >
        <div className=" w-1/2">
          <p className="secondary-text-color uppercase p-0 m-0 mb-1 text-xs font-semibold tracking-wide">
            Equipments Installed
          </p>
          <h2 className="primary-text-color font-bold text-lg">
            {randomNumber(10, 100)}
          </h2>
        </div>

        <div className=" w-1/2 ">
          <p className="secondary-text-color uppercase p-0 m-0 mb-1 text-xs font-semibold tracking-wide">
            Equipments Active
          </p>
          <h2 className="primary-text-color font-bold text-lg">
            {Math.abs(randomNumber(10, 100) - 50)}
          </h2>
        </div>
      </motion.div>
    </div>
  );
}

export default BuildingDetails;
