import moment from "moment";
export const transfromBills = (startDate, endDate, bills) => {
  // console.log("START DATE", startDate, "END DATE", endDate);

  // console.log(moment(startDate).diff(moment(endDate), "days", true));
  const filteredBills = bills.filter((bill) => {
    if (
      moment(bill.base.bill_statement_date).format("YYYY-MM-DD") >= startDate &&
      moment(bill.base.bill_statement_date).format("YYYY-MM-DD") <= endDate
    ) {
      return bill;
    }
  });

  return filteredBills;
};
