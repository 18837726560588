import React, { useEffect, useState } from "react";
import { classNames } from "../../helpers/classNames";
import Loader from "../../Resueables/Loader/Loader";
import ProgressBar from "../../Resueables/ProgressBar/ProgressBar";
import BuildingDetailsTab from "./BuildingDetails";
import MeterDetailsTab from "./MeterDetails";
import { useParams } from "react-router-dom";
import { loadEqInfo } from "../API/API";
import "./LeftSidePanel.css";

function LeftSidePanel({ selectedSite, occupyAndCount, allSites }) {
  const params = useParams();
  const tabs = [
    { name: "Building", current: false, label: "Building" },
    { name: "Meter", current: false, label: params.meter.toUpperCase() },
  ];

  const [meterInfo, setMeterInfo] = useState({});

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  useEffect(() => {
    (async () => {
      try {
        if (activeTab == "Meter") {
          const meterInfo = await loadEqInfo(params.meter);
          setMeterInfo(meterInfo.data.data.meterInfo);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [activeTab]);

  return (
    <div className="h-full bg-white  overflow-y-auto primary-text-color">
      <div className="pb-16 space-y-6">
        <div>
          <div className="hidden sm:block">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex" aria-label="Tabs">
                {tabs.map((tab) => (
                  <a
                    onClick={() => setActiveTab(tab.name)}
                    key={tab.name}
                    className={classNames(
                      tab.name == activeTab
                        ? "tab-active"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                      "w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm w-full panelToggleButtons"
                    )}
                  >
                    {tab.label}
                  </a>
                ))}
              </nav>
            </div>
          </div>
        </div>

        {activeTab == "Building" && (
          <BuildingDetailsTab
            allSites={allSites}
            selectedSite={selectedSite}
            occupyAndCount={occupyAndCount}
          />
        )}
        {activeTab == "Meter" && (
          <MeterDetailsTab meterInfo={meterInfo} selectedSite={selectedSite} />
        )}
      </div>
    </div>
  );
}

export default LeftSidePanel;
