import { Divider, Input } from "antd";
import React from "react";

function TemplateBuildingDetails({ name }) {
  if (!name.length) return null;
  return (
    <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
      <Divider orientation="left" orientationMargin={0}>
        <span className="text-gray-400">Basic</span>
      </Divider>
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Name</dt>
          <dd className="mt-1 text-lg font-semibold text-gray-900">{name}</dd>
        </div>

        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">
            No of Floors/Sites
          </dt>

          <dd className="mt-1 text-lg font-semibold text-gray-900">xx</dd>
        </div>

        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Sqft</dt>
          <dd className="mt-1 text-lg font-semibold text-gray-900">
            xxxx sqft
          </dd>
        </div>

        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Location</dt>
          <dd className="mt-1 text-lg font-semibold text-gray-900">
            Richmond, VA
          </dd>
        </div>

        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">About</dt>
          <dd className="mt-1 max-w-prose  text-gray-900 space-y-5">
            <Input.TextArea
              value="Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis
              labore aut non? Quasi illo dolor culpa accusantium sint distinctio
              aliquam dolorum harum possimus? Eum rerum est magni provident,
              harum labore. Possimus ea, necessitatibus cumque veritatis eaque
              facilis facere, quas laudantium nulla id voluptatum sequi! Illo in
              consectetur consequatur magni dignissimos sit magnam sequi fugiat
              suscipit, impedit soluta"
              rows={6}
            />
          </dd>
        </div>
      </dl>

      <Divider orientation="right" orientationMargin={0}>
        <span className="text-gray-400">Equipment</span>
      </Divider>

      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">VAV</dt>
          <dd className="mt-1 text-sm font-semibold text-gray-900">
            <div>
              <span className="text-xs text-gray-500">Count :</span> xx{" "}
            </div>
            <div>
              <span className="text-xs text-gray-500">Active :</span> xx (xx%){" "}
            </div>
          </dd>
        </div>

        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">FCU</dt>
          <dd className="mt-1 text-sm font-semibold text-gray-900">
            <div>
              <span className="text-xs text-gray-500">Count :</span> xx{" "}
            </div>
            <div>
              <span className="text-xs text-gray-500">Active :</span> xx (xx%){" "}
            </div>
          </dd>
        </div>

        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">AHU</dt>
          <dd className="mt-1 text-sm font-semibold text-gray-900">
            <div>
              <span className="text-xs text-gray-500">Count :</span> xx{" "}
            </div>
            <div>
              <span className="text-xs text-gray-500">Active :</span> xx (xx%){" "}
            </div>
          </dd>
        </div>

        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Boilers</dt>
          <dd className="mt-1 text-sm font-semibold text-gray-900">
            <div>
              <span className="text-xs text-gray-500">Count :</span> xx{" "}
            </div>
            <div>
              <span className="text-xs text-gray-500">Active :</span> xx (xx%){" "}
            </div>
          </dd>
        </div>

        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Chillers</dt>
          <dd className="mt-1 text-sm font-semibold text-gray-900">
            <div>
              <span className="text-xs text-gray-500">Count :</span> xx{" "}
            </div>
            <div>
              <span className="text-xs text-gray-500">Active :</span> xx (xx%){" "}
            </div>
          </dd>
        </div>
      </dl>
    </div>
  );
}

export default TemplateBuildingDetails;
