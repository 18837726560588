import React from 'react';
import "./Loader.css";

function Loader({size, color, center}) {

    const styles = {width: size, height: size, borderTopColor: color}
    return (
        <div style={center ? {...styles, margin: "0 auto"} : {...styles} } class="loader"></div>
    )
}

export default Loader
