import React, { useEffect, useReducer, useRef, useState } from "react";
import { Empty, message, notification, Table } from "antd";
import { MdAdd, MdClose } from "react-icons/md";
import {
  assignSiteAdndEquipment,
  deleteViewById,
  getAllViewsByUserIdAndViewType,
  insertIntoView,
} from "../../../API/API";
import shortid from "shortid";
import moment from "moment";
import { BsTrash } from "react-icons/bs";
import Modal from "antd/lib/modal/Modal";
import { tableHead } from "./TableHeaderRendere";
import { tableBody } from "./TableHeaderRendere";
import { connect } from "react-redux";
import TemplateAssignButtons from "../../TemplateAssignButtons";

function TableCustom({ selectedPage, lanes }) {
  const [tables, setTables] = useState([]);
  const [columns, setColumns] = useState([]);
  const [tableName, setTableName] = useState("");
  const [editing, setEditing] = useState("");
  const [modalOpen, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [somethingChanged, setSomethingChanged] = useState(false);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  let rowsRef = useRef();
  let optionsRef = useRef();

  let tableRef = useRef();

  const fetchAllTables = async () => {
    const res = await getAllViewsByUserIdAndViewType(
      JSON.parse(localStorage.getItem("user")).sub,
      selectedPage.pageId,
      "table"
    );

    const allRecords = res.data.data.views;
    let cols = [
      {
        id: "title",
        name: "Title",
        type: "text",
      },
      {
        id: "date",
        name: "Date",
        type: "date",
      },
      {
        id: "status",
        name: "Status",
        type: "select",
      },
    ];
    const propertiesFromAllRecords = [];
    if (allRecords.length) {
      for (let i in allRecords) {
        if (allRecords[i]?.properties?.length) {
          propertiesFromAllRecords.push(...allRecords[i].properties);
        }
      }
    }

    const filteredCols = propertiesFromAllRecords.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );

    cols.push(...filteredCols);

    setColumns(cols);

    let rows = [];
    let singleRow = {};

    for (let i in allRecords) {
      singleRow = {};
      singleRow["title"] = allRecords[i].title;
      singleRow["status"] = allRecords[i].status;
      singleRow["date"] = allRecords[i].date;
      singleRow["viewId"] = allRecords[i].viewId;

      for (let j in allRecords[i].properties) {
        if (!singleRow[allRecords[i].properties[j].name.split(" ").join("")]) {
          singleRow[allRecords[i].properties[j].name.split(" ").join("")] =
            allRecords[i].properties[j];
        }
      }

      rows.push(singleRow);
    }

    tableRef.current.scrollTo(0, 0);

    setRows(rows);
  };

  const handleColumsNameChange = (e, colIndex, col) => {
    const columnsCopy = [...columns];
    console.log(col);
    const colName = e.target.value;
    columnsCopy[colIndex] = {
      ...columnsCopy[colIndex],
      name: colName,
      id: colName.split(" ").join(""),
    };
    setColumns(columnsCopy);
    setSomethingChanged(true);
    // setColumns(allColumns);
  };

  const handleColumnTypeChange = (colIndex, type) => {
    const columnsCopy = [...columns];

    columnsCopy[colIndex] = {
      ...columnsCopy[colIndex],
      type: type,
    };

    setColumns(columnsCopy);
    forceUpdate();
    setSomethingChanged(true);
  };

  const handleColumnAdd = () => {
    console.log("COLUMNS ADDED");
    const columnsCopy = [...columns];
    const colName = `Col ${columnsCopy.length}`;
    columnsCopy.push({
      type: "text",
      name: colName,
      typeLabel: colName,
      id: colName.split(" ").join(""),
    });
    setColumns(columnsCopy);
    setSomethingChanged(true);
  };

  const handleColumnDelete = (i) => {
    const columnsCopy = [...columns];
    columnsCopy.splice(i, 1);
    setColumns(columnsCopy);
    setSomethingChanged(true);
  };

  const handleRowAdd = () => {
    savePrevious();

    let rowsCopy = [...rows];
    let singleRow = {};

    if (rowsCopy.length) {
      const lastEntry = rowsCopy[rowsCopy.length - 1];

      Object.keys(lastEntry).forEach((keys) => {
        if (typeof lastEntry[keys] == "object") {
          singleRow[keys] = {
            ...lastEntry[keys],
            value: "",
          };
        } else {
          singleRow[keys] = lastEntry[keys];
        }
      });

      console.log("Modified X", singleRow);
    } else {
      for (let i in columns) {
        const rowKey = columns[i].id || columns[i].name.split(" ").join("");
        singleRow[rowKey] = {
          ...columns[i],
          value: "",
          type: columns[i].type,
          name: columns[i].name,
        };
      }
    }

    singleRow["viewId"] = shortid.generate();
    singleRow["new"] = true;

    rowsCopy.push(singleRow);
    setRows(rowsCopy);
    setSomethingChanged(true);
  };

  const savePrevious = async () => {
    let latestRowState;
    if (rows.length > rowsRef.current.length) {
      latestRowState = rows;
    } else {
      latestRowState = rowsRef.current;
    }

    const rowsCopy = JSON.parse(JSON.stringify(latestRowState));

    const newestRowIndex = rowsCopy.findIndex((x) => x.new);

    const newestRow = rowsCopy[newestRowIndex];

    if (!newestRow) return;
    if (newestRow) {
      const viewId = newestRow["viewId"];
      const rowToSave = Object.values(newestRow).filter((x) => {
        // console.log(x);
        // if (typeof x == "object" && x.value.length) {
        //   return x;
        // }
        return x;
      });

      const userId = JSON.parse(localStorage.getItem("user")).sub;
      const title = rowToSave.filter((x) => x.name?.toLowerCase() == "title")
        .length
        ? rowToSave.filter((x) => x.name?.toLowerCase() == "title")[0]
        : null;

      const status = rowToSave.filter((x) => x.name?.toLowerCase() == "status")
        .length
        ? rowToSave.filter((x) => x.name?.toLowerCase() == "status")[0]
        : null;

      const date = rowToSave.filter((x) => x.name?.toLowerCase() == "date")
        .length
        ? rowToSave.filter((x) => x.name?.toLowerCase() == "date")[0]
        : null;

      const tags = rowToSave.filter((x) => x.name?.toLowerCase() == "tags")
        .length
        ? rowToSave.filter((x) => x.name?.toLowerCase() == "tags")[0]
        : null;

      const props = rowToSave.filter((x) => x.typeLabel).length
        ? rowToSave.filter((x) => x.typeLabel)
        : null;

      console.log();

      const res = await insertIntoView(
        viewId,
        props,
        moment().format("DD-MM-YYYY hh:mm a"),
        null,
        title,
        userId,
        selectedPage.pageId,
        date,
        status,
        lanes
      );

      if (res?.data?.data?.insert_views?.affected_rows) {
        rowsCopy[newestRowIndex]["new"] = false;
        console.log(rowsCopy);
        setRows(rowsCopy);
        // handleRowAdd();
      }
    } else {
      notification.error({ message: "Something Went Wrong" });
    }
  };

  useEffect(() => {
    return () => {
      savePrevious();
      // console.log(rowsRef.current);
    };
  }, []);

  const handleRowInputChange = (colId, type, value, index, options) => {
    optionsRef.current = options;
    const rowsCopy = JSON.parse(JSON.stringify(rows));

    rowsCopy[index][colId] = {
      ...rowsCopy[index][colId],
      value: value,
      options,
    };
    setRows(rowsCopy);
  };

  useEffect(() => {
    fetchAllTables();
  }, []);

  useEffect(() => {
    rowsRef.current = rows;
  }, [rows]);

  const handleRowDelete = async (text, record, index) => {
    const rowsCopy = JSON.parse(JSON.stringify(rows));
    const res = await deleteViewById(rowsCopy[index].viewId);
    if (res?.data?.data?.delete_views?.affected_rows) {
      notification.success({ message: "Deleted Successfully" });
    }
    rowsCopy.splice(index, 1);
    console.log(rowsCopy);
    setRows(rowsCopy);
    forceUpdate();
  };

  const handleSiteAssigned = async (site, equipment) => {
    const viewsToBeAssigned = rows.map((x) => ({
      viewId: x.viewId,
      sites: site,
      eq_type: equipment,
      pageId: selectedPage.pageId,
      title: x.title,
      userid: JSON.parse(localStorage.getItem("user")).sub,
    }));

    const res = await assignSiteAdndEquipment(viewsToBeAssigned);

    console.log(res, viewsToBeAssigned);

    if (
      res?.data?.data?.insert_views?.affected_rows == viewsToBeAssigned.length
    ) {
      message.success("Assigned Successfully");
    } else {
      message.error("Assignment Failed");
    }
  };

  return (
    <>
      <div ref={tableRef} style={{ overflowX: "scroll" }} className="bg-white">
        <Table
          pagination={false}
          rowClassName={() => "editable-row"}
          dataSource={rows}
          columns={[
            ...columns.map((x, i) => {
              return {
                title: (filters, sortOrder) =>
                  tableHead(
                    x,
                    i,
                    handleColumnDelete,
                    handleColumsNameChange,

                    handleColumnTypeChange
                  ),
                dataIndex: x.name.split(" ").join(""),
                key: x.name.split(" ").join(""),
                render: (text, record, index) =>
                  tableBody(
                    x,
                    i,
                    handleRowInputChange,
                    record,
                    text,
                    index,
                    rows
                  ),
              };
            }),
            {
              title: () => (
                <div onClick={handleColumnAdd}>
                  <MdAdd className="text-lg"></MdAdd>
                </div>
              ),
              dataIndex: "add",
              key: "add",
              render: (text, record, index) => (
                <>
                  <div
                    onClick={() => handleRowDelete(text, record, index)}
                    className="cursor-pointer"
                  >
                    <MdClose className="text-lg" />
                  </div>
                </>
              ),
            },
          ]}
        />
      </div>
      <div className="flex justify-between">
        <button
          type="button"
          className="p-1 border rounded rounded-lg px-3 mt-3 text-xs"
          onClick={handleRowAdd}
        >
          + New
        </button>

        <TemplateAssignButtons
          handleAssigned={handleSiteAssigned}
          selectedDB="table"
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    lanes: state.notion.lanes,
  };
};
export default connect(mapStateToProps)(TableCustom);
