import React, { Fragment, useRef, useState } from "react";
import { Popover, Transition, Dialog } from "@headlessui/react";
import { setAllPoints, setLoadingSiteData } from "../../../redux/actions";
import { FiChevronDown } from "react-icons/fi";
import { connect } from "react-redux";

export const siteCategories = [
  {
    value: null,
    label: "All Buildings",
    navName: "All Buildings",
    isAGroup: true,
  },
  {
    value: "utilitiesSite",
    label: "Utility Buildings",
    navName: "Utilities Buildings",
    isAGroup: true,
  },
  {
    value: "dormSite",
    label: "Dorm Buildings",
    navName: "Dorm Buildings",
    isAGroup: true,
  },
  {
    value: "auxSite",
    label: "Auxiliary Buildings",
    navName: "Auxiliary Buildings",
    isAGroup: true,
  },
  {
    value: "athleticSite",
    label: "Athelietic Buildings",
    navName: "Athelietic Buildings",
    isAGroup: true,
  },
  {
    value: "acadSite",
    label: "Academic Buildings",
    navName: "Academic Buildings",
    isAGroup: true,
  },
];

function UtilityMeterPopOver({
  utilitiyMeters,
  selectedUtilityMeter,
  setSelectedUtilityMeter,
  utilityPopOverRef,
}) {
  return (
    <Popover className="selection site">
      {({ open }) => (
        <>
          {/*  */}
          <Popover.Button
            className={`group  popover-btn bg-white  inline-flex rounded rounded-lg items-center text-base font-medium  opacity-30 primary-text-color opacity-100 p-2`}
          >
            <span ref={utilityPopOverRef}>
              {selectedUtilityMeter != null
                ? selectedUtilityMeter.meter_number &&
                  !selectedUtilityMeter.allMeter
                  ? selectedUtilityMeter.meter_number
                  : "All Meters"
                : "Utility meter"}{" "}
            </span>
            <span className="ml-1">
              <FiChevronDown />
            </span>
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-1"
          >
            <Popover.Panel
              className="absolute inset-x-0 transform shadow-lg panel"
              style={{
                zIndex: "-10",
                borderRadius: "0px 0px 20px 20px",
              }}
            >
              <div className="absolute inset-0 flex" aria-hidden="true">
                <div className="bg-white w-1/2" />
                <div className="bg-white w-1/2" />
              </div>

              <div className="relative mx-auto ">
                <nav
                  className=" px-10 py-8 bg-white  sm:py-12 sm:px-6 lg:px-8 xl:pr-12"
                  aria-labelledby="solutions-heading"
                >
                  <div className="flex w-full justify-between">
                    <div className="w-1/5">Meter Number</div>
                    <div className="w-1/5">No. of Sites</div>
                    <div className="w-1/3 flex-1">Sites</div>
                  </div>

                  <div className="border border-1 border-grey-100 my-2"></div>
                  {utilitiyMeters && (
                    <div
                      className="flex items-center  p-2 cursor-pointer my-1 hover:bg-gray-100 "
                      onClick={() => {
                        utilityPopOverRef.current.click();
                        setSelectedUtilityMeter({
                          meter_number: null,
                          allMeter: true,
                        });
                      }}
                    >
                      <div className="w-1/5 font-lg font-bold">All Meters</div>
                      <div className="w-1/5 font-lg">
                        {/* {utilitiyMeters
                          .map((x) => x.buildings.length)
                          .reduce((a, b) => a + b, 0)} */}
                      </div>
                      <div className="w-1/3 font-lg flex-1  overflow-scroll text-xs">
                        {utilitiyMeters.map((x) => (
                          <>
                            {/* {x.buildings.map((building) => (
                              <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-pink-100 text-pink-800  mx-1 my-1">
                                {building}
                              </span>
                            ))} */}
                          </>
                        ))}
                      </div>
                    </div>
                  )}
                  {utilitiyMeters.map((meter) => (
                    <>
                      <div
                        className="flex items-center  p-2 cursor-pointer my-1 hover:bg-gray-100 "
                        onClick={() => {
                          utilityPopOverRef.current.click();
                          setSelectedUtilityMeter(meter);
                        }}
                      >
                        <div className="w-1/5 font-lg font-bold">
                          {meter.meter_number}
                        </div>
                        <div className="w-1/5 font-lg">
                          {/* {meter.buildings.length} */}
                        </div>
                        <div className="w-1/3 font-lg flex-1  overflow-scroll text-xs">
                          {/* {meter.buildings.map((building) => (
                            <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-pink-100 text-pink-800  mx-1">
                              {building}
                            </span>
                          ))} */}
                        </div>
                      </div>
                    </>
                  ))}
                </nav>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

const mapStateToProps = (state) => {
  return {
    utilitiyMeters: state.utilities.utilitiyMeters,
  };
};

export default connect(mapStateToProps)(UtilityMeterPopOver);
