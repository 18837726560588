import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { resetSearchState } from "../../redux/actions";
import "./UserProfileModal.css";
import userCoverImage from "../../images/user-cover.jpeg";

function UserProfileModal({ isOpen, setIsOpen, user }) {
  const history = useHistory();
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed  inset-0 overflow-y-auto"
        open={isOpen}
        style={{ zIndex: "100" }}
        onClose={setIsOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block usermodal align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle  sm:p-6">
              <div className="coverimg">
                <div className="useravatar rounded rounded-full bg-white p-1 absolute ">
                  <img
                    src={
                      "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png"
                    }
                    className="rounded rounded-full"
                  />
                </div>
              </div>
              <div className="mt-10" />
              <div
                style={{ marginLeft: "100px", marginTop: "80px" }}
                className=""
              >
                <h1 className="text-xl font-bold primary-text-color">
                  Micheal Briggs
                </h1>
                <div className="flex mt-6 flex-wrap">
                  <div className="item my-4 w-1/2">
                    <p className="m-0 text-sm font-bold uppercase tracking-wide">
                      Position
                    </p>
                    <h1 className="m-0 primary-text-color text-lg font-bold">
                      Technician
                    </h1>
                  </div>

                  <div className="item my-4 w-1/2">
                    <p className="m-0 text-sm font-bold uppercase tracking-wide">
                      Phone Number
                    </p>
                    <h1 className="m-0 primary-text-color text-lg font-bold">
                      123456789
                    </h1>
                  </div>

                  <div className="item my-4 w-1/2">
                    <p className="m-0 text-sm font-bold uppercase tracking-wide">
                      YEARS EMPLOYED
                    </p>
                    <h1 className="m-0 primary-text-color text-lg font-bold">
                      10 Years
                    </h1>
                  </div>

                  <div className="item my-4 w-1/2">
                    <p className="m-0 text-sm font-bold uppercase tracking-wide">
                      Email
                    </p>
                    <h1 className="m-0 primary-text-color text-lg font-bold">
                      micheal@novo.cloud
                    </h1>
                  </div>

                  <div className="item my-4 w-1/2">
                    <p className="m-0 text-sm font-bold uppercase tracking-wide">
                      Department
                    </p>
                    <h1 className="m-0 primary-text-color text-lg font-bold">
                      Facilities
                    </h1>
                  </div>

                  <div className="item my-4 w-1/2">
                    <p className="m-0 text-sm font-bold uppercase tracking-wide">
                      Buildings working on
                    </p>
                    <h1 className="m-0 primary-text-color text-lg font-bold">
                      All Buildings
                    </h1>
                  </div>

                  <div className="item my-4 w-1/2">
                    <p className="m-0 text-sm font-bold uppercase tracking-wide">
                      Twitter
                    </p>
                    <h1 className="m-0 primary-text-color text-lg font-bold">
                      @BriggsNovo
                    </h1>
                  </div>

                  <div className="item my-4 w-1/2">
                    <p className="m-0 text-sm font-bold uppercase tracking-wide">
                      Linkedin
                    </p>
                    <h1 className="m-0 primary-text-color text-lg font-bold">
                      Micheal Briggs
                    </h1>
                  </div>

                  <div className="item my-4 w-1/2">
                    <p className="m-0 text-sm font-bold uppercase tracking-wide">
                      Systems of focus
                    </p>
                    <h1 className="m-0 primary-text-color text-lg font-bold">
                      HVAC, Lights
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const mapDispatchToProps = {
  resetSearchState: resetSearchState,
};

export default connect(null, mapDispatchToProps)(UserProfileModal);
