import React, { useState } from "react";
import { connect } from "react-redux";
import { siteCategories } from "../../Resueables/UniversalSearch/SitesPopOver";
import SubMenu from "antd/lib/menu/SubMenu";
import MenuItem from "antd/lib/menu/MenuItem";
import { allMeterOptions } from "../../redux/reducers/search.reducers";
import { IoMdClose } from "react-icons/io";
import { Button, Dropdown, Menu, Select } from "antd";

function TemplateAssignButtons({ selectedDB, allSites, handleAssigned }) {
  const [assignedSite, setAssignedSite] = useState(null);
  const [assignedEquipment, setAssignedEquipment] = useState(null);
  const [saveAsTemplateClicked, setSaveAsTemplateClick] = useState(false);

  const handleAssign = (site, isAgroup) => {
    setAssignedSite({
      id: site.value || site.id,
      navName: site.navName,
    });
  };

  const handleEquipmentSelect = (e) => {
    handleAssigned(assignedSite, e);
  };

  return (
    <>
      {selectedDB == "table" || selectedDB == "list" ? (
        <div className="absolute -bottom-3 right-0 flex items-center">
          {saveAsTemplateClicked ? (
            <>
              <div className=" mx-1 top-0 left-0">
                <Dropdown.Button
                  trigger="click"
                  className="overflow-hidden ml-auto"
                  overlay={
                    <Menu>
                      <Menu.ItemGroup title="Group Of Sites">
                        {siteCategories.map((cat) => (
                          <Menu.Item
                            onClick={() => handleAssign(cat, true)}
                            key={cat.id}
                          >
                            {cat.navName}
                          </Menu.Item>
                        ))}
                      </Menu.ItemGroup>
                      <Menu.ItemGroup title="Individual Sites">
                        <SubMenu key="siteSelect" title="Select a Site">
                          {allSites.map((site) => (
                            <MenuItem
                              onClick={() => handleAssign(site, false)}
                              key={site.id}
                            >
                              {site.navName}
                            </MenuItem>
                          ))}
                        </SubMenu>
                      </Menu.ItemGroup>
                      <Menu.ItemGroup title="Unassign">
                        <MenuItem
                          onClick={() => setAssignedSite(null)}
                          style={{ color: "red" }}
                        >
                          Unassign
                        </MenuItem>
                      </Menu.ItemGroup>
                    </Menu>
                  }
                >
                  {assignedSite == null ? "Assign To" : assignedSite.navName}
                </Dropdown.Button>
              </div>

              <div className="mx-1 top-0 left-0">
                <Select
                  disabled={assignedSite == null ? true : false}
                  placeholder="Select Equipment"
                  onChange={(e) => handleEquipmentSelect(e)}
                >
                  <Option value="kwh">KWH Usage</Option>
                  <Option value="steam">Steam Usage</Option>

                  {allMeterOptions
                    .filter((x) => x.isEquipment)
                    .map((meter) => (
                      <Option key={meter.value} value={meter.value}>
                        {meter.label}
                      </Option>
                    ))}
                </Select>
              </div>

              <div
                onClick={() => {
                  setAssignedSite(null);
                  setAssignedEquipment(null);
                  setSaveAsTemplateClick(false);
                }}
                className="mx-1 top-0 left-0 h-4 border bg-red-100 border-red w-4 flex items-center justify-center rounded rounded-full cursor-pointer"
              >
                <IoMdClose className="text-xs text-red" color="red" />
              </div>
            </>
          ) : (
            <button
              className="p-1 border rounded rounded-lg px-3 mt-3 text-sm"
              onClick={() => setSaveAsTemplateClick((prev) => !prev)}
            >
              Save as Template
            </button>
          )}
        </div>
      ) : null}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    allSites: state.buildings.allSites,
  };
};

export default connect(mapStateToProps)(TemplateAssignButtons);
