import React from "react";
import { Redirect, Route } from "react-router-dom";

function ProtectedRoute({ exact, path, children }) {
  return (
    <>
      {localStorage.getItem("accessToken") ? (
        <Route exact={exact} path={path}>
          {children}
        </Route>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
}

export default ProtectedRoute;
