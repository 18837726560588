//@ts-nocheck

const equipShape = {
  shapeId: "#site", // relates to the type property of a node
  shape: (
    <symbol viewBox="0 0 80 80" id="site" key="0" width="120" height="120">
      <circle
        cx="40"
        cy="40"
        r="30"
        stroke="rgb(28 144 255)"
        strokeWidth="3"
        fill="pink"
      />
    </symbol>
  ),
};

export const GraphConfig = {
  NodeTypes: {
    site: {
      // required to show empty nodes
      // typeText: "None",
      shapeId: "#site", // relates to the type property of a node
      shape: (
        <symbol viewBox="0 0 80 80" id="site" key="0" width="120" height="120">
          <circle
            cx="40"
            cy="40"
            r="30"
            stroke="rgb(28 144 255)"
            strokeWidth="3"
            fill="pink"
          />
        </symbol>
      ),
    },

    floor: {
      // required to show empty nodes
      // typeText: "None",
      shapeId: "#site", // relates to the type property of a node
      shape: (
        <symbol viewBox="0 0 80 80" id="site" key="0" width="120" height="120">
          <circle
            cx="40"
            cy="40"
            r="30"
            stroke="rgb(28 144 255)"
            strokeWidth="3"
            fill="pink"
          />
        </symbol>
      ),
    },

    equipment: {
      // required to show empty nodes
      // typeText: "None",
      shapeId: "#site", // relates to the type property of a node
      shape: (
        <symbol viewBox="0 0 80 80" id="site" key="0" width="120" height="120">
          <circle
            cx="40"
            cy="40"
            r="30"
            stroke="rgb(28 144 255)"
            strokeWidth="3"
            fill="pink"
          />
        </symbol>
      ),
    },

    building: {
      // typeText: "None",
      shapeId: "#site", // relates to the type property of a node
      shape: (
        <symbol viewBox="0 0 80 80" id="site" key="0" width="120" height="120">
          <circle
            cx="40"
            cy="40"
            r="30"
            stroke="rgb(28 144 255)"
            strokeWidth="3"
            fill="pink"
          />
        </symbol>
      ),
    },

    equipVav: equipShape,
    equipFcu: equipShape,

    equipKwh: equipShape,

    equipSteam: equipShape,

    equipAhu: equipShape,

    emptyH: {
      // required to show empty nodes
      typeText: "None",
      shapeId: "#emptyH", // relates to the type property of a node
      shape: (
        <symbol
          viewBox="0 0 100 100"
          id="emptyH"
          key="0"
          width="100"
          height="100"
          style={{ fill: "blue", stroke: "pink", strokeWidth: 5, opacity: 0.5 }}
        >
          <circle cx="50" cy="50" r="45"></circle>
        </symbol>
      ),
    },
    custom: {
      // required to show empty nodes
      typeText: "Custom",
      shapeId: "#custom", // relates to the type property of a node
      shape: (
        <symbol
          id="icon-67a9aab9"
          viewBox="0 0 1080 1080"
          id=".1489277643572997"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="1080"
            height="1080"
            x="-540"
            y="-540"
            fill="#FFF"
            rx="0"
            ry="0"
            transform="translate(540 540)"
          />
          <rect
            width="66.167"
            height="66.167"
            x="-33.084"
            y="-33.084"
            fill="#FFF"
            stroke="#770606"
            rx="0"
            ry="0"
            transform="matrix(6.37 0 0 8.71 540 612.15)"
          />
          <path
            fill="#656565"
            stroke="#000"
            d="M40.375 40.375h-80.75v-80.75h80.75v80.75zm-78.75-2h76.75v-76.75h-76.75v76.75z"
            transform="matrix(1.21 0 0 1.24 408.96 419.66)"
          />
          <path
            fill="#656565"
            d="M588.854 469.435h-97.708v-100.13h97.708v100.13zm-95.288-2.48h92.868v-95.17h-92.868v95.17zM717.854 467.435h-97.708v-100.13h97.708v100.13zm-95.288-2.48h92.868v-95.17h-92.868v95.17z"
          />
        </symbol>
      ),
    },
    customH: {
      // required to show empty nodes

      shapeId: "#customH", // relates to the type property of a node
      shape: (
        <symbol
          viewBox="0 0 115 115"
          id="customH"
          key="1"
          width="115"
          height="115"
          style={{ fill: "blue", stroke: "pink", strokeWidth: 5, opacity: 0.5 }}
        >
          <rect width="109" height="109" />
        </symbol>
      ),
    },
    rect: {
      // required to show empty nodes
      //   typeText: "Rect",
      shapeId: "#rect", // relates to the type property of a node
      shape: (
        //   <symbol viewBox="0 0 50 25" id="rect" key="0">
        //     <rect cx="50" cy="25" rx="50" ry="25"></rect>
        //   </symbol>

        <symbol
          viewBox="-27 0 154 154"
          id="rect"
          key="2"
          width="154"
          height="154"
        >
          <rect transform="translate(50) rotate(45)" width="109" height="109" />
        </symbol>
      ),
    },
    rectH: {
      // required to show empty nodes
      //   typeText: "Rect",
      shapeId: "#rectH", // relates to the type property of a node
      shape: (
        //   <symbol viewBox="0 0 50 25" id="rect" key="0">
        //     <rect cx="50" cy="25" rx="50" ry="25"></rect>
        //   </symbol>

        <symbol
          viewBox="-27 0 154 154"
          id="rectH"
          key="2"
          width="154"
          height="154"
          style={{ fill: "blue", stroke: "pink", strokeWidth: 5, opacity: 0.5 }}
        >
          <rect transform="translate(50) rotate(45)" width="109" height="109" />
        </symbol>
      ),
    },
  },
  NodeSubtypes: {},
  EdgeTypes: {
    emptyEdge: {
      // required to show empty edges
      shapeId: "#emptyEdge",
      shape: (
        //   <symbol viewBox="0 0 50 50" id="emptyEdge" key="0">
        //     <circle cx="25" cy="25" r="8" fill="currentColor"> </circle>
        //   </symbol>
        <symbol viewBox="0 0 0 0" id="emptyEdge">
          <circle cx="25" cy="25" r="8" fill="currentColor" />
        </symbol>
      ),
    },
  },
};
