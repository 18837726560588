import React, { Fragment, useEffect, useContext, useState } from "react";
import Header from "../../Resueables/Header/Header.jsx";

import UniversalSearch from "../../Resueables/UniversalSearch/index";
import MetersCard from "../MetersCard/MetersCard.jsx";
import MapBox from "./MapBox/MapBox.jsx";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Dashboard.css";
import { getAllEquipCounts } from "../../helpers/getAllEquipCounts";
import { connect } from "react-redux";
import {
  handleSiteSelected,
  setAllEquipments,
  setAllSites,
  setAllPoints,
  setShowSplashScreen,
  setSplashShown,
  resetSearchState,
} from "../../redux/actions";
import {
  getAllSites,
  getEquipmentCount,
  getPointCounts,
  getWeatherForADay,
} from "../API/API";
import EquipmentsCard from "../EquipmentsCard/EquipmentsCard";
import moment from "moment";
import { FiMaximize, FiMinimize } from "react-icons/fi";
import { Link } from "react-router-dom";

function Dashboard({
  setAllSitesToGlobalState,
  setAllEquipmentsToGlobalState,
  equipmentCounts,
  buildings,
  setAllPoints,
  points,
  setShowSplashScreen,
  showSplashScreen,
  setSplashShown,
  splashShown,
  resetSearch,
}) {
  const settings = {
    dots: true,
    slidesToShow: 2,
    slidesToScroll: 0,
    arrows: true,
    infinite: false,
  };

  const [open, setOpen] = useState(false);
  const [showTimePanel, setShowTimePanel] = useState(false);

  const [siteCatSelected, setSiteCatSelected] = useState({});

  const [selectedSite, setSelectedSite] = useState({
    id: null,
    navName: "All Buildings",
    area: "2,624,836 ft2",
    elecMeterId: "elec",
    steamMeterId: "steam",
  });

  const [siteMetaData, setSiteMetaData] = useState({});

  const [zoom, setZoom] = useState(15);

  const [weatherObj, setWeatherObj] = useState(null);

  const [knowledgeFullScreen, setKnowledgeFullScreen] = useState(false);

  const [sqft, setSqft] = useState(0);
  const [loading, setLoading] = useState(true);

  const addCordinatesToData = (data: any[]) => {
    for (let i in data) {
      if (data[i].source.geoCoord) {
        data[i].geoLat = parseFloat(
          data[i].source.geoCoord.replace("c:", "").split(",")[0]
        );
        data[i].geoLon = parseFloat(
          data[i].source.geoCoord.replace("c:", "").split(",")[1]
        );
      }
    }
    return { site: data };
  };

  useEffect(() => {
    (async () => {
      if (
        !buildings.allSites.length ||
        !Object.keys(equipmentCounts) ||
        !Object.keys(points)
      ) {
        setLoading(true);

        const siteRes = await getAllSites(null);

        const eqRes = await getEquipmentCount(null, null);

        const pointRes = await getPointCounts(null, null);

        const weather = await getWeatherForADay(moment().format("YYYY-MM-DD"));

        const siteWithCooordinates = addCordinatesToData(
          siteRes?.data?.data?.site
        );

        if (!buildings.allSites.length) {
          setAllSitesToGlobalState(siteWithCooordinates.site);
        }

        const eqCounts = getAllEquipCounts(
          eqRes.data.data.equip_aggregate.nodes
        );

        localStorage.setItem("eqCountForAll", JSON.stringify(eqCounts));

        console.log("WEARHER", weather);
        setAllEquipmentsToGlobalState(eqCounts);
        setWeatherObj(weather.data.data);
        setAllPoints(pointRes.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    setShowSplashScreen(true);
  }, []);

  useEffect(() => {
    if (showSplashScreen == true) {
      setTimeout(() => {
        setShowSplashScreen(false);
        setSplashShown(true);
      }, 1000);
    }
  }, [showSplashScreen]);

  if (showSplashScreen && !splashShown) {
    return (
      <div className=" text-2xl h-full text-center flex items-center justify-center absolute w-full left-0 top-0">
        <span className="tracking-in-contract-bck">
          <span style={{ color: "#3ec3f4" }}>NOVO</span>.
          <span style={{ color: "#929394" }}>CLOUD</span>
        </span>
      </div>
    );
  }

  const KnowledgeScreen = () => (
    <>
      <div className="shadow rounded rounded-lg relative">
        <div
          onClick={() => setKnowledgeFullScreen((prev) => !prev)}
          className="absolute shadow shadow-xs cursor-pointer right-2 bottom-2"
        >
          {knowledgeFullScreen ? (
            <FiMinimize size="30" />
          ) : (
            <FiMaximize size="30" />
          )}
        </div>
        <iframe
          height="700px"
          width="100%"
          src="https://mental-felidae.super.site/"
          title="Super.so"
        ></iframe>
        <div className="mt-8" />
      </div>
    </>
  );

  if (knowledgeFullScreen) {
    return (
      <Fragment>
        <div className="flex">
          {/* <div>
        <Sidebar />
      </div> */}
          <div className="container mx-auto w-10/12">
            <Header forLogin={false} />

            <div className="mt-8" />

            <UniversalSearch
              auotSwitch={false}
              setZoom={setZoom}
              showTimePanel={showTimePanel}
              setShowTimePanel={setShowTimePanel}
            />

            <div className="mt-8" />
            <KnowledgeScreen />
          </div>
        </div>
        <div className="mt-8" />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className="flex">
        {/* <div>
          <Sidebar />
        </div> */}
        <div className="container mx-auto w-10/12">
          <Header forLogin={false} />

          <div className="mt-8" />

          <UniversalSearch
            auotSwitch={false}
            setZoom={setZoom}
            showTimePanel={showTimePanel}
            setShowTimePanel={setShowTimePanel}
          />

          <div className="mt-8" />

          <div className="flex items-strech mapbox-row w-full h-96 fade-in-bck">
            <MapBox
              zoom={zoom}
              setZoom={setZoom}
              weatherObj={weatherObj}
              sqft={sqft}
              siteCatSelected={siteCatSelected}
              setSelectedSite={setSelectedSite}
              siteMetaData={siteMetaData}
              selectedSite={selectedSite}
            />
          </div>

          <h1
            className="font-bold primary-text-color ml-2 mt-5"
            style={{ fontSize: "32px" }}
          >
            Meters
          </h1>

          <Slider className="slider-1" {...settings}>
            <div className="slide no__outline w-100 fade-in-bck py-3">
              <MetersCard
                loading={loading}
                meter="kwh"
                meterName="Kwh Usage"
                timeFrameLabel="Today"
                color="#0da4e7"
                chartBgColor="rgba(13, 164, 231, 0.06)"
              />
            </div>

            <div className="slide no__outline w-100 fade-in-bck py-3">
              <MetersCard
                loading={loading}
                meter="steam"
                meterName="Steam Usage"
                timeFrameLabel="Today"
                color="#ffba69"
                chartBgColor="rgba(255, 186, 105, 0.06)"
              />
            </div>
          </Slider>

          <div className="mt-8" />

          <h1
            className="font-bold primary-text-color ml-2"
            style={{ fontSize: "32px" }}
          >
            Equipment
          </h1>

          <Slider
            {...{
              slidesToShow: 3,
              centerMode: false,
              centerPadding: "60px",
              slidesToScroll: 1,
              arrows: true,
              infinite: false,
            }}
          >
            <div className="fade-in-bck py-3">
              <EquipmentsCard
                loading={loading}
                meter="vav"
                meterName="VAV"
                timeFrameLabel="Today"
                chartbgColor="rgba(14, 164, 231, 0.06)"
                color="rgba(14, 164, 231)"
              />
            </div>

            <div className="fade-in-bck py-3">
              <EquipmentsCard
                loading={loading}
                meter="ahu"
                meterName="AHU"
                timeFrameLabel="Today"
                chartbgColor="rgba(147,237,126, 0.06)"
                color="rgb(147,237,126)"
              />
            </div>

            <div className="fade-in-bck py-3">
              <EquipmentsCard
                loading={loading}
                meter="fcu"
                meterName="FCU"
                timeFrameLabel="Today"
                chartbgColor="rgba(250,208,178, 0.06)"
                color="rgb(250,208,178)"
              />
            </div>

            <div className="fade-in-bck py-3">
              <EquipmentsCard
                loading={loading}
                meter="chiller"
                meterName="Chiller"
                timeFrameLabel="Today"
                chartbgColor="rgba(167,169,230, 0.06)"
                color="rgb(167,169,230)"
              />
            </div>

            <div className="fade-in-bck py-3">
              <EquipmentsCard
                loading={loading}
                meter="boiler"
                meterName="Boiler"
                timeFrameLabel="Today"
                chartbgColor="rgba(237,227,157, 0.06)"
                color="rgb(237,227,157)"
              />
            </div>
          </Slider>

          <h1
            className="font-bold primary-text-color ml-2 mt-5"
            style={{ fontSize: "32px" }}
          >
            Knowledge Base
          </h1>
          <Link
            className="border p-2 rounded rounded-lg"
            to="/notionrender/aec49dbf7c1e451cb6555dd0f7ff3506"
          >
            Click to access the Richmond's knowledge base
          </Link>

          <div className="mt-8" />
          <div className="mt-8" />
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    buildings: state.buildings,
    equipmentCounts: state.buildings.equipmentCounts.count,
    points: state.buildings.equipmentCounts.points,
    selectedSiteFromGlobal: state.buildings.selectedSite,
    loadingSiteData: state.buildings.loadingSiteData,
    showSplashScreen: state.buildings.showSplashScreen,
    splashShown: state.buildings.splashShown,
  };
};

const mapDispatchToProps = {
  setAllSitesToGlobalState: setAllSites,
  setSelectedSite: handleSiteSelected,
  setAllEquipmentsToGlobalState: setAllEquipments,
  setAllPoints: setAllPoints,
  setShowSplashScreen: setShowSplashScreen,
  setSplashShown: setSplashShown,
  resetSearch: resetSearchState,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
