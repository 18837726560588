import {
  AutoComplete,
  Button,
  Divider,
  Dropdown,
  Menu,
  message,
  Spin,
} from "antd";
import MenuItem from "antd/lib/menu/MenuItem";
import SubMenu from "antd/lib/menu/SubMenu";
import { IconMap } from "antd/lib/result";
import useSelection from "antd/lib/table/hooks/useSelection";
import React, { useState } from "react";
import { FaClock } from "react-icons/fa";
import { HiOutlineEmojiHappy } from "react-icons/hi";
import { RiCloseFill, RiGalleryLine } from "react-icons/ri";
import { connect } from "react-redux";
import { classNames } from "../../../helpers/classNames";
import { generateGrad } from "../../../helpers/generateRandomGradient";
import { allMeterOptions } from "../../../redux/reducers/search.reducers";
import EmojiAndImagePicker from "../../../Resueables/EmojiAndImagePicker/EmojiAdnImagePicker";
import { siteCategories } from "../../../Resueables/UniversalSearch/SitesPopOver";
import { saveEquipmentTemplates } from "../../API/API";
import TemplateBuildingDetails from "./TemplateBuildingDetails";
import TemplatesEquipments from "./TemplatesEquipments";

function NewTemplates({
  creatingNewTemplate,
  allSites,
  setCreatingNewTemplate,
}) {
  const allOptions =
    creatingNewTemplate.type == "building"
      ? allSites.map((x) => ({ value: x.navName, id: x.id }))
      : [];
  const [options, setOptions] = useState(allOptions);

  const [name, setName] = useState("");
  const [addIcon, setAddIcon] = useState("");

  const [cover, setCover] = useState("");
  const [icon, setIcon] = useState(
    "https://images.emojiterra.com/google/noto-emoji/v2.028/128px/1f3db.png"
  );

  const [saving, setSaving] = useState(false);

  const [properties, setProperties] = useState([
    {
      name: `Equipment Type`,
      typeLabel: "Select",
      type: "select",
      value: "",
      options: [
        "VAV",
        "AHU",
        "FCU",
        "KWH",
        "Steam",
        "Chiller",
        "Boiler",
        "Cooling Tower",
      ],
      noDelete: true,
    },
  ]);

  const onSearch = (e) => {
    setName(e);
    if (e.length) {
      setOptions((prev) => {
        return prev?.filter((x) =>
          x?.value?.toLowerCase().includes(e.toLowerCase())
        );
      });
    } else {
      setOptions(allOptions);
    }
  };
  const onSelect = (e) => {
    setName(e);
  };

  const handleLinkUpload = (link) => {
    setIcon(link);
  };

  const handleCoverAdd = () => {
    setCover(generateGrad());
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleImageUpload = (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    getBase64(info.file.originFileObj, (imageUrl) => {
      setIcon(imageUrl);
    });

    // if (info.file.status === "done") {
    //   // Get this url from response in real world.
    //   // getBase64(info.file.originFileObj, (imageUrl) => {
    //   //   console.log(imageUrl);
    //   //   setUploadedImage(imageUrl);
    //   //   setUploading(false);
    //   // });
    // }
  };

  const handleAssigned = async (site, isAgroup) => {
    setSaving(true);

    let propertiesToSave = properties.filter((x) => !x.noDelete);
    propertiesToSave.map((x) => {
      delete x?.icon;
      return { ...x };
    });

    const res = await saveEquipmentTemplates(
      name,
      properties[0].value.split(" ").join("").toLowerCase(),
      propertiesToSave,
      {
        navName: site.navName,
        id: site.id || site.value,
        isAgroup: isAgroup,
      }
    );

    if (res?.data?.data?.insert_equipment_templates?.affected_rows) {
      message.success("Successfully Saved");
      setSaving(false);
      resetValues();
    }
  };

  const resetValues = () => {
    setName("");
    setIcon("");
    setCover("");
    setProperties((prev) => {
      return prev.map((x) =>
        x.noDelete || x.type == "select" || x.type == "multiselect"
          ? { ...x }
          : { ...x, value: "" }
      );
    });
  };

  return (
    <div className=" w-5/6  py-3  relative">
      <span
        onClick={() => setCreatingNewTemplate(null)}
        className="absolute font-bold text-lg right-5 top-0 cursor-pointer"
      >
        <RiCloseFill />{" "}
      </span>

      <div className="template-head">
        {cover && (
          <div className="relative">
            <div
              className={classNames("border h-44 relative mt-3 -mb-12")}
              style={{
                background: cover,
                backgroundImage: `url(${cover})`,
                backgroundSize: "100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                zIndex: -10,
              }}
            ></div>
            <div
              style={{ zIndex: 10 }}
              className="text-xs absolute right-20 bottom-5"
            >
              <Dropdown
                trigger="click"
                overlay={
                  <EmojiAndImagePicker
                    handleImageUpload={(img) => {
                      console.log(img);
                      setCover(img);
                    }}
                    handleLinkUpload={(link) => {
                      console.log(link);
                      setCover(link);
                    }}
                  />
                }
                // placement="bottomCenter"
                arrow
              >
                {/* <FaClock className="text-3xl cursor-pointer" /> */}
                <button className="bg-white border p-1 px-3">
                  Change Cover
                </button>
              </Dropdown>

              <button
                onClick={() => handleCoverAdd()}
                className="bg-white border p-1 px-3"
              >
                Random Cover
              </button>
            </div>
          </div>
        )}

        <div className="w-11/12   mx-auto" style={{ zIndex: 10 }}>
          <div
            className={classNames(
              "flex",
              addIcon ? "flex-col items-start" : ""
            )}
          >
            {addIcon ? (
              <>
                <Dropdown
                  trigger="click"
                  overlay={
                    <EmojiAndImagePicker
                      handleImageUpload={handleImageUpload}
                      handleLinkUpload={handleLinkUpload}
                    />
                  }
                  // placement="bottomCenter"
                  arrow
                >
                  {/* <FaClock className="text-3xl cursor-pointer" /> */}
                  <img src={icon} className="w-24 h-24 border" />
                </Dropdown>
              </>
            ) : (
              <span
                onClick={() => setAddIcon(true)}
                className="mr-3 flex cursor-pointer items-center p-1 text-gray-400 hover:bg-gray-200 transition"
              >
                <HiOutlineEmojiHappy className="mr-1 " /> Add Icon
              </span>
            )}

            {!cover && (
              <span
                onClick={() => handleCoverAdd()}
                className="mr-3 flex cursor-pointer items-center p-1 text-gray-400 hover:bg-gray-200 transition"
              >
                <RiGalleryLine className="mr-1" /> Add Cover
              </span>
            )}
          </div>
          <AutoComplete
            options={options}
            onSelect={onSelect}
            onSearch={onSearch}
            value={name}
          >
            <input
              type="text"
              placeholder={
                creatingNewTemplate.type == "building"
                  ? "Building Name"
                  : creatingNewTemplate.type == "equipment"
                  ? "Equipment Name"
                  : null
              }
              className="font-bold border-0 tracking-wide text-2xl outline-none bg-transparent py-3"
            />
          </AutoComplete>

          <div>
            {creatingNewTemplate.type == "building" ? (
              <TemplateBuildingDetails name={name} />
            ) : creatingNewTemplate.type == "equipment" ? (
              <TemplatesEquipments
                setProperties={setProperties}
                properties={properties}
                name={name}
              />
            ) : null}
          </div>

          {name &&
            (!saving ? (
              <div className="text-right">
                <Dropdown.Button
                  trigger="click"
                  className="overflow-hidden ml-auto"
                  overlay={
                    <Menu>
                      <Menu.ItemGroup title="Group Of Sites">
                        {siteCategories.map((cat) => (
                          <Menu.Item
                            onClick={() => handleAssigned(cat, true)}
                            key={cat.id}
                          >
                            {cat.navName}
                          </Menu.Item>
                        ))}
                      </Menu.ItemGroup>
                      <Menu.ItemGroup title="Individual Sites">
                        <SubMenu key="siteSelect" title="Select a Site">
                          {allSites.map((site) => (
                            <MenuItem
                              onClick={() => handleAssigned(site, false)}
                              key={site.id}
                            >
                              {site.navName}
                            </MenuItem>
                          ))}
                        </SubMenu>
                      </Menu.ItemGroup>
                    </Menu>
                  }
                >
                  {creatingNewTemplate.type == "building"
                    ? "Apply To"
                    : creatingNewTemplate.type == "equipment"
                    ? "Assign To"
                    : null}
                </Dropdown.Button>
              </div>
            ) : (
              <div className="text-right">
                <Button loading>Assigning</Button>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    allSites: state.buildings.allSites,
  };
};

export default connect(mapStateToProps)(NewTemplates);
