//Contains all the functions related to nodes.

const convertDataIntoNodes = (sites, selectedSite, selectedMeter) => {
  const nodes = [
    {
      id: selectedSite?.value || "1",
      title: selectedSite?.label,
      x: 453,
      y: -545,
      type: "site",
    },
    {
      id: selectedMeter?.value || "2",
      title: selectedMeter?.label,
      x: 445,
      y: 100,
      type: "site",
    },
  ];
  const edges = [
    {
      id: 1,
      source: nodes[0].id,
      target: nodes[1].id,
      type: "emptyEdge",
    },
  ];

  // let w = 800;
  // let h = 500;
  // let x = 0 - w / 2;
  // let y = 0 + h / 2;

  // let x = 1;
  // let y = 1;

  // let angle = 17;
  // console.log(sites);

  let x = 200;
  let y = 250;
  let j = 1;
  sites.map((data, index) => {
    let totalKWH = 0;

    if (data.floor.site.navName) {
      data.points[0]?.pointHistories?.map((point) => {
        totalKWH = totalKWH + point.val;
      });
      //if (totalKWH > 0) {
      nodes.push(
        addNode(
          "site",
          data.floor.site.source.id,
          `${data.floor.site.navName} => ${totalKWH}`,
          x,
          y
        )
      );
      edges.push(addEdge(nodes[1].id, data.floor.site.source.id, edges));
      const position = rotate(445, 100, x, y, 20);
      x = Math.round(position[0]);
      y = Math.round(position[1]);
      if (index > 0 && index % 8 === 0) {
        x = 200 - 120 * j;
        y = nodes[2].y + 30 * j;
        j = j + 1;
      }
      //}
    }
  });

  // sites.map((site)=>{

  //   nodes.push({id:site.navName});
  //   edges.push({source:siteCategory,target:site.navName})
  //   site.floors.map((floor)=>{
  //     nodes.push({id:floor.dis})
  //     edges.push({source:site.navName,target:floor.dis})
  //   })

  // });
  console.log(nodes);
  return { graph: { nodes, edges } };
};

const addNode = (type: string, id, title, x, y, floorId = "") => {
  const node = {
    id,
    title,
    type,
    x,
    y,
    floorId,
  };

  return node;
};

const addEdge = (sourceId, targetId, edges, edgeType) => {
  const edge = {
    id: edges?.length + 1,
    handleText: "",
    source: sourceId,
    target: targetId,
    type: edgeType,
  };

  return edge;
};

export const rotate = (cx, cy, x, y, angle) => {
  var radians = (Math.PI / 180) * angle,
    cos = Math.cos(radians),
    sin = Math.sin(radians),
    nx = cos * (x - cx) + sin * (y - cy) + cx,
    ny = cos * (y - cy) - sin * (x - cx) + cy;
  return [nx, ny];
};

const updateNodeTitle = (nodeId, nodeTitle, graphState) => {
  const newGraphState = {
    ...graphState,
    graph: {
      ...graphState.graph,
      nodes: graphState.graph.nodes.map((node) => {
        if (node.id === nodeId) {
          return {
            ...node,
            title: nodeTitle,
          };
        }
        return node;
      }),
    },
  };

  return newGraphState;
};

const updateEdgeHandleText = (edgeId, edgeHandleText, graphState) => {
  const newGraphState = {
    ...graphState,
    graph: {
      ...graphState.graph,
      edges: graphState.graph.edges.map((edge) => {
        if (edge.id === edgeId) {
          return {
            ...edge,
            handleText: edgeHandleText,
          };
        }
        return edge;
      }),
    },
  };
  return newGraphState;
};

export {
  convertDataIntoNodes,
  addNode,
  addEdge,
  updateEdgeHandleText,
  updateNodeTitle,
};
