// import tConvert from "./tConvert";
import moment from "moment";

// const addGroups = (point) => {
//   return {
//     ...point,
//   };
// };

// export const processEquipmentData = (sensor, data, lineDataDamper) => {
//   const arr = [];

//   for (let i in data) {
//     arr.push(...data[i].pointHistoryAggregates_aggregate.nodes);
//   }

//   const timestamps = Array.from(new Set(arr.map((data) => data.timestamp)));

//   timestamps.sort();

//   const timestampsWithAverage = timestamps.map((timestamp) => {
//     const dataWithTimestamp = arr
//       .filter((data) => data.timestamp === timestamp)
//       .map((val) => val.avg);
//     return addGroups({
//       time: tConvert(`${timestamp.slice(11, 13 + 3)}:00`),
//       value: Math.floor(
//         dataWithTimestamp.reduce((a, b) => a + b) / dataWithTimestamp.length
//       ),
//     });
//   });

//   const timestampsWithMaxAndMin = timestamps.map((timestamp) => {
//     const dataWithTimestamp = arr
//       .filter((data) => data.timestamp === timestamp)
//       .map((val) => val.avg)
//       .sort((a, b) => a.time - b.time);

//     return {
//       time: tConvert(`${timestamp.slice(11, 13 + 3)}:00`),
//       value: [
//         Math.floor(dataWithTimestamp[0]),
//         Math.floor(dataWithTimestamp[dataWithTimestamp.length - 1]),
//       ],
//     };
//   });

//   // console.log(sensor, timestampsWithAverage);

//   // timestampsWithMaxAndMin.reverse();
//   // timestampsWithAverage.reverse();

//   // console.log(sensor, timestampsWithMaxAndMin, timestampsWithAverage);

//   // for (let i in timestampsWithMaxAndMin) {
//   //   chartData.datasets[0].data.push(timestampsWithMaxAndMin[i].value[0]); /// MAX
//   //   chartData.datasets[2].data.push(timestampsWithMaxAndMin[i].value[1]); //MIN
//   // }

//   for (let i in timestampsWithAverage) {
//     lineDataDamper.labels.push(timestampsWithAverage[i].time);
//     lineDataDamper.datasets[0].data.push(timestampsWithAverage[i].value); // AVG
//     lineDataDamper.sensor = sensor;
//   }

//   return lineDataDamper;
// };

// const generateChartDataFromEquipmentPoints = (vavData) => {
//   try {
//     const allPoints = Object.values(vavData);
//     const allSensors = Object.keys(vavData);

//     const allEquipCharts = [];

//     for (let i in allPoints) {
//       const chartProperites = allCharts.filter(
//         (chart) => chart.sensorName == allSensors[i]
//       );

//       const processedPoint = processEquipmentData(
//         allSensors[i],
//         allPoints[i],
//         {
//           labels: [],
//           datasets: [
//             {
//               borderColor: "#000",
//               borderWidth: 2,

//               barThickness: 10,
//               tension: 0.1,
//               pointBorderWidth: 2,
//               lineTension: 0,
//               pointRadius: 4,
//               pointBackgroundColor: "rgba(255,255,255,1)",
//               data: [],
//             },
//           ],
//         }
//       );

//       allEquipCharts.push(processedPoint);
//       if (processedPoint.datasets[0].data.length) {
//         processedPoint.metaData = maxMinAvg(processedPoint.datasets[0].data);
//         processedPoint.unit = chartProperites[0].unit;
//       }
//     }
//     return allEquipCharts;
//   } catch (e) {
//     console.log("ERROR", e);
//   }
// };

export const processEquipmentPoints = (eqData) => {
  // console.log(Object.keys(eqData));

  try {
    const allMetrics = Object.keys(eqData);

    let finalData = [];
    let processedPoints = [];

    //PROCESS ALL  POINTS OTHER THAN EQUIPS

    allMetrics.forEach((metric) => {
      finalData = [];
      if (metric == "equip") {
        return;
      }

      let sum = 0;

      let arr = [];

      eqData[metric].forEach((point) => {
        arr.push(...point.pointHistoryAggregates_aggregate.nodes);
      });

      const groupedValues = Object.values(groupBy([...arr], "timestamp"));
      const groupedTimeStamps = Object.keys(groupBy([...arr], "timestamp"));

      // console.log();

      groupedTimeStamps.forEach((timestamp) => {
        let sum = 0;
        groupBy([...arr], "timestamp")[`${timestamp}`].forEach((val) => {
          sum += val.avg;
        });

        let avg = sum / groupBy([...arr], "timestamp")[`${timestamp}`].length;

        // finalData.push({
        //   name: point.navName
        // })

        finalData.unshift({
          timestamp: timestamp,
          sum: sum,
          avg: avg,
          unit: eqData[metric][0].unit,
          name: eqData[metric][0].navName,
        });
      });

      processedPoints.push(finalData);
    });

    // PROCESS OCC STATS

    let eqArray = [],
      finalOccCount = [];
    eqData.equip.forEach((point) => {
      if (point.points.length) {
        eqArray.push(...point.points[0].pointHistories);
      }
    });

    const groupedTimeStamps = Object.keys(groupBy(eqArray, "ts"));
    const groupdedValues = Object.values(groupBy(eqArray, "ts"));

    groupedTimeStamps.forEach((timestamp) => {
      let occCount = 0;
      groupBy([...eqArray], "ts")[`${timestamp}`].forEach((val) => {
        occCount += val.val;
      });

      finalOccCount.push({
        timestamp: timestamp,
        occCount: occCount,
        total: groupBy([...eqArray], "ts")[`${timestamp}`].length,
        occPer:
          (occCount / groupBy([...eqArray], "ts")[`${timestamp}`].length) * 100,
        unit: "%",
        name: eqData.equip[0].navName,
        isOcc: true,
      });
    });

    const onlyHoursOcc = finalOccCount.filter(
      (x) => moment(x.timestamp.slice(0, -6)).format("mm") == 0
    );

    processedPoints.push(onlyHoursOcc);

    return processedPoints;
  } catch (e) {
    console.log(e);
  }
};

const groupBy = (array, key) => {
  // Return the end result
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};
