import React, { useContext } from "react";
import { connect } from "react-redux";
import { BuildingsContext } from "../../Contexts/BuildingsContext";
import Loader from "../../Resueables/Loader/Loader";
import ProgressBar from "../../Resueables/ProgressBar/ProgressBar";

function BuildingDetailsTab({
  selectedSite,
  occupyAndCount,
  kwhTotal,
  steamTotal,
  allSites,
}) {
  const colorPallette = ["#9ba3af", "#3c82f6", "#12b981"];

  return (
    <>
      <div className="text-sm w-full">
        <div className=" flex items-start justify-between">
          <div className="w-full">
            <h2 className="text-sm font-bold  mt-8 flex items-center justify-between primary-text-color">
              <span>
                {selectedSite && selectedSite.navName} {"  "}
              </span>
              <div className="text-xs ml-auto">
                {selectedSite && selectedSite.elecMeterId && (
                  <span className="badge kwh p-1 text-xs  font-medium px-2 mr-2 rounded-full cursor-pointer">
                    Kwh
                  </span>
                )}
                {selectedSite && selectedSite.steamMeterId && (
                  <span className="badge steam p-1 text-xs  font-medium px-2 mr-2 rounded-full cursor-pointer">
                    Steam
                  </span>
                )}
              </div>
            </h2>
          </div>
        </div>

        <div className="mt-4 block w-full aspect-w-10 aspect-h-7 rounded-lg overflow-hidden">
          <img
            src="https://images.static-collegedunia.com/public/image/2a63cae5d6f3047267286f770f0b0ebb.jpeg?tr=w-1100,h-550,c-force"
            alt=""
            className="object-cover"
          />
        </div>
      </div>

      <div className=" details-box p-5">
        <h3 className="font-medium primary-text-color ">Information</h3>
        <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
          <div className="py-3 flex justify-between text-sm font-medium">
            <dt className="primary-text-color">Sqft</dt>
            <dd className=" text-lg">{selectedSite && selectedSite.area}</dd>
          </div>
          {selectedSite && selectedSite.floors && !selectedSite.isAGroup && (
            <div className="py-3 flex justify-between text-sm font-medium">
              <dt className="primary-text-color">Floors</dt>
              <dd className=" text-lg">{selectedSite.floors.length}</dd>
            </div>
          )}

          {selectedSite && selectedSite.source && !selectedSite.isAGroup && (
            <div className="py-3 flex justify-between text-sm font-medium">
              <dt className="primary-text-color">Location</dt>
              <dd className=" text-lg">
                <>
                  {selectedSite.source.geoCity || "Richmond"},{" "}
                  {selectedSite.source.geoCountry || "US"}
                </>
              </dd>
            </div>
          )}

          {selectedSite.isAGroup && (
            <>
              <div className="py-3 flex justify-between text-sm font-medium">
                <dt className="primary-text-color">No of Sites</dt>
                <dd className=" text-lg">
                  {
                    allSites.filter((x) =>
                      x.source
                        ? Object.keys(x.source).includes(
                            selectedSite.id || "site"
                          )
                        : null
                    ).length
                  }
                </dd>
              </div>

              <div className="py-3 flex justify-between text-sm font-medium">
                <div class="tabs w-full">
                  <div class="tab w-full">
                    <input type="checkbox" id="chck1" />
                    <label
                      class="tab-label  w-full  bg-transparent"
                      for="chck1"
                    >
                      See {selectedSite.navName}
                    </label>
                    <div class="tab-content">
                      <ul role="list" className="divide-y divide-gray-200">
                        {allSites
                          .filter((x) =>
                            x.source
                              ? Object.keys(x.source).includes(
                                  selectedSite.id || "site"
                                )
                              : null
                          )
                          .map((site) => (
                            <li key={site.id} className="py-4 flex">
                              <div className="ml-3 flex justify-between items-center w-full">
                                <p className="text-sm font-medium ">
                                  {site.navName}
                                </p>
                                <div className="ml-auto">
                                  {site.elecMeterId && (
                                    <span className="kwh badge inline-flex items-center shadow-sm px-2.5 py-0.5 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50  inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                      Kwh
                                    </span>
                                  )}

                                  {site.steamMeterId && (
                                    <span className="steam  badge inline-flex items-center shadow-sm px-2.5 py-0.5 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50  inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                      Steam
                                    </span>
                                  )}
                                </div>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </dl>
      </div>

      {Object.keys(occupyAndCount).length ? (
        <div className=" details-box  p-5">
          <h3 className="font-medium primary-text-color ">Equipment Counts</h3>
          <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
            {Object.keys(occupyAndCount).map((x) =>
              occupyAndCount[x].occCount ? (
                <div className="py-3  text-sm font-medium">
                  <ProgressBar
                    makeTransparent
                    progress={
                      (occupyAndCount[x].occCount / occupyAndCount[x].count) *
                      100
                    }
                    fillColor={
                      colorPallette[
                        Math.floor(
                          Math.random() * (colorPallette.length - 0) + 0
                        )
                      ]
                    }
                    backGroundColor=""
                    text={x.toUpperCase()}
                  ></ProgressBar>
                  <dt className="primary-text-color mt-3">
                    {occupyAndCount[x].occCount} of {occupyAndCount[x].count}{" "}
                    Active{" "}
                  </dt>
                </div>
              ) : null
            )}
          </dl>
        </div>
      ) : (
        <div className=" details-box primary-text-color   p-5 flex  items-center">
          <Loader size="20px" />
          <span className="ml-3 primary-text-color">
            Loading Equipment Stasuses
          </span>
        </div>
      )}

      <div className=" details-box  primary-text-color p-5">
        <h3 className="font-medium primary-text-color">Energy Total</h3>
        <div className="mt-2 border-t border-gray-200">
          <div className="pt-3 flex justify-between text-sm font-medium">
            <p className="m-0">Today</p>
            <p className="text-lg m-0">
              {localStorage.getItem("kwhTotal")} KwH
            </p>
          </div>
        </div>
      </div>

      <div className=" details-box primary-text-color  p-5">
        <h3 className="font-medium primary-text-color">Steam Total</h3>
        <div className="mt-2 border-t border-gray-200">
          <div className="pt-3 flex justify-between text-sm font-medium">
            <p className="m-0">Today</p>
            <p className="text-lg m-0">
              {localStorage.getItem("steamTotal")} lb/h
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default BuildingDetailsTab;
