import React from "react";
import { HiPaperClip } from "react-icons/hi";
import { alEquipmentDetails } from "../../helpers/allEquipmentDetails";
import { useParams } from "react-router-dom";
import Loader from "../../Resueables/Loader/Loader";
import vav from "../../images/VAV.png";
import ahu from "../../images/AHU.png";
import fcu from "../../images/FCU.png";
import chiller from "../../images/Chiller.png";
import boiler from "../../images/boiler.png";
import kwh from "../../images/kwhMeter.png";
import steam from "../../images/steamMeter.png";

function MeterDetailsTab({ selectedSite, meterInfo }) {
  const params = useParams();
  return (
    <>
      <div className="mt-10" />
      <div className=" p-2">
        <p className="text-xs">Equipment Selected</p>
        <p className="text-xl">
          <span className="font-semibold">
            {alEquipmentDetails[params.meter].label}
          </span>{" "}
          <span className="text-sm">
            {" "}
            at <span className="font-semibold">{selectedSite.navName} </span>
          </span>
        </p>

        <p className="mt-5">{alEquipmentDetails[params.meter].description}</p>
      </div>

      <div className="details-box p-5">
        <img
          src={
            params.meter == "vav"
              ? vav
              : params.meter == "ahu"
              ? ahu
              : params.meter == "fcu"
              ? fcu
              : params.meter == "heat"
              ? boiler
              : params.meter == "chiller"
              ? chiller
              : params.meter == "kwh"
              ? kwh
              : params.meter == "steam"
              ? steam
              : null
          }
        />
      </div>

      {alEquipmentDetails[params.meter].points.length ? (
        <div className="details-box p-5">
          <h3 className="font-medium primary-text-color">Points</h3>
          <div className="accordion">
            <div class="tabs">
              <div class="tab sensor">
                <input type="checkbox" id="chck1" />
                <label class="tab-label   label-sensor" for="chck1">
                  Sensor
                  <span className="ml-3 underline color-darkblue">
                    (
                    {
                      alEquipmentDetails[params.meter].points.filter(
                        (x) => x.type == "sensor"
                      ).length
                    }{" "}
                    per {params.meter})
                  </span>
                </label>
                <div class="tab-content">
                  <ul role="list" className="divide-y divide-gray-200">
                    {alEquipmentDetails[params.meter].points
                      .filter((x) => x.type == "sensor")
                      .map((point) => (
                        <li key={point.id} className="py-4 flex">
                          <div>
                            <p className="text-sm font-medium primary-text-color flex items-center justify-between">
                              <span>{point.name}</span>
                              <span style={{ color: "#fab788" }}>
                                {point.range}
                              </span>
                            </p>
                            <p className="text-xs primary-text-color items-center flex flex-wrap ">
                              <span>HayStack Tags:</span>
                              {point.haystackTags.map((tag) => (
                                <span className=" ml-1 mt-1 text-xs sensor-badge  font-medium rounded-full text-white  rounded-full text-xs font-medium bg-gray-100 primary-text-color  px-2 leading-5">
                                  {tag}
                                </span>
                              ))}
                            </p>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>

              <div class="tab command">
                <input type="checkbox" id="chck2" />
                <label class="tab-label label-command" for="chck2">
                  Command
                  <span className="ml-3 underline color-darkblue">
                    (
                    {
                      alEquipmentDetails[params.meter].points.filter(
                        (x) => x.type == "command"
                      ).length
                    }{" "}
                    per {params.meter})
                  </span>
                </label>
                <div class="tab-content">
                  <ul role="list" className="divide-y divide-gray-200">
                    {alEquipmentDetails[params.meter].points
                      .filter((x) => x.type == "command")
                      .map((point) => (
                        <li key={point.id} className="py-4 flex">
                          <div>
                            <p className="text-sm font-medium primary-text-color flex items-center justify-between">
                              <span>{point.name}</span>
                              <span style={{ color: "#fab788" }}>
                                {point.range}
                              </span>
                            </p>
                            <p className="text-xs primary-text-color items-center flex flex-wrap ">
                              <span>HayStack Tags:</span>
                              {point.haystackTags.map((tag) => (
                                <span className=" ml-1 mt-1 text-xs sensor-badge  font-medium rounded-full text-white  rounded-full text-xs font-medium bg-gray-100 primary-text-color  px-2 leading-5">
                                  {tag}
                                </span>
                              ))}
                            </p>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>

              <div class="tab setpoint">
                <input type="checkbox" id="chck3" />
                <label class="tab-label label-setpoint" for="chck3">
                  Setpoint
                  <span className="ml-3 underline color-darkblue">
                    (
                    {
                      alEquipmentDetails[params.meter].points.filter(
                        (x) => x.type == "setpoint"
                      ).length
                    }{" "}
                    per {params.meter})
                  </span>
                </label>
                <div class="tab-content">
                  <ul role="list" className="divide-y divide-gray-200">
                    {alEquipmentDetails[params.meter].points
                      .filter((x) => x.type == "setpoint")
                      .map((point) => (
                        <li key={point.id} className="py-4 flex">
                          <div>
                            <p className="text-sm font-medium primary-text-color flex items-center justify-between">
                              <span>{point.name}</span>
                              <span style={{ color: "#fab788" }}>
                                {point.range}
                              </span>
                            </p>
                            <p className="text-xs primary-text-color items-center flex flex-wrap ">
                              <span>HayStack Tags:</span>
                              {point.haystackTags.map((tag) => (
                                <span className=" ml-1 mt-1 text-xs sensor-badge  font-medium rounded-full text-white  rounded-full text-xs font-medium bg-gray-100 primary-text-color  px-2 leading-5">
                                  {tag}
                                </span>
                              ))}
                            </p>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="details-box p-5">
        <h3 className="font-medium primary-text-color">Information</h3>
        <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
          {console.log("METER INFO", meterInfo)}
          {meterInfo && Object.keys(meterInfo).length ? (
            meterInfo.map((info) => (
              <div className="py-3 flex justify-between items-center text-sm font-medium">
                <dt className="primary-text-color">{info.key}</dt>
                <dd className="primary-text-color text-lg"> {info.value}</dd>
              </div>
            ))
          ) : (
            <div className="flex items-center">
              <Loader size="25px" />
              <span className="ml-2">Loading Equipment Information</span>
            </div>
          )}
        </dl>
      </div>

      <div className="details-box p-3">
        <div className=" sm:grid sm:grid-cols-1 ">
          <h3 className="font-medium primary-text-color">Attachments</h3>
          <dd className="mt-1 text-sm primary-text-color sm:mt-0 sm:col-span-2">
            <ul
              role="list"
              className="border border-gray-200 rounded-md divide-y divide-gray-200"
            >
              <li className=" pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                <div className="w-0 flex-1 flex items-center my-2">
                  <HiPaperClip className="flex-shrink-0 h-5 w-5 primary-text-color" />
                  <span className="ml-2 flex-1 w-0 truncate">
                    {alEquipmentDetails[params.meter].label}.pdf
                  </span>
                </div>
                <div className="ml-4 flex-shrink-0">
                  <a
                    href="#"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Download
                  </a>
                </div>
              </li>

              <li className=" pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                <div className="w-0 flex-1 flex items-center">
                  <HiPaperClip className="flex-shrink-0 h-5 w-5 primary-text-color" />
                  <span className="ml-2 flex-1 w-0 truncate">
                    {alEquipmentDetails[params.meter].label}_2.pdf
                  </span>
                </div>
                <div className="ml-4 flex-shrink-0">
                  <a
                    href="#"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Download
                  </a>
                </div>
              </li>
            </ul>
          </dd>
        </div>
      </div>
    </>
  );
}

export default MeterDetailsTab;
