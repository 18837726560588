import React from "react";
import { BsBuilding } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";

function Templates({ setCreatingNewTemplate }) {
  const buildingTemplates = [
    {
      name: "All Buildings",
    },
    {
      name: "Utility Buildings",
    },
    {
      name: "Business School",
    },
    {
      name: "Modlin Center",
    },
  ];
  const eqTemplates = [
    {
      name: "Equipments Template 1",
    },
    {
      name: "Equipments Template 2",
    },
  ];

  return (
    <div className="   w-5/6 p-3">
      <div>
        <h1 className="font-bold text-lg ml-1 text-gray-700 flex items-center">
          <span className="mr-2">
            <BsBuilding />{" "}
          </span>
          Building Templates
        </h1>
        <ul className="templates mt-3 flex items-stretch flex-wrap">
          {buildingTemplates.map((x) => (
            <div className="w-1/3">
              <li
                //   onClick={() => handleItemClick(item)}
                //   key={item.title}
                className="relative rounded-lg border border-gray-300 bg-white  shadow-sm  items-center space-x-3 hover:border-gray-400  cursor-pointer  my-1 mx-1 "
              >
                <div className="flex-shrink-0 h-32 border-b border-gray-300"></div>

                <div className="flex-1 min-w-0 p-2">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="text-sm  m-0 font-normal">{x.name}</p>
                </div>
              </li>
            </div>
          ))}
          <div className="w-1/3">
            <li
              onClick={() => setCreatingNewTemplate({ type: "building" })}
              //   key={item.title}
              className="relative rounded-lg border border-gray-300 bg-white  shadow-sm bg-gray-200  items-center space-x-3 hover:border-gray-400  cursor-pointer  my-1 mx-1 "
            >
              <div className="flex-shrink-0 flex items-center justify-center h-32 border-b border-gray-300">
                <p className=" ">Click to add your own template</p>
              </div>

              <div className="flex-1 min-w-0 p-2">
                <span className="absolute inset-0" aria-hidden="true" />
                <p className="text-sm  m-0 font-normal">+ New</p>
              </div>
            </li>
          </div>
        </ul>
      </div>
      <div className="spacer" />
      <div>
        <h1 className="font-bold text-lg ml-1 text-gray-700 flex items-center">
          <span className="mr-2">
            <FiSettings />
          </span>
          Equipment Templates
        </h1>
        <ul className="templates mt-3 flex flex-wrap">
          {eqTemplates.map((x) => (
            <div className="w-1/3">
              <li
                //   onClick={() => handleItemClick(item)}
                //   key={item.title}
                className="relative rounded-lg border border-gray-300 bg-white  shadow-sm  items-center space-x-3 hover:border-gray-400  cursor-pointer  my-1 mx-1 "
              >
                <div className="flex-shrink-0 h-32 border-b border-gray-300"></div>

                <div className="flex-1 min-w-0 p-2">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="text-sm  m-0 font-normal">{x.name}</p>
                </div>
              </li>
            </div>
          ))}
          <div className="w-1/3">
            <li
              onClick={() => setCreatingNewTemplate({ type: "equipment" })}
              //   key={item.title}
              className="relative rounded-lg border border-gray-300 bg-white  shadow-sm bg-gray-200  items-center space-x-3 hover:border-gray-400  cursor-pointer  my-1 mx-1 "
            >
              <div className="flex-shrink-0 flex items-center justify-center h-32 border-b border-gray-300">
                <p className=" ">Click to add your own template</p>
              </div>

              <div className="flex-1 min-w-0 p-2">
                <span className="absolute inset-0" aria-hidden="true" />
                <p className="text-sm  m-0 font-normal">+ New</p>
              </div>
            </li>
          </div>
        </ul>
      </div>
    </div>
  );
}

export default Templates;
