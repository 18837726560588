import React, { useState } from "react";
import { Select, Divider, Input, DatePicker, Upload, Button } from "antd";
import { IoMdAdd } from "react-icons/io";
import { AiOutlineUpload } from "react-icons/ai";
import Checkbox from "antd/lib/checkbox/Checkbox";
import moment from "moment";
const { Option } = Select;

function InputRenderer({
  type,
  handleOnInputChange,
  propertyIndex,
  value,
  options,
}) {
  const [items, setItems] = useState(options ? [...options] : []);
  const [name, setName] = useState("");
  const [index, setIndex] = useState(1);

  const onNameChange = (e) => {
    setName(e.target.value);
  };

  const addItem = () => {
    setIndex((x) => x++);
    setItems((prev) => [...prev, name || `New Item ${index}`]);
    setName("");
  };

  if (type == "select") {
    return (
      <Select
        // defaultValue={value[0]}
        value={value}
        style={{ width: 240 }}
        placeholder="Select"
        name="select"
        onChange={(e) =>
          handleOnInputChange(type, e, "value", propertyIndex, null, items)
        }
        dropdownRender={(menu) => (
          <div>
            {menu}

            <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
              <Input
                style={{ flex: "auto" }}
                value={name}
                onChange={onNameChange}
              />
              <a
                className="flex w-10 text-center block items-center"
                onClick={addItem}
              >
                <IoMdAdd className="mx-auto" />
              </a>
            </div>
          </div>
        )}
      >
        {items.map((item) => (
          <Option key={item}>{item}</Option>
        ))}
      </Select>
    );
  }
  if (type == "multiselect") {
    return (
      <Select
        mode="tags"
        placeholder="Multiple Select"
        style={{ width: "200px" }}
        onChange={(e) => handleOnInputChange(type, e, "value", propertyIndex)}
        tokenSeparators={[","]}
        name="multiselect"
        defaultValue={value}
      >
        <Option key="1">ONE</Option>
        <Option key="2">TWO</Option>
        <Option key="3">THREE</Option>
        {/* {value?.map((val) => (
          <Option key={val}>{val}</Option>
        ))} */}
      </Select>
    );
  }

  if (type == "date") {
    return (
      <>
        <DatePicker
          defaultValue={value ? moment(value) : null}
          name="date"
          onChange={(e) => handleOnInputChange(type, e, "value", propertyIndex)}
        />
      </>
    );
  }

  if (type == "file") {
    const props = {
      name: "file",
      action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
      headers: {
        authorization: "authorization-text",
      },
      onChange(info) {
        console.log(info);
        handleOnInputChange(type, info);
      },
    };
    return (
      <Upload {...props}>
        <Button>Click to Upload</Button>
      </Upload>
    );
  }

  if (type == "checkbox") {
    return (
      <Checkbox
        checked={value}
        name="checkbox"
        onChange={(e) =>
          handleOnInputChange(type, e.target.checked, "value", propertyIndex)
        }
      ></Checkbox>
    );
  }

  return (
    <input
      onChange={(e) =>
        handleOnInputChange(type, e.target.value, "value", propertyIndex)
      }
      value={value}
      type={type}
      placeholder="Empty"
      className="w-full bg-transparent h-full  p-1  text-black outline-none"
      name={type}
    />
  );
}

export default InputRenderer;
