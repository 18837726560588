export const options = {
  plugins: {
    legend: {
      display: false,
    },
  },

  maintainAspectRatio: false,
  responsive: true,
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: {
          style: "normal",
          weight: "100",
        },
      },
    },
    leftYaxis: {
      position: "left",
      grid: {
        display: false,
      },
      ticks: {
        autoSkip: false,
        precision: 1,
        beginAtZero: true,

        font: {
          style: "normal",
          weight: "100",
        },
      },
    },
  },

  elements: {
    line: {
      fill: false,
    },
  },
};
