import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { randomNumber } from "../../../helpers/getRandomNumber";
import { getWeather } from "../../API/API";
import { options } from "../displayCharOptions";
import { Chart } from "chart.js";
import moment from "moment";
import { FaTemperatureHigh } from "react-icons/fa";

function KwhVsWeatherForWeek({ activeSlide, index }) {
  const [weatherChartInstance, setWeatherChartInstance] = useState({});
  const [startDate, setStartDate] = useState("2022-02-24");
  const [endDate, setEndDate] = useState("2022-03-02");
  const [weather, setWeather] = useState([]);
  useEffect(() => {
    (async () => {
      const weatherRes = await getWeather(startDate, endDate, "day");
      let weather = weatherRes.data.data.point.filter((point) => {
        if (
          point &&
          point.pointHistoryAggregates &&
          point.pointHistoryAggregates.length
        ) {
          return true;
        }
      });

      if (weather.length) {
        weather = weather[0].pointHistoryAggregates;
      }
      setWeather(weather);
    })();
  }, []);

  useEffect(() => {
    if (weather) {
      plotWeatherChart(weather);
    }
  }, [weather]);

  const plotWeatherChart = (weather) => {
    try {
      if (Object.keys(weatherChartInstance).length) {
        weatherChartInstance?.destroy();
      }

      const ctxDOM = document.querySelector("#weatherChart").getContext("2d");

      const data = {
        labels: weather.map((x) => x.timestamp),
        datasets: [
          // {
          //   data: weather.map((x) => x.avg),
          //   backgroundColor: "#ffba69",
          //   borderWidth: 1,
          //   type: "line",
          //   borderColor: "#64E480",
          //   pointRadius: 2,
          //   pointHoverRadius: 5,
          //   borderWidth: 3,
          //   lineTension: 0.5,
          // },
          {
            data: weather.map((x) => randomNumber(0, 500)),
            backgroundColor: "#0EA4E7",
            borderWidth: 1,
            type: "bar",
            pointRadius: 2,
            pointHoverRadius: 5,
            borderWidth: 3,
            lineTension: 0.5,
            yAxisID: "rightYaxis",
          },
        ],
      };

      const optionsForWtVsKw = { ...options };

      optionsForWtVsKw["scales"]["rightYaxis"] = {
        position: "right",
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          precision: 1,
          beginAtZero: true,

          font: {
            style: "normal",
            weight: "100",
          },
        },
      };

      var myChart = new Chart(ctxDOM, {
        type: "line",
        data: data,
        options: optionsForWtVsKw,
      });

      setWeatherChartInstance(myChart);
    } catch (err) {
      console.log("ERROR", err);
      if (Object.keys(weatherChartInstance).length) {
        weatherChartInstance?.destroy();
      }
    }
  };

  return (
    <motion.div
      animate={activeSlide == index ? { opacity: 1, scaleY: 1 } : {}}
      initial={{ opacity: 0, scaleY: 0 }}
      transition={{ duration: 1 }}
      className="card p-3"
      style={{ originY: 1 }}
    >
      <div className="weather-plot flex justify-between px-14">
        {weather.map((x) => (
          <div className="weather flex items-center card p-3">
            <div>
              <FaTemperatureHigh />
            </div>
            <div className="ml-3">
              <p className="max m-0 text-red-600">{Math.round(x.max)} °F</p>
              <p className="min m-0 text-blue-600">{Math.round(x.min)} °F</p>
            </div>
          </div>
        ))}
      </div>
      <div style={{ height: "56vh" }}>
        <canvas id="weatherChart"></canvas>
      </div>
    </motion.div>
  );
}

export default KwhVsWeatherForWeek;
