import React, { useEffect, useState } from "react";
import { Steps, Divider } from "antd";
import { BsBuilding, BsGearWideConnected } from "react-icons/bs";
import { GiGears } from "react-icons/gi";
import { BiLoader } from "react-icons/bi";
import { equipmentsByFloor, sitesByGroup } from "../buildRealtionshipDiagrams";
import { connect } from "react-redux";
import axios from "axios";
const { Step } = Steps;

function Sync({
  selectedSite,
  dormSite,
  utilitiesSite,
  auxSite,
  athleticSite,
  acadSite,
  allSites,
}) {
  const [steps, setSteps] = useState([
    {
      label: "Syncing All Sites by group",
      value: "syncSites",
      status: "wait",
      icon: <BsBuilding />,
    },
    {
      label: "Syncing All Equipments by site",
      status: "wait",
      value: "syncEq",
      icon: <GiGears />,
    },
    {
      label: "Syncing All Equipments Relation",
      status: "wait",
      value: "syncEqRel",
      icon: <BsGearWideConnected />,
    },
  ]);

  const [currentlyActive, setCurrentlyActive] = useState(null);

  useEffect(() => {
    const sitesByGroupText = sitesByGroup(
      utilitiesSite,
      dormSite,
      auxSite,
      athleticSite,
      acadSite
    );

    const eqFloorsText = equipmentsByFloor(allSites);
    console.log(eqFloorsText);

    const eq_eq_Text = `graph LR`;

    (async () => {
      setCurrentlyActive(0);
      const siteSyncRes = await axios.post("http://localhost:8080/syncsites", {
        mermaidText: sitesByGroupText,
      });
      setCurrentlyActive((prev) => prev + 1);
      const syncEqRes = await axios.post(
        "http://localhost:8080/syncfloorsandeq",
        {
          mermaidText: eqFloorsText,
        }
      );

      // setCurrentlyActive((prev) => prev + 1);
      // const syncEq_EqRes = await axios.post(
      //   "http://localhost:8080/synceq_eq",
      //   {
      //     mermaidText: eq_eq_Text,
      //   }
      // );
      // setCurrentlyActive((prev) => prev + 1);
    })();
  }, []);

  return (
    <>
      <h1 className="text-center my-12 text-lg">
        Please wait while NOVO syncs to NOTION
      </h1>
      {console.log(steps)}
      <Steps current={currentlyActive}>
        {steps.map((step, idx) => (
          <Step
            // status=
            title={step.label}
            icon={idx == currentlyActive ? <BiLoader /> : step.icon}
          />
        ))}
      </Steps>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    allSites: state.buildings.allSites,
    utilitiesSite: state.buildings.utilitiesSite,
    acadSite: state.buildings.acadSite,
    athleticSite: state.buildings.athleticSite,
    auxSite: state.buildings.auxSite,
    dormSite: state.buildings.dormSite,
    selectedSite: state.buildings.selectedSite,
  };
};

export default connect(mapStateToProps, null)(Sync);
