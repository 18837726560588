export const INCREMENT_COUNT = "INCREMENT_COUNT";

export const SET_ALL_SITES = "SET_ALL_SITES";

export const SET_CHART_DATA = "SET_CHART_DATA";

export const SET_ALL_EQUIPMENTS = "SET_ALL_EQUIPMENTS";
export const SET_ALL_POINTS = "SET_ALL_POINTS";

export const SET_SELECTED_SITE = "SET_SELECTED_SITE";

export const HANDLE_METER_CHANGE = "HANDLE_METER_CHANGE";

export const HANDLE_TIME_CHANGE = "HANDLE_TIME_CHANGE";

export const SET_LOADING_SITE_DATA = "SET_LOADING_SITE_DATA";

export const SET_ERROR_MODAL = "SET_ERROR_MODAL";
export const SET_COST_ACTIVE = "SET_COST_ACTIVE";

export const SET_SPLASH_SCREEN = "SET_SPLASH_SCREEN";
export const SET_SPLASH_SHOWN = "SET_SPLASH_SHOWN";

export const HANDLE_METRIC_MULTI_SELECT = "HANDLE_METRIC_MULTI_SELECT";

export const RESET_SEARCH_STATE = "RESET_SEARCH_STATE";

export const SET_ALL_UTILITY_METERS = "SET_ALL_UTILITY_METERS";

//  NOTION CLONE ACTION TYPES

export const ADD_LANE = "ADD_LANE";
export const DELETE_LANE = "DELETE_LANE";
export const EDIT_LANE = "EDIT_LANE";
export const SET_LANES = "SET_LANES";

export const ADD_TASK = "ADD_TASK";
export const DELETE_TASK = "DELETE_TASK";
export const EDIT_TASK = "EDIT_TASK";
export const SET_TASKS = "GET_ALL_TASKS";
