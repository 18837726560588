import React, { Fragment, useEffect, useState } from "react";
import { Popover, Transition, Dialog } from "@headlessui/react";
import { classNames } from "../../../helpers/classNames";
import { AiFillThunderbolt } from "react-icons/ai";
import { GiSteam } from "react-icons/gi";
import { FiBox, FiChevronDown } from "react-icons/fi";
import { connect, useStore } from "react-redux";
import { Link } from "react-router-dom";
import MeterSelectorCard from "./MeterSelectorCard";
import { useHistory } from "react-router-dom";
import { handleMeterStateChange } from "../../../redux/actions";
import BuildingDetailsCard from "../../../components/BuildingDetailsCard/BuildingDetailsCard";

interface Props {}

function MeterPopOver(props: Props) {
  const {
    // btnLabel,
    siteBtnRef,
    panelToggleHandler,
    meterBtnRef,
    timeBtnRef,
    meterOptions,
    checkIfPanelIsOpen,
    handleMeterChange,
    equipmentCounts,
    meterLabel,
    isAGroup,
    showTimePanel,
    panelOpen,
    selectedSite,
    detailsPanelOpen,
    loadingSiteData,
    forcedGraphView,
    showGraph,
    handleMeterStateChange,
  } = props;

  if (!equipmentCounts) {
    return <></>;
  }
  const [noOfCards, setNoOfCards] = useState(null);

  const history = useHistory();

  const [tabs, setTabs] = useState([
    {
      name: "Meters",
      tab: "meter",
      current: true,
    },
    {
      name: "Equipment",
      tab: "equipment",
      current: false,
    },
  ]);

  const handleTabChange = (tab) => {
    setTabs(
      tabs.map((x) =>
        x.name == tab.name
          ? { ...tab, current: true }
          : { ...x, current: false }
      )
    );
  };

  useEffect(() => {
    if (selectedSite.isAGroup) {
      setTabs((prev) => {
        return [
          ...prev,
          {
            name: "Utilities",
            tab: "utility",
            current: false,
          },
        ];
      });
    } else {
      setTabs((prev) => {
        return prev.filter((tab) => tab.tab !== "utility");
      });
    }
  }, [selectedSite]);

  useEffect(() => {
    const noOfCards = meterOptions
      .filter((x) => !x.isUtility)
      .filter((opt) => equipmentCounts[opt.value + "Count"]).length;
    setNoOfCards(noOfCards);
  }, [equipmentCounts]);

  return (
    <Popover className="selection meter">
      {({ open }) => (
        <>
          {/**/}
          <Popover.Button
            className={`group bg-white popover-btn  inline-flex rounded rounded-lg items-center text-base font-medium  opacity-30 primary-text-color opacity-100 p-2`}
          >
            <span
              ref={meterBtnRef}
              onClick={() => {
                panelToggleHandler(open, "meter");
              }}
            >
              {meterLabel}
            </span>
            <span className="ml-1">
              <FiChevronDown />
            </span>
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-1"
          >
            <Popover.Panel
              className="absolute inset-x-0 panel transform shadow-lg mx-auto rounded rounded-xl overflow-hidden"
              style={{
                zIndex: "-10",

                borderRadius: "0px 0px 20px 20px",
              }}
            >
              <div className="absolute inset-0 flex" aria-hidden="true">
                <div className="bg-white w-1/2" />
                <div className="bg-white w-1/2" />
              </div>

              <div className="relative mx-auto  w-full">
                <nav
                  className=" px-10 bg-white pt-10 pb-3"
                  aria-labelledby="solutions-heading"
                >
                  <div>
                    <div className="sm:block">
                      <div className="border-b border-gray-200">
                        <nav
                          className="-mb-px flex space-x-8"
                          aria-label="Tabs"
                        >
                          {noOfCards > 5 ? (
                            <>
                              {tabs.map((tab) => (
                                <div
                                  onClick={() => handleTabChange(tab)}
                                  key={tab.name}
                                  className={classNames(
                                    tab.current
                                      ? "tab-active"
                                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm tab cursor-pointer"
                                  )}
                                  aria-current={
                                    tab.current ? "page" : undefined
                                  }
                                >
                                  {tab.name}
                                </div>
                              ))}
                            </>
                          ) : (
                            <div className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 tab-active whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm tab cursor-pointer">
                              Meters & Equipments
                            </div>
                          )}
                        </nav>
                      </div>
                    </div>
                  </div>
                </nav>

                <div className="my-3">
                  <div className="cards  flex  flex-wrap  mx-7">
                    <dl className="flex flex-wrap">
                      {noOfCards > 5 ? (
                        <>
                          {tabs.filter((tab) => tab.current)[0].tab ==
                            "meter" &&
                            meterOptions
                              .filter((x) => !x.isEquipment && !x.isUtility)
                              .map((option) => (
                                <MeterSelectorCard
                                  forcedGraphView={forcedGraphView}
                                  equipmentCounts={equipmentCounts}
                                  option={option}
                                  handleMeterChange={handleMeterChange}
                                  loadingSiteData={loadingSiteData}
                                />
                              ))}

                          {tabs.filter((tab) => tab.current)[0].tab ==
                            "equipment" &&
                            meterOptions
                              .filter((x) => x.isEquipment)
                              .map((option) =>
                                (equipmentCounts &&
                                  equipmentCounts[option.value + "Count"]) ||
                                isAGroup ? (
                                  <MeterSelectorCard
                                    forcedGraphView={forcedGraphView}
                                    equipmentCounts={equipmentCounts}
                                    option={option}
                                    handleMeterChange={handleMeterChange}
                                    loadingSiteData={loadingSiteData}
                                  />
                                ) : (
                                  <></>
                                )
                              )}

                          {tabs.filter((tab) => tab.current)[0].tab ==
                            "utility" &&
                            selectedSite.value == null &&
                            selectedSite.isAGroup &&
                            meterOptions
                              .filter((x) => x.isUtility)
                              .map((option) => (
                                <>
                                  <div
                                    onClick={() => handleMeterChange(option)}
                                    className="w-96 shadow shadow-lg mx-4 my-2 flex items-center border border-1"
                                  >
                                    <div className="h-full w-28 mr-2 flex items-center p-4">
                                      <img src={option.image} />
                                    </div>

                                    <div className="flex flex-col h-auto justify-center py-4 px-3">
                                      <div>
                                        <h1 className="m-0 font-bold text-xl">
                                          {option.label}
                                        </h1>
                                        <p className="m-0 text-sm text-gray-400">
                                          {option.description}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}
                        </>
                      ) : (
                        <>
                          {meterOptions
                            .filter((x) => !x.isEquipment && !x.isUtility)
                            .map((option) => (
                              <MeterSelectorCard
                                forcedGraphView={forcedGraphView}
                                equipmentCounts={equipmentCounts}
                                option={option}
                                handleMeterChange={handleMeterChange}
                                loadingSiteData={loadingSiteData}
                              />
                            ))}

                          <BuildingDetailsCard />

                          {meterOptions
                            .filter((x) => x.isEquipment)
                            .map((option) =>
                              (equipmentCounts &&
                                equipmentCounts[option.value + "Count"]) ||
                              isAGroup ? (
                                <MeterSelectorCard
                                  forcedGraphView={forcedGraphView}
                                  equipmentCounts={equipmentCounts}
                                  option={option}
                                  handleMeterChange={handleMeterChange}
                                  loadingSiteData={loadingSiteData}
                                />
                              ) : (
                                <></>
                              )
                            )}
                        </>
                      )}
                    </dl>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

const mapStateToProps = (state) => {
  return {
    loadingSiteData: state.buildings.loadingSiteData,
  };
};

const mapDispatchToprops = {
  handleMeterStateChange: handleMeterStateChange,
};

export default connect(mapStateToProps, mapDispatchToprops)(MeterPopOver);
