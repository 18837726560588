import useSelection from "antd/lib/table/hooks/useSelection";
import React, { useEffect, useState } from "react";
import { Chart, registerables } from "chart.js";
import { AiOutlineNodeIndex } from "react-icons/ai";
import { FaRegBuilding } from "react-icons/fa";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { BiCube } from "react-icons/bi";
import { classNames } from "../../helpers/classNames";
import { BsArrowReturnLeft } from "react-icons/bs";

Chart.register(...registerables);

const chartData = {
  labels: [
    "06:00",
    "06:15",
    "06:30",
    "06:45",
    "06:00",
    "06:15",
    "06:30",
    "06:45",
  ],
  datasets: [
    {
      label: "KWH",
      data: [43.5, 46.5, 38, 48, 50.5, 60.5, 38, 20],
      backgroundColor: "#0da4e7",
    },
  ],
};

function EquipmentsCard({
  meter,
  meterName,
  color,
  chartbgColor,
  selectedSite,
  equipmentCounts,
  points,
  loadingSiteData,
  loading,
}) {
  const [chartInstance, setChartInstance] = useState({});

  const EqchartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      chartAreaBorder: {
        borderWidth: 0,
      },
    },

    maintainAspectRatio: false,

    scales: {
      x: {
        ticks: {
          display: false,
        },
        beginAtZero: true,

        grid: {
          display: false,
          drawBorder: false,
        },
      },

      y: {
        ticks: {
          display: false,
        },

        beginAtZero: true,
        grid: {
          display: false,
          drawBorder: false,
        },
      },
    },

    layout: {
      padding: 0,
    },
  };

  const eqcardInfo = [
    // {
    //   icon: <FaRegBuilding color="#0EA4E7" size="23px" />,
    //   label: "Buildings",
    //   value: "",
    // },
    {
      icon: <BiCube color="#0EA4E7" size="23px" />,

      label: "Equipments",
      // value: "",
      value: equipmentCounts[`${meter + "Count"}`] || "",
    },

    {
      icon: <AiOutlineNodeIndex color="#0EA4E7" size="23px" />,
      label: "Points",
      // value: " ",
      value: points[`${meter + "Points"}`]
        ? points[`${meter + "Points"}`].aggregate.count
        : "",
    },
  ];

  const generateRandomGraph = () => {
    let dataForMeter = [];
    const data = new Array(5).fill("");

    if (Object.keys(chartInstance).length) {
      try {
        chartInstance.destroy();
      } catch (e) {
        console.log("Cannot clear Instance");
      }
    }

    const ctxDom = document.getElementById(`chart-${meter}`);

    if (!ctxDom) return;
    const ctx = ctxDom.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 450);
    gradient.addColorStop(0, chartbgColor);
    gradient.addColorStop(0.5, "rgba(255, 255, 255, 0.2)");
    gradient.addColorStop(1, "rgba(255, 0, 0, 0)");

    for (let i in data) {
      data[i] = Math.abs(Math.floor(Math.random() * (0 - 10 + 1)) + 0);
    }

    dataForMeter = {
      labels: ["12:00 am", "1:00 am", "2:00 am", "3:00 am", "4:00am"],
      datasets: [
        {
          label: `${meter}`,
          data: data,
          fillColor: color,
          fill: true,
          lineTension: 0.2,
          backgroundColor: gradient,
          borderColor: color,
          // pointBackgroundColor: color,
          // pointBorderColor: "#fff",
          // pointBorderWidth: 5,
          pointRadius: 0,
          pointHoverRadius: 5,
          borderWidth: 3,
          lineTension: 0.5,
          fill: true,
        },
      ],
    };

    const chart = new Chart(ctx, {
      type: "line",
      options: EqchartOptions,
      data: dataForMeter,
    });

    setChartInstance(chart);
  };

  useEffect(() => {
    if (!(loadingSiteData || loading)) {
      generateRandomGraph();
    }
  }, [loadingSiteData, loading]);

  if (loadingSiteData || loading) {
    return (
      <div
        className={
          "bg-white metercard equipmentcard text-left flex flex-col justify-between bg-gray-300"
        }
      >
        <div className="flex justify-between">
          <h1 className=" primary-text-color">
            <span className="mr-2 uppercase font-bold text-transparent bg-gray-300 animate-pulse ">
              {meterName}
            </span>
            <span className="rounded rounded-full badge text-xs text-transparent bg-gray-300 animate-pulse ">
              {equipmentCounts[`${meter}Count`]}
            </span>
          </h1>

          <p className="m-0 text-sm font-bold text-transparent bg-gray-300 animate-pulse ">
            Today
          </p>
        </div>

        <div className="chart -mb-14  overflow-hidden">
          <div className=" mt-5 flex-1 ">
            <div className="h-full text-transparent bg-gray-300 animate-pulse ">
              <canvas id={`chart-${meter}`}></canvas>
            </div>
          </div>
        </div>

        <div style={{ border: "1px solid #DBDFF1" }} className="mt-5" />

        <div className="flex  h-10 justify-between">
          {eqcardInfo.map((info) => (
            <div className="info  flex items-center">
              <div className="icon m-0 text-transparent bg-gray-300 animate-pulse "></div>
              <div>
                <h1 className=" value m-0 font-bold text-transparent bg-gray-300 animate-pulse ">
                  {info.value}
                </h1>
                <p className="label m-0 text-transparent bg-gray-300 animate-pulse  ">
                  {info.label}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <Link
      to={{
        pathname: `/dashboard/${meter}/${
          selectedSite.id ? selectedSite.id : ""
        }`,
      }}
    >
      <div
        className={
          "bg-white metercard equipmentcard text-left flex flex-col justify-between bg-gray-300"
        }
      >
        <div className="flex justify-between">
          <h1 className=" primary-text-color">
            <span className="mr-2 uppercase font-bold">{meterName}</span>
            <span
              style={{ backgroundColor: color }}
              className="rounded rounded-full badge text-white text-xs"
            >
              {equipmentCounts[`${meter}Count`]}
            </span>
          </h1>

          <p className="m-0 text-sm secondary-text-color font-bold">Today</p>
        </div>

        <div className="chart -mb-14  overflow-hidden">
          <div className=" mt-5 flex-1 ">
            <div className="h-full">
              <canvas id={`chart-${meter}`}></canvas>
            </div>
          </div>
        </div>

        <div style={{ border: "1px solid #DBDFF1" }} className="mt-5" />

        <div className="flex  h-10 justify-between">
          {eqcardInfo.map((info) => (
            <div className="info flex items-center  flex-1">
              <div className="icon m-0">{info.icon}</div>
              <div>
                <h1 className="primary-text-color value m-0 font-bold">
                  {info.value}
                </h1>
                <p className="label m-0 secondary-text-color ">{info.label}</p>
              </div>
            </div>
          ))}

          <div className="info  items-center  text-center flex-1">
            <div>
              <button
                type="button"
                className="inline-flex items-center px-3.5 py-2 border border-transparent text-xs leading-4 font-medium rounded-full shadow-sm text-white "
              >
                Details
              </button>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

const mapStateToProps = (state) => {
  return {
    allSites: state.buildings.allSites,
    selectedSite: state.buildings.selectedSite,
    equipmentCounts: state.buildings.equipmentCounts.count,
    points: state.buildings.equipmentCounts.points,
    loadingSiteData: state.buildings.loadingSiteData,
  };
};
export default connect(mapStateToProps)(EquipmentsCard);
