import React, { useEffect, useReducer, useRef, useState } from "react";
import { MdAdd } from "react-icons/md";
import { connect } from "react-redux";
import NewItemModal from "../NewItemModal";
import Lane from "./Lane";
import {
  addLane,
  addTask,
  deleteLane,
  deleteTask,
  editLane,
  setLanes,
  setTasks,
} from "../../../../redux/actions";
import {
  getAllViewsByUserIdAndViewType,
  getViewsByViewId,
  insertIntoView,
  updatePageLanes,
} from "../../../API/API";
import shortid from "shortid";
import moment from "moment";
import { notification } from "antd";

function BoardContainer({
  selectedPage,
  lanes,
  tasks,
  addLaneToReducer,
  deleteLane,
  editLane,
  addTask,
  deleteTask,
  setTasks,
  setLanes,
}) {
  let lanesLocal = useRef();

  const [newItemName, setNewItemName] = useState("");

  const [modalOpen, setModalOpen] = useState(false);

  const [currentlySelectedTask, setCurrentlySelectedTask] = useState({});

  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const [somethingChanged, setSomethingChanged] = useState(false);

  const [loadingView, setLoadingView] = useState("");

  const handleAddLane = async () => {
    setSomethingChanged(true);
    const newLane = {
      value: lanes[lanes.length - 1]?.value + 1 || 1,
      name: `Lane ${lanes.length}`,
      show: true,
    };
    addLaneToReducer(newLane);
  };

  const handleLaneDelete = async (lane) => {
    setSomethingChanged(true);
    deleteLane(lane);
  };

  const handleLaneNameChange = (e, lane) => {
    setSomethingChanged(true);
    editLane({ lane, newName: e.target.value });
  };

  const handleNewItemAdd = async (e, lane) => {
    if (!e) e = window.event;
    var keyCode = e.code || e.key;

    if (keyCode == "Enter") {
      const viewId = shortid.generate();
      const itemTitle = { type: "text", value: e.target.value, id: "title" };

      const userId = JSON.parse(localStorage.getItem("user")).sub;
      const res = await insertIntoView(
        viewId,
        null,
        moment().format("DD-MM-YYYY hh:mm a"),
        null,
        itemTitle,
        userId,
        selectedPage.pageId,
        null,
        lane,
        lanesLocal.current
      );

      console.log(res);
      if (res.data.data.insert_views.affected_rows) {
        addTask({
          id: viewId,
          laneId: lane.value,
          title: itemTitle,
        });
      } else {
        notification.error({ message: "Something Went wrong !!" });
      }
    }
  };

  const handleItemClicked = async (item) => {
    setLoadingView(item.viewId);
    const res = await getViewsByViewId(item.viewId);
    if (res?.data?.data?.views?.length) {
      setCurrentlySelectedTask(res?.data?.data?.views[0]);
      setModalOpen(true);
      setLoadingView("");
    }
  };

  // TODO: PROPERLY DEFINE THE HANDLE DROP, [CANNT ACCESS THE tasks STATE]
  const handleDrop = (task, lane, allTasks) => {
    console.log(localTasks);
    return;
    // const tasksCopy = JSON.parse(JSON.stringify(allTasks));
    // const objIndex = tasksCopy.findIndex((obj) => obj.name == task.name);
    // tasksCopy[objIndex].laneId = lane.id;
    // setTasks(tasksCopy);
  };

  const handleItemDelete = (item) => {
    deleteTask(item);
  };

  const handleItemMove = (task, lane) => {
    const tasksCopy = JSON.parse(JSON.stringify(tasks));
    const objIndex = tasksCopy.findIndex((x) => x.viewId == task.viewId);
    tasksCopy[objIndex].laneId = lane.value;
    setTasks(tasksCopy);
    // setTasks((prev) => {
    //   const obj = prev.findIndex((x) => x.id == task.id);
    //   prev[obj].laneId = lane.id;
    //   return prev;
    // });
    forceUpdate();
  };

  useEffect(() => {
    fetchAllLists();
  }, []);

  useEffect(() => {
    lanesLocal.current = lanes;
  }, [lanes]);

  useEffect(() => {
    return async () => {
      const res = await updatePageLanes(
        selectedPage.pageId,
        lanesLocal.current
      );
      console.log(res);
    };
  }, []);

  const fetchAllLists = async () => {
    const res = await getAllViewsByUserIdAndViewType(
      JSON.parse(localStorage.getItem("user")).sub,
      selectedPage.pageId
    );

    const allTasks = res.data.data.views.map((v) => ({
      ...v,
      laneId: v.status ? v.status.value : 1,
    }));

    console.log("ALL TASKS", allTasks);

    res?.data?.data?.views[0]?.lanes.length
      ? setLanes(res?.data?.data?.views[0]?.lanes)
      : setLanes([
          {
            value: 1,
            name: "No Tags",
            show: false,
          },
        ]);

    setTasks(allTasks);
  };

  return (
    <div className="lanes flex item-center overflow-scroll">
      <NewItemModal
        lists={tasks}
        open={modalOpen}
        setOpen={setModalOpen}
        currentlySelectedList={currentlySelectedTask}
        setLists={() => console.log("sdfsdf")}
        selectedPage={selectedPage}
        fetchAllLists={fetchAllLists}
      />

      {lanes.map((lane) => (
        <Lane
          loadingView={loadingView}
          handleItemMove={handleItemMove}
          handleItemClicked={handleItemClicked}
          handleLaneNameChange={handleLaneNameChange}
          handleLaneDelete={handleLaneDelete}
          handleDrop={handleDrop}
          lane={lane}
          tasks={tasks}
          lanes={lanes}
          handleNewItemAdd={handleNewItemAdd}
          handleItemDelete={handleItemDelete}
        />
      ))}
      <div
        onClick={handleAddLane}
        className="add-icon border p-2 h-8 rounded flex items-center cursor-pointer"
      >
        <MdAdd />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    lanes: state.notion.lanes,
    tasks: state.notion.tasks,
  };
};

const mapDispatchToProps = {
  addLaneToReducer: addLane,
  deleteLane: deleteLane,
  editLane: editLane,
  setLanes: setLanes,

  addTask: addTask,
  deleteTask: deleteTask,
  setTasks: setTasks,
};

export default connect(mapStateToProps, mapDispatchToProps)(BoardContainer);
