import { Switch } from "@headlessui/react";
import React, { useState } from "react";
import { connect } from "react-redux";
import { classNames } from "../../../helpers/classNames";
import { setCostActive } from "../../../redux/actions";
import "./Toggle.css";

function Toggle({
  totalWidth,
  leftSideWidth,
  rightSideWidth,
  isOnLeft,
  toggleBetween,
}) {
  return (
    <div
      style={{ width: totalWidth }}
      className="flex toggle relative cursor-pointer"
    >
      <div
        style={{ width: isOnLeft ? leftSideWidth : rightSideWidth }}
        className={classNames(
          "absolute slider transition transition-all",
          isOnLeft ? "left" : "right"
        )}
      ></div>
      <div
        style={{ width: leftSideWidth }}
        className={classNames(
          "p-2 text-center flex items-center justify-center",
          isOnLeft ? "primary-text-color" : ""
        )}
      >
        {toggleBetween[0]}
      </div>

      <div
        style={{ width: rightSideWidth }}
        className={classNames(
          "p-2 text-center flex items-center justify-center ",
          !isOnLeft ? "primary-text-color" : ""
        )}
      >
        {toggleBetween[1]}
      </div>
    </div>
  );
}

// const mapStateToProps = (state) => {
//   return {
//     isOnLeft: state.buildings.isOnLeft,
//   };
// };

const mapDispatchToProps = {
  setCostActive: setCostActive,
};

export default connect(null, mapDispatchToProps)(Toggle);
