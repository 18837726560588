export const getRandomColor = () => {
  let pallet = [
    "#0074D9",
    "#FF4136",
    "#2ECC40",
    "#FF851B",
    "#7FDBFF",
    "#B10DC9",
    "#FFDC00",
    "#001f3f",
    "#39CCCC",
    "#01FF70",
    "#85144b",
    "#F012BE",
    "#3D9970",
    "#111111",
    "#AAAAAA",
  ];
  let colors = [];

  return pallet[Math.floor(Math.random() * (pallet.length - 4) + 4)];
};
