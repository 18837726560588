import { Dropdown, Menu, Spin } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoMdTrash } from "react-icons/io";
import { classNames } from "../../../../helpers/classNames";

function ItemCard({
  task,
  handleItemClicked,
  handleItemDelete,
  lanes,
  handleItemMove,
  loadingView,
}) {
  // const [{ isDragging }, drag] = useDrag(() => ({
  //   type: "task",
  //   item: task,
  //   collect: (monitor) => ({
  //     isDragging: !!monitor.isDragging(),
  //   }),
  // }));

  const [taskMenuOpen, setTaskMenuOpen] = useState(false);

  const CustomMenu = () => (
    <Menu triggerSubMenuAction="click" className="m-0 shadow">
      <Menu.Item>
        <div
          className="flex items-center"
          onClick={() => handleItemDelete(task)}
        >
          <span>
            <IoMdTrash color="red" />
          </span>
          <span className="ml-3">Delete</span>
        </div>
      </Menu.Item>

      <SubMenu title="Move To">
        {lanes.map((lane) => (
          <Menu.Item key={lane.value} className="text-xs text-center">
            <div
              onClick={() => {
                handleItemMove(task, lane);
                setTaskMenuOpen(!taskMenuOpen);
              }}
              className="flex items-center"
            >
              {lane.name}
            </div>
          </Menu.Item>
        ))}
      </SubMenu>
    </Menu>
  );

  return (
    <li
      // ref={drag}
      key={task.id + task.key}
      className={classNames(
        "border my-2 task p-2  fade-in-fwd flex items-center justify-between cursor-move"
      )}
    >
      <span
        onClick={() => handleItemClicked(task)}
        className="flex-1 cursor-pointer"
      >
        {console.log(task)}
        {task.title.value}
      </span>

      <span
        // onClick={() => handleItemDelete(task)}
        className="cursor-pointer taskoptions"
      >
        <Dropdown
          overlayStyle={{ position: "fixed" }}
          trigger={["click"]}
          visible={taskMenuOpen}
          placement="bottomRight"
          overlay={<CustomMenu />}
        >
          <div
            onClick={() => setTaskMenuOpen(!taskMenuOpen)}
            className="ant-dropdown-link editable py-2 flex justify-center items-center w-5 h-5 shadow shadow-xs"
          >
            <HiOutlineDotsHorizontal />
          </div>
        </Dropdown>
      </span>

      {loadingView == task.viewId && (
        <span className="ml-1 -mb-1">
          <Spin size="small" />
        </span>
      )}
    </li>
  );
}

export default ItemCard;
