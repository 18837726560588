import React, { Fragment, useEffect } from "react";
import Header from "../../Resueables/Header/Header";
import NeumorphBox from "../../Resueables/NeumorphBox/NeumorphBox";

import "../Login/Login.css";

import { Link } from "react-router-dom";

function Signup() {
  return (
    <Fragment>
      <Header forLogin={true} />
      <div className="flex w-3/4 mx-auto px-16 mt-12">
        <div className="flex-1 login-left relative">
          <form>
            <NeumorphBox
              // onChange={}
              isInput={true}
              icon="http://novo-host.surge.sh/assets/images/bxs-envelope%202.png"
              placeholder="Your Name"
            />
            <div className="mt-7" />
            <NeumorphBox
              // onChange={}
              isInput={true}
              icon="http://novo-host.surge.sh/assets/images/bxs-lock%201.png"
              placeholder="Your Email"
            />

            <div className="mt-7" />
            <NeumorphBox
              // onChange={}
              isInput={true}
              icon="http://novo-host.surge.sh/assets/images/bxs-envelope%202.png"
              placeholder="Organization"
            />

            <div className="mt-7" />
            <NeumorphBox
              // onChange={}
              isInput={true}
              icon="http://novo-host.surge.sh/assets/images/bxs-envelope%202.png"
              placeholder="Your Password"
              type="password"
            />
            <div className="mt-7" />
            <NeumorphBox
              // onChange={}
              isInput={true}
              icon="http://novo-host.surge.sh/assets/images/bxs-envelope%202.png"
              placeholder="Confirm Password"
              type="password"
            />

            <div className="mt-7" />
            <div className="mt-7" />

            <div className="mt-7" />
            <div className="text-center py-5 rounded-lg text-white border-blue login-btn font-bold">
              Register
            </div>

            <Link
              to="/"
              className="text-center primary-text-color font-bold mt-7 hover:none block"
            >
              Already have an account ? Login
            </Link>
          </form>
        </div>
        <div className="mx-10" />
        <div className="flex-1 relative login-right ml-auto">
          <div onClick={() => loginHandler("google-oauth2")}>
            <NeumorphBox
              // onChange={}
              icon="http://novo-host.surge.sh/assets/images/brands-and-logotypes%203.png"
              title="Register with Google"
              extraBoxClasses="py-7 cursor-pointer"
            />
          </div>
          <div className="mt-7" />
          <NeumorphBox
            // onChange={}
            icon="http://novo-host.surge.sh/assets/images/logo%203.png"
            title="Register with Github"
            extraBoxClasses="py-7 cursor-pointer"
          />

          <div className="mt-7" />
          <NeumorphBox
            // onChange={}
            icon="http://novo-host.surge.sh/assets/images/linkedin%203.png"
            title="Register with Linkedin"
            extraBoxClasses="py-7 cursor-pointer"
          />
          <div className="mt-7" />
          <NeumorphBox
            // onChange={}
            icon="http://novo-host.surge.sh/assets/images/twitter%203.png"
            title="Register with Twitter"
            extraBoxClasses="py-7 cursor-pointer"
          />
        </div>
      </div>
    </Fragment>
  );
}

export default Signup;
