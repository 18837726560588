import React, { useState, useEffect } from "react";
import { IoMdWallet } from "react-icons/io";
import { BsFillLightningFill } from "react-icons/bs";
import { transfromBills } from "../transformBills";
import { Chart } from "chart.js";
import moment from "moment";
import { connect } from "react-redux";
import {
  handleMeterStateChange,
  handleTimeStateChange,
  setCostActive,
} from "../../../redux/actions";
import Toggle from "../../../Resueables/UniversalSearch/Toggle/Toggle";

function KwhUtility({
  isCostActive,
  universalSearchState,
  handleTimeStateChange,
  setCostActive,
  handleMeterStateChange,
}) {
  const [loading, setLoading] = useState(true);
  const [bills, setBills] = useState([]);
  const [offPeakElec, setOffPeakElec] = useState("");
  const [onPeakElec, setOnPeakElec] = useState("");
  const [totalElec, setTotalElec] = useState("");

  const [offPeakCons, setOffPeakCons] = useState("");
  const [onPeakCons, setOnPeakCons] = useState("");
  const [totalCons, setTotalCons] = useState("");

  const [chartInst, setChartInst] = useState(null);

  useEffect(() => {
    handleTimeStateChange({
      startDate: moment().format("YYYY-01-01"),
      endDate: moment().format("YYYY-MM-DD"),
      label: moment().format("YYYY"),
    });
  }, []);

  useEffect(() => {
    setLoading(true);

    if (
      universalSearchState.time.startDate == universalSearchState.time.endDate
    )
      return;
    fetch("https://utilityapi.com/api/v2/bills?meters=504984", {
      headers: {
        Authorization: "Bearer 5223317c948449b79d695a1c4384e67e",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        setBills(response.bills);

        const transformedBills = transfromBills(
          universalSearchState.time.startDate,
          universalSearchState.time.endDate,
          response.bills
        );

        let offPeakElecSum = 0,
          onPeakElecSum = 0,
          totalElecSum = 0,
          offPeakConSum = 0,
          onPeakConSum = 0,
          totalCons = 0;

        transformedBills.forEach((bill) => {
          if (bill.tou) {
            offPeakElecSum += bill.tou[1].cost;
            offPeakConSum += bill.tou[1].volume;

            onPeakElecSum += bill.tou[0].cost;
            onPeakConSum += bill.tou[0].volume;

            totalCons += bill.base.bill_total_volume;
          }
        });
        totalElecSum = offPeakElecSum + onPeakElecSum;

        setOffPeakElec(Math.round(offPeakElecSum).toLocaleString());
        setOffPeakCons(Math.round(offPeakConSum).toLocaleString());
        setOnPeakElec(Math.round(onPeakElecSum).toLocaleString());
        setOnPeakCons(Math.round(onPeakConSum).toLocaleString());
        setTotalCons(Math.round(totalCons).toLocaleString());
        setTotalElec(Math.round(totalElecSum).toLocaleString());

        plotTheChart(transformedBills);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("ERROR", err);
      });
  }, [universalSearchState.time]);

  const plotTheChart = (data) => {
    if (chartInst && Object.keys(chartInst).length) {
      chartInst.destroy();
    }

    data = data.reverse();

    if (
      data.length !== 12 &&
      moment().format("YYYY") == universalSearchState.time.label
    ) {
      for (let i = 0; i <= 12 - data.length; i++) {
        data.push(moment().month(data.length).format("MMMM"));
      }
    }

    console.log(data);
    let chartStatus = Chart.getChart("kwh-utility-chart");
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }

    const ctx = document.getElementById("kwh-utility-chart").getContext("2d");

    let datasets = [
      {
        label: "On Peak",
        data: data.map((x) => {
          if (x.tou && x.tou.length) {
            if (isCostActive) {
              return x.tou[1].cost || 5000;
            } else {
              return x.tou[1].volume || 5000;
            }
          } else {
            if (isCostActive) {
              return x.line_items ? x.line_items[7].cost : 5000;
            } else {
              return x.line_items ? x.line_items[7].volume : 5000;
            }
          }
        }),
        //
        backgroundColor: data.map((x) =>
          typeof x == "string" ? "transparent" : "#00aeef"
        ),
        borderRadius: 10,
        borderColor: data.map((x) =>
          typeof x == "string" ? "#000" : "transparent"
        ),
        borderWidth: data.map((x) => (typeof x == "string" ? 1 : 0)),
      },
      {
        label: "Off Peak",
        data: data.map((x) => {
          if (x.tou && x.tou.length) {
            if (isCostActive) {
              return x.tou[0].cost || 5000;
            } else {
              return x.tou[0].volume || 5000;
            }
          } else {
            if (isCostActive) {
              return x.line_items ? x.line_items[8].cost : 5000;
            } else {
              return x.line_items ? x.line_items[8].cost : 5000;
            }
          }
        }),

        borderColor: data.map((x) =>
          typeof x == "string" ? "#000" : "transparent"
        ),
        borderWidth: data.map((x) => (typeof x == "string" ? 1 : 0)),
        backgroundColor: data.map((x) =>
          typeof x == "string" ? "transparent" : "#ffba69"
        ),
        borderRadius: 10,
      },
    ];

    datasets[0].back;

    const chartDataLocal = {
      labels: data.map((x) =>
        x.base ? moment(x.base.bill_statement_date).format("MMMM") : x
      ),
      datasets: datasets,
    };

    var myChart = new Chart(ctx, {
      type: "bar",
      data: chartDataLocal,
      options: {
        layout: {
          padding: {
            top: 10,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },

        maintainAspectRatio: false,
        responsive: true,
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
            },
            ticks: {
              font: {
                style: "normal",
                weight: "600",
                size: "12px",
                color: "rgba(189, 189, 189, 0.6)",
              },
            },
          },
          y: {
            stacked: true,
            position: "left",
            grid: {
              display: false,
            },
            ticks: {
              autoSkip: false,
              precision: 1,
              beginAtZero: true,
              callback: (label) => {
                return isCostActive
                  ? "$" + formatCash(label)
                  : formatCash(label) + "kWh";
              },
              font: {
                style: "normal",
                weight: "600",
                size: "12px",
                color: "rgba(189, 189, 189, 0.6)",
              },
            },
          },
        },

        elements: {
          line: {
            fill: false,
          },
        },
      },
    });

    setChartInst(myChart);

    // const chart = new Chart(ctx, {});
  };

  useEffect(() => {
    plotTheChart(
      transfromBills(
        universalSearchState.time.startDate,
        universalSearchState.time.endDate,
        bills
      )
    );
  }, [isCostActive]);

  return (
    <>
      <div className="utility-cards flex items-center justify-between">
        {loading ? (
          <>
            <div className="utility-card text-transparent bg-transparent bg-gray-300 animate-pulse flex-1 flex items-center text-transparent">
              <div className="icon flex justify-center w-1/4">
                <div className="icon-wrapper flex items-center justify-center text-transparent bg-gray-300 animate-pulse">
                  {isCostActive ? (
                    <IoMdWallet color="#00AEEF" size="28px"></IoMdWallet>
                  ) : (
                    <BsFillLightningFill color="#00AEEF" size="28px" />
                  )}
                </div>
              </div>

              <div className="w-4/5">
                <h1 className="font-bold m-0 text-transparent bg-grey-900 "></h1>
                <p className="font-thin m-0">
                  On-Peak Electricty {isCostActive ? "Cost" : "Consumption"}
                </p>
              </div>
            </div>

            <div className="utility-card text-transparent bg-transparent bg-gray-300 animate-pulse flex-1 flex items-center text-transparent">
              <div className="icon flex justify-center w-1/4">
                <div className="icon-wrapper flex items-center justify-center text-transparent bg-gray-300 animate-pulse">
                  {isCostActive ? (
                    <IoMdWallet color="#00AEEF" size="28px"></IoMdWallet>
                  ) : (
                    <BsFillLightningFill color="#00AEEF" size="28px" />
                  )}
                </div>
              </div>

              <div className="w-4/5">
                <h1 className="font-bold m-0 text-transparent bg-grey-900 "></h1>
                <p className="font-thin m-0">
                  On-Peak Electricty {isCostActive ? "Cost" : "Consumption"}
                </p>
              </div>
            </div>

            <div className="utility-card text-transparent bg-transparent bg-gray-300 animate-pulse flex-1 flex items-center text-transparent">
              <div className="icon flex justify-center w-1/4">
                <div className="icon-wrapper flex items-center justify-center text-transparent bg-gray-300 animate-pulse">
                  {isCostActive ? (
                    <IoMdWallet color="#00AEEF" size="28px"></IoMdWallet>
                  ) : (
                    <BsFillLightningFill color="#00AEEF" size="28px" />
                  )}
                </div>
              </div>

              <div className="w-4/5">
                <h1 className="font-bold m-0 text-transparent bg-grey-900 "></h1>
                <p className="font-thin m-0">
                  On-Peak Electricty {isCostActive ? "Cost" : "Consumption"}
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="utility-card flex-1 flex items-center">
              <div className="icon flex justify-center w-1/4">
                <div className="icon-wrapper flex items-center justify-center">
                  {isCostActive ? (
                    <IoMdWallet color="#00AEEF" size="28px"></IoMdWallet>
                  ) : (
                    <BsFillLightningFill color="#00AEEF" size="28px" />
                  )}
                </div>
              </div>

              <div className="w-4/5">
                <h1 className="font-bold m-0">
                  {isCostActive ? "$" : null}{" "}
                  {isCostActive ? onPeakElec : onPeakCons}
                  {!isCostActive ? (
                    <small className="text-sm">kWh</small>
                  ) : null}
                </h1>
                <p className="font-thin m-0">
                  On-Peak Electricty {isCostActive ? "Cost" : "Consumption"}
                </p>
              </div>
            </div>

            <div className="utility-card flex-1  flex items-center">
              <div className="icon flex justify-center w-1/4">
                <div className="icon-wrapper flex items-center justify-center">
                  {isCostActive ? (
                    <IoMdWallet color="#ffba69" size="28px"></IoMdWallet>
                  ) : (
                    <BsFillLightningFill color="#ffba69" size="28px" />
                  )}
                </div>
              </div>

              <div className="w-4/5">
                <h1 className="font-bold m-0">
                  {" "}
                  {isCostActive ? "$" : null}{" "}
                  {isCostActive ? offPeakElec : offPeakCons}
                  {!isCostActive ? (
                    <small className="text-sm">kWh</small>
                  ) : null}
                </h1>
                <p className="font-thin m-0">
                  Off-Peak Electricty {isCostActive ? "Cost" : "Consumption"}
                </p>
              </div>
            </div>

            <div className="utility-card flex-1  flex items-center">
              <div className="icon flex justify-center w-1/4">
                <div className="icon-wrapper flex items-center justify-center">
                  {isCostActive ? (
                    <IoMdWallet color="#00AEEF" size="28px"></IoMdWallet>
                  ) : (
                    <BsFillLightningFill color="#00AEEF" size="28px" />
                  )}
                </div>
              </div>

              <div className="w-4/5">
                <h1 className="font-bold m-0">
                  {isCostActive ? "$" : null}
                  {isCostActive ? totalElec : totalCons.toLocaleString()}
                  {!isCostActive ? (
                    <small className="text-sm">kWh</small>
                  ) : null}
                </h1>
                <p className="font-thin m-0">
                  Total Electricty {isCostActive ? "Cost" : "Consumption"}
                </p>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="mt-10" />

      <div className="utility-chart-wrapper bg-white">
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-bold w-2/4">
            {isCostActive ? " Cost" : "Consumption"} Breakdown{" "}
            <small className="font-thin text-sm ml-4">
              {universalSearchState.time.label}{" "}
            </small>
          </h1>

          <div
            style={{ zIndex: "2" }}
            onClick={() => setCostActive(!isCostActive)}
          >
            <Toggle
              totalWidth="200px"
              leftSideWidth="35%"
              rightSideWidth="65%"
              toggleBetween={["COST", "CONSUMPTION"]}
            />
          </div>

          <div className="flex">
            <div className="ml-4">
              <div className="flex items-center">
                <div className="w-3 h-3 rounded rounded-full onPeakBg primary-bg-color m-0"></div>
                <p className="ml-2 my-0">On Peak</p>
              </div>
            </div>

            <div className="ml-4">
              <div className="flex items-center">
                <div className="w-3 h-3  rounded rounded-full offPeakBg m-0"></div>
                <p className="ml-2 my-0">Off Peak</p>
              </div>
            </div>
          </div>
        </div>
        <div className="my-3" style={{ border: "1.06229px solid #F2F2F2" }} />
        <div style={{ height: "383.42px", width: "100%" }}>
          <canvas id="kwh-utility-chart"></canvas>
        </div>
      </div>

      <div className="mt-20" />
    </>
  );
}

const formatCash = (n) => {
  if (n < 1e3) return n;
  if (n >= 1e3) return +(n / 1e3).toFixed(1) + "K";
};

const mapStateToProps = (state) => {
  return {
    isCostActive: state.buildings.isCostActive,
    universalSearchState: state.search.universalSearchState,
  };
};

const mapDispatchToProps = {
  handleTimeStateChange: handleTimeStateChange,
  setCostActive: setCostActive,
  handleMeterStateChange: handleMeterStateChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(KwhUtility);
