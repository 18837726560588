import React, { useEffect, useState } from "react";
import {
  fetchEquipmentDetails,
  fetchViewBySiteAndEquipment,
  getEquipmentCount,
} from "../API/API";
import { Table } from "antd";
import { useParams } from "react-router-dom";

function EquipmentDetailsModal({ selectedSite, modalOpen }) {
  const params = useParams();

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  useEffect(() => {
    getEquipmentDetails();
  }, [modalOpen]);

  const getEquipmentDetails = async () => {
    const res = await fetchViewBySiteAndEquipment(params.meter, {
      id: selectedSite.id || selectedSite.value,
    });

    console.log(res);
    if (res?.data?.data?.views.length) {
      const views = res.data.data.views;
      const columnsCopy = [];
      const rowsCopy = [];

      // MAKE COLS

      views.forEach((view) => {
        Object.values(view).forEach((obj) => {
          if (columnsCopy.findIndex((x) => x.title == obj?.name) == -1) {
            if (obj && !obj.length) {
              columnsCopy.push({
                title: obj.name,
                key: obj.id,
                dataIndex: obj.id,
              });
            }
          }
        });

        if (view.properties) {
          view?.properties.forEach((prop) => {
            if (columnsCopy.findIndex((x) => x.title == prop?.name) == -1) {
              columnsCopy.push({
                title: prop?.name,
                key: prop?.name.split(" ").join(""),
                dataIndex: prop?.name.split(" ").join(""),
              });
            }
          });
        }
      });

      setColumns(columnsCopy);

      //MAKE ROWS

      let singleRow = {};

      for (let i in views) {
        singleRow = {};
        singleRow["title"] = views[i]?.title?.value;
        singleRow["status"] = views[i]?.status?.value;
        singleRow["date"] = views[i]?.date?.value;

        if (views[i].properties) {
          for (let j in views[i].properties) {
            if (!singleRow[views[i].properties[j].name.split(" ").join("")]) {
              singleRow[views[i].properties[j].name.split(" ").join("")] =
                views[i]?.properties[j]?.value;
            }
          }
        }
        rowsCopy.push(singleRow);
      }

      console.log(rowsCopy);
      setRows(rowsCopy);
    }
  };
  return <Table scroll={{ x: "100%" }} columns={columns} dataSource={rows} />;
}

export default EquipmentDetailsModal;
