import { SET_ALL_UTILITY_METERS } from "../actionTypes";

const initialState = {
  utilitiyMeters: [],
};

export default function utilityReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ALL_UTILITY_METERS:
      console.log("STATE FROM REDUCER", action.payload);
      return {
        ...state,
        utilitiyMeters: action.payload,
      };

    default:
      return state;
  }
}
