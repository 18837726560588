import { createStore, combineReducers } from "redux";
import searchReducer from "./reducers/search.reducers";
import buildingReducer from "./reducers/buildings.reducers";
import { applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import utilityReducer from "./reducers/utilities.reducers";
import notionCloneReducer from "./reducers/notionclone.reducers";

const rootReducer = combineReducers({
  search: searchReducer,
  buildings: buildingReducer,
  utilities: utilityReducer,
  notion: notionCloneReducer,
});

export default createStore(rootReducer, composeWithDevTools());
