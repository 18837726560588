export const getAllEquipCounts = (nodes) => {
  let vavCount = 0,
    fcuCount = 0,
    ahuCount = 0,
    boilerCount = 0,
    kwhCount = 0,
    steamCount = 0,
    chillerCount = 0;

  let vav = [],
    fcu = [],
    ahu = [],
    boiler = [],
    kwh = [],
    steam = [],
    chiller = [];

  // let vavPoints = [],
  //   fcuPoints = [],
  //   ahuPoints = [],
  //   boilerPoints = [],
  //   kwhPoints = [],
  //   steamPoints = [],
  //   chiller = [];

  for (let i in nodes) {
    if (nodes[i].source.elecMeter) {
      kwhCount += 1;
      kwh.push(nodes[i]);
      // kwhPoints.push(nodes[i].points);
    }
    if (nodes[i].source.steamMeter) {
      steamCount += 1;
      steam.push(nodes[i]);
      // steamPoints.push(nodes[i].points);
    }
    if (nodes[i].source.vav) {
      vavCount += 1;
      vav.push(nodes[i]);
      // vavPoints.push(nodes[i].points);
    }
    if (nodes[i].source.ahu) {
      ahuCount += 1;
      ahu.push(nodes[i]);
      // ahuPoints.push(nodes[i].points);
    }
    if (nodes[i].source.fcu) {
      fcuCount += 1;
      fcu.push(nodes[i]);
      // fcuPoints.push(nodes[i].points);
    }
    if (nodes[i].source.boilerPlant) {
      boilerCount += 1;
      boiler.push(nodes[i]);
      // boilerPoints.push(nodes[i].points);
    }
    if (nodes[i].source.chillerPlant) {
      chillerCount += 1;
      chiller.push(nodes[i]);
      // heatPoints.push(nodes[i].points);
    }
  }

  return {
    count: {
      vavCount,
      fcuCount,
      ahuCount,
      boilerCount,
      kwhCount,
      steamCount,
      chillerCount,
    },
    equips: {
      vav,
      fcu,
      ahu,
      boiler,
      kwh,
      steam,
      chiller,
    },
    // points: {
    //   vavPoints,
    //   ahuPoints,
    //   fcuPoints,
    //   boilerPoints,
    //   kwhPoints,
    //   steamPoints,
    //   heatPoints,
    // },
  };
};

export const getAllEquipCountsAndOccupyStatus = (nodes) => {
  // let vavCount = 0,
  //   vavOccCount = 0,
  //   fcuCount = 0,
  //   fcuOccCount = 0,
  //   ahuCount = 0,
  //   ahuOccCount = 0,
  //   boilerCount = 0,
  //   boilerOccCount = 0,
  //   kwhCount = 0,
  //   kwhOccCount = 0,
  //   steamCount = 0,
  //   steamOccCount = 0,
  //   heatCount = 0,
  //   heatOccCount = 0;

  let vav = { count: 0, occCount: 0 },
    fcu = { count: 0, occCount: 0 },
    ahu = { count: 0, occCount: 0 },
    heat = { count: 0, occCount: 0 },
    steam = { count: 0, occCount: 0 },
    kwh = { count: 0, occCount: 0 },
    boiler = { count: 0, occCount: 0 };

  for (let i in nodes) {
    if (nodes[i].source.elecMeter) {
      kwh.count += 1;
      if (
        nodes[i].points[0] &&
        nodes[i].points[0].source.curVal == "occupied"
      ) {
        kwh.occCount++;
      }
    }
    if (nodes[i].source.steamMeter) {
      steam.count += 1;
      if (nodes[i].points[0] && nodes[i].points[0].source.occ) {
        steam.occCount++;
      }
    }
    if (nodes[i].source.vav) {
      vav.count += 1;
      if (nodes[i].points[0] && nodes[i].points[0].source.occ) {
        vav.occCount++;
      }
    }
    if (nodes[i].source.ahu) {
      ahu.count += 1;
      if (nodes[i].points[0] && nodes[i].points[0].source.occ) {
        ahu.occCount++;
      }
    }
    if (nodes[i].source.fcu) {
      fcu.count += 1;
      if (nodes[i].points[0] && nodes[i].points[0].source.occ) {
        fcu.occCount++;
      }
    }
    if (nodes[i].source.boilerPlant) {
      boiler.count += 1;

      if (nodes[i].points[0] && nodes[i].points[0].source.occ) {
        boiler.occCount++;
      }
    }
    if (nodes[i].source.chillerPlant) {
      heat.count += 1;
      if (nodes[i].points[0] && nodes[i].points[0].source.occ) {
        heat.occCount++;
      }
    }
  }

  return {
    vav,
    fcu,
    ahu,
    heat,
    steam,
    kwh,
    boiler,
  };
};
