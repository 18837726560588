import { Dropdown, Menu } from "antd";
import MenuItem from "antd/lib/menu/MenuItem";
import SubMenu from "antd/lib/menu/SubMenu";
import React, { useState } from "react";
import { IoMdTrash } from "react-icons/io";
import { propertyTypes } from "../configs";
import InputRenderer from "../InputRenderer";

function TemplatesEquipments({ properties, setProperties }) {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownNumber, setDropdownNumber] = useState(null);

  const addNewProperty = () => {
    const propertiesCopy = JSON.parse(JSON.stringify(properties || []));

    propertiesCopy.push({
      name: `Property ${propertiesCopy.length + 1}`,
      typeLabel: "Text",
      type: "text",
      value: "",
    });

    setProperties(propertiesCopy);
  };

  const handleOnPropertyTypeChange = (type, index) => {
    const propertiesCopy = JSON.parse(JSON.stringify(properties || []));

    propertiesCopy[index].type = type.type;
    propertiesCopy[index].typeLabel = type.name;
    propertiesCopy[index].icon = type.icon;

    setProperties(propertiesCopy);
  };

  const handleOnInputChange = (name, value, property, index) => {
    console.log(name, value, property, index);
    const propertiesCopy = JSON.parse(JSON.stringify(properties || []));
    if (property == "value") {
      propertiesCopy[index]["value"] = value;
    } else {
      propertiesCopy[index]["name"] = value;
    }
    setProperties(propertiesCopy);
  };

  const removeProperty = (index) => {
    const propertiesCopy = JSON.parse(JSON.stringify(properties || []));
    propertiesCopy.splice(index, 1);
    setProperties(propertiesCopy);
  };

  const CustomMenu = ({ property, index }) => (
    <Menu triggerSubMenuAction="click" className="m-0 shadow">
      <Menu.Item className="property-name-input-container">
        <input
          type="text"
          placeholder="Property Name"
          className="p-1 property-name-input"
          onChange={(e) =>
            handleOnInputChange(property.name, e.target.value, property, index)
          }
          name="property-name"
          value={property.name}
          autoFocus
        />
      </Menu.Item>
      <Menu.ItemGroup title="PROPERTY TYPE" className="text-xs type-selector">
        <SubMenu title={property.typeLabel}>
          {propertyTypes.map((type) => (
            <Menu.Item
              style={{
                paddingLeft: "5px",
                display: "flex",
              }}
              className="text-xs text-left "
              key={type.name}
              onClick={() => {
                setDropdownNumber(null);
                handleOnPropertyTypeChange(type, index);
              }}
            >
              <div className="flex py-1 items-center">
                <div className="text-lg">{type.icon}</div>
                <div className="ml-2">{type.name}</div>
              </div>
            </Menu.Item>
          ))}
        </SubMenu>

        {!property.noDelete && (
          <MenuItem className="deleteMenu">
            <div
              onClick={() => removeProperty(index)}
              className="flex items-center"
            >
              <span>
                <IoMdTrash />
              </span>
              <span className="ml-2">Delete Property</span>
            </div>
          </MenuItem>
        )}
      </Menu.ItemGroup>
    </Menu>
  );

  return (
    <>
      {properties?.length
        ? properties?.map((property, i) => (
            <li className="property my-2 flex items-center transition-all">
              <div
                className="editable  text-left"
                style={{
                  padding: "3px",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <Dropdown
                  overlayStyle={{ position: "fixed" }}
                  trigger={["click"]}
                  visible={dropdownVisible && dropdownNumber == i}
                  onVisibleChange={(x) => {
                    setDropdownNumber(i);
                    setDropdownVisible(x);
                  }}
                  overlay={<CustomMenu property={property} index={i} />}
                >
                  <div className="ant-dropdown-link editable py-2 flex items-center text-sm text-gray-400 ">
                    <div>
                      {propertyTypes.filter((x) => x.type == property.type)[0]
                        ?.icon || propertyTypes[0].icon}
                    </div>
                    <div className="ml-1"> {property.name}</div>
                  </div>
                </Dropdown>
              </div>
              <div className="editable">
                <InputRenderer
                  propertyIndex={i}
                  handleOnInputChange={handleOnInputChange}
                  type={property.type}
                  value={property.value}
                  options={property.options}
                />
              </div>
            </li>
          ))
        : null}
      <button
        className="p-1 border rounded rounded-lg px-3 mt-3 text-xs ml-2"
        type="button"
        onClick={addNewProperty}
      >
        + New Property
      </button>
    </>
  );
}

export default TemplatesEquipments;
