import React, { useEffect, useState } from "react";
import { Collapse } from "antd";
import "./notionux.css";
import { getAllPagesByUserId } from "../API/API";
import shortid from "shortid";
import { savePage } from "../API/API";
import { classNames } from "../../helpers/classNames";
import Header from "../../Resueables/Header/Header";
import UniversalSearch from "../../Resueables/UniversalSearch";
import Databases from "./Databases";
import Templates from "./Templates";
import NewTemplates from "./Layouts/NewTemplates";

const { Panel } = Collapse;

function NotionMainPage() {
  const [selectedDB, setSelectedDB] = useState(null);
  const [selectedPage, setSelectedPage] = useState({});
  const [pageName, setPageName] = useState("");
  const [pages, setPages] = useState([]);
  const userid = JSON.parse(localStorage.getItem("user")).sub;

  const [creatingNewTemplate, setCreatingNewTemplate] = useState(null);

  const [showTimePanel, setShowTimePanel] = useState(false);

  const [showTemplates, setShowTemplates] = useState(false);

  useEffect(() => {
    fetchAllPages();
  }, []);

  const fetchAllPages = async () => {
    const res = await getAllPagesByUserId(userid);
    console.log(res);

    setPages(res?.data?.data?.user_views[0]?.pageIds || []);
  };

  const handlePageAdd = async (e) => {
    if (!e) e = window.event;
    var keyCode = e.code || e.key;
    if (keyCode == "Enter") {
      if (!pageName.length) return;
      const newPage = [...pages, { pageId: shortid.generate(), pageName }];
      setPages(newPage);
      setPageName("");
      const saveRes = await savePage(userid, newPage);
      if (!saveRes?.data?.data?.insert_user_views?.affected_rows) {
        alert("Cannot Save");
      }
      fetchAllPages();
    }
  };

  return (
    <div className="flex">
      <div className="container mx-auto w-10/12">
        <Header />

        <div className="">
          <div>
            <div className="mt-10" />

            <UniversalSearch
              auotSwitch={true}
              forcedGraphView={false}
              detailsPanelOpen={false}
              showTimePanel={showTimePanel}
              setShowTimePanel={setShowTimePanel}
            />
            <div className="mt-10" />

            <div className="flex  justify-center w-full relative">
              <div className="w-1/6">
                <Collapse defaultActiveKey={["1"]} ghost>
                  <Panel
                    extra={
                      <div
                        className="absolute w-full h-full left-0"
                        onClick={(e) => setShowTemplates(false)}
                      ></div>
                    }
                    header="Pages"
                    showArrow={false}
                    key="1"
                  >
                    <ul className="flex  flex-wrap">
                      {pages.length
                        ? pages.map((page) => (
                            <div
                              key={page.pageId}
                              className=" w-full fade-in-fwd"
                              onClick={() => {
                                setSelectedPage(
                                  page.pageId == selectedPage.pageId ? {} : page
                                );
                                setSelectedDB(null);
                                setShowTemplates(false);
                              }}
                            >
                              <li
                                className={classNames(
                                  " shadow px-1 py-2  cursor-pointer my-2 text-xs",
                                  selectedPage.pageId == page.pageId
                                    ? "ring"
                                    : ""
                                )}
                              >
                                {page.pageName}
                              </li>
                            </div>
                          ))
                        : null}

                      <div className=" newPage w-full h-full">
                        <li className="shadow  bg-gray-100  my-2 flex h-full">
                          <input
                            value={pageName}
                            onChange={(e) => setPageName(e.target.value)}
                            onKeyUp={handlePageAdd}
                            type="text"
                            placeholder="+ NEW"
                            className="outline-none bg-transparent text-xs  h-full px-1 py-2   w-full"
                          />
                        </li>
                      </div>
                    </ul>
                  </Panel>

                  <Panel
                    showArrow={false}
                    extra={
                      <div
                        className="absolute w-full h-full left-0"
                        onClick={(e) => {
                          setShowTemplates(true);
                          setSelectedDB(null);
                          setSelectedPage({});
                        }}
                      ></div>
                    }
                    header="Templates"
                  ></Panel>
                </Collapse>
              </div>

              {creatingNewTemplate ? (
                <NewTemplates
                  creatingNewTemplate={creatingNewTemplate}
                  setCreatingNewTemplate={setCreatingNewTemplate}
                />
              ) : showTemplates ? (
                <Templates setCreatingNewTemplate={setCreatingNewTemplate} />
              ) : (
                <Databases
                  selectedDB={selectedDB}
                  setSelectedDB={setSelectedDB}
                  selectedPage={selectedPage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotionMainPage;
