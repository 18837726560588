import { motion } from "framer-motion";

function AnimatedText({ text, type, activeSlide, animate, className }) {
  const tagMap = {
    paragraph: "p",
    heading1: "h1",
    heading2: "h2",
  };

  const splitWords = text.split(" ");

  // Create storage array
  const words = [];

  // Push each word into words array
  for (const [, item] of splitWords.entries()) {
    words.push(item.split(""));
  }

  // Add a space ("\u00A0") to the end of each word
  words.map((word) => {
    return word.push("\u00A0");
  });

  const container = {
    visible: {
      transition: {
        staggerChildren: 0.025,
      },
    },
  };

  const item = {
    hidden: {
      y: "200%",

      transition: {
        ease: [0.455, 0.03, 0.515, 0.955],
        duration: 0.85,
      },
    },
    visible: {
      y: 0,

      transition: {
        ease: [0.455, 0.03, 0.515, 0.955],
        duration: 0.75,
      },
    },
  };

  const Tag = tagMap[type];

  return (
    <motion.div
      initial="hidden"
      animate={animate ? "visible" : ""}
      variants={container}
      whileInView="visible"
      viewport={{ once: true }}
    >
      <Tag>
        {words.map((word, index) => {
          return (
            // Wrap each word in the Wrapper component
            <Wrapper key={index}>
              {words[index].flat().map((element, index) => {
                return (
                  <span
                    style={{
                      overflow: "hidden",
                      display: "inline-block",
                    }}
                    key={index}
                  >
                    <motion.span
                      style={{ display: "inline-block" }}
                      variants={item}
                      whileInView="visible"
                      viewport={{ once: true }}
                      className={className}
                    >
                      {element}
                    </motion.span>
                  </span>
                );
              })}
            </Wrapper>
          );
        })}
      </Tag>
    </motion.div>
  );
}

export default AnimatedText;

const Wrapper = (props) => {
  return <span className="word-wrapper">{props.children} </span>;
};
