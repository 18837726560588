export const alEquipmentDetails = {
  kwh: {
    id: "kwh",
    label: "Kwh Meter",
    description: "Measures the electricity usage",
    points: [],
  },

  steam: {
    id: "steam",
    label: "Steam Meter",
    description: "Measures the electricity usage",
    points: [],
  },

  vav: {
    id: "vav",
    label: "VAV",
    description:
      "Variable air volume terminal unit. VAV systems use a constant air temperature with a variable airflow rate.",
    points: [
      {
        name: "Damper Position",
        type: "sensor",
        abbr: "Damper",
        pointType: "Numeric",
        range: "0-100",
        haystackTags: ["air", "damper", "sensor"],
      },
      {
        name: "Room Temperature Setpoint",
        type: "setpoint",
        abbr: "RMT-STP",
        pointType: "Numeric",
        range: "60°-85°",
        haystackTags: ["temp", "sp", "effective", "zone"],
      },
      {
        name: "Occupied",
        type: "command",
        abbr: "Occ",
        pointType: "Enumeration",
        range: "True/False",
        haystackTags: ["occ", "cmd"],
      },
      {
        name: "Cubic Feet Per Minute",
        type: "sensor",
        abbr: "CFM",
        pointType: "Numeric",
        range: "0-3,000",
        haystackTags: ["inlet", "air", "sensor", "flow"],
      },
      {
        name: "Hot Water Valve ",
        type: "command",
        abbr: "HWV",
        pointType: "Numeric",
        range: "0-100",
        haystackTags: ["hot", "water", "valve", "cmd"],
      },
      {
        name: "Supply Air Temperature",
        type: "sensor",
        abbr: "SAT",
        pointType: "Numeric",
        range: "55°-120°",
        haystackTags: ["discharge", "air", "temp", "sensor"],
      },
      {
        name: "Room Temperature",
        type: "sensor",
        abbr: "RMT",
        pointType: "Numeric",
        range: "60°-85°",
        haystackTags: ["temp", "sensor", "air", "zone"],
      },
    ],
  },

  ahu: {
    id: "ahu",
    label: "AHU",
    description:
      "Air Handling Unit: An enclosure with a fan that delivers air to space via ductwork and performs one or more of the functions of cleaning, heating, cooling, humidifying, dehumidifying, ventilating, or circulating the air.",
    points: [
      {
        name: "Mixed Air Temperature",
        type: "sensor",
        abbr: "MAT",
        pointType: "Numeric",
        range: "50°-100°",
        haystackTags: ["mixed", "air", "temp", "sensor"],
      },
      {
        name: "Return Air Temperature",
        type: "sensor",
        abbr: "RAT",
        pointType: "Numeric",
        range: "50°-100°",
        haystackTags: ["sensor", "air", "temp", "entering"],
      },
      {
        name: "Supply Fan Speed",
        type: "command",
        abbr: "SF-Speed",
        pointType: "Numeric",
        range: "0-100",
        haystackTags: ["discharge", "air", "fan", "speed", "cmd"],
      },
      {
        name: "Supply Fan Command ",
        type: "command",
        abbr: "SF-CMD",
        pointType: "Boolean",
        range: "True-False,On/Off",
        haystackTags: ["discharge", "air", "fan", "run", "cmd"],
      },
      {
        name: "Chilled Water Valve",
        type: "command",
        abbr: "CHWV",
        pointType: "Numeric",
        range: "0-100",
        haystackTags: ["chiller", "water", "valve", "cmd"],
      },
      {
        name: "Exhaust Air Damper",
        type: "command",
        abbr: "Exhaust-Damper",
        pointType: "Numeric",
        range: "0-100",
        haystackTags: ["air", "damper", "exhaust", "cmd"],
      },
      {
        name: "Return Air Damper",
        type: "command",
        abbr: "Damper",
        pointType: "Numeric",
        range: "0-100",
        haystackTags: ["air", "damper", "entering", "cmd"],
      },
      {
        name: "Occ",
        type: "command",
        abbr: "Occ",
        pointType: "Boolean",
        range: "True/False",
        haystackTags: ["occ", "cmd"],
      },
      {
        name: "Cubic Feet Per Minute",
        type: "sensor",
        abbr: "CFM",
        pointType: "Number",
        range: "0-50,000",
        haystackTags: ["air", "sensor", "flow", "discharge"],
      },
      {
        name: "Hot Water Valve",
        type: "command",
        abbr: "HWV",
        pointType: "Numeric",
        range: "0-100",
        haystackTags: ["hot", "water", "valve", "cmd"],
      },
      {
        name: "Supply Air Temperature Setpoint",
        type: "setpoint",
        abbr: "SAT-SPT",
        pointType: "Numeric",
        range: "40°-150°",
        haystackTags: ["discharge", "air", "temp", "sp"],
      },
      {
        name: "Supply Air Temperature",
        type: "sensor",
        abbr: "SAT",
        pointType: "Numeric",
        range: "40°-150°",
        haystackTags: ["discharge", "air", "temp", "sensor"],
      },
    ],
  },

  fcu: {
    id: "fcu",
    label: "FCU",
    description:
      "Fan Coil Unit: A unitary, factory-made enclosure with a fan and coil that recirculates air in a space and performs one or more of the functions of cleaning, heating, cooling, humidifying, dehumidifying, or ventilating the air. Unit heaters are examples of FCUs.",
    points: [
      {
        name: "Supply Fan Command",
        type: "command",
        abbr: "SF-CMD",
        pointType: "Boolean",
        range: "True-False",
        haystackTags: ["discharge", "air", "fan", "cmd", "run"],
      },
      {
        name: "Chilled Water Valve",
        type: "command",
        abbr: "CHWV",
        pointType: "Numeric",
        range: "0-100",
        haystackTags: ["chilled", "water", "valve", "cmd"],
      },
      {
        name: "Room Temperature Setpoint",
        type: "setpoint",
        abbr: "RMT-STP",
        pointType: "Numeric",
        range: "60°-85°",
        haystackTags: ["temp", "sensor", "air", "zone", "sp"],
      },
      {
        name: "Occ",
        type: "command",
        abbr: "Occ",
        pointType: "Enumeration",
        range: "Occupied=0,UnOccupied=1 or 2",
        haystackTags: ["occ", "cmd"],
      },
      {
        name: "Cubic Feet Per Minute",
        type: "sensor",
        abbr: "CFM",
        pointType: "Numeric",
        range: "0-3,000",
        haystackTags: ["inlet", "air", "sensor", "flow"],
      },
      {
        name: "Hot Water Valve ",
        type: "command",
        abbr: "HWV",
        pointType: "Numeric",
        range: "0-100",
        haystackTags: ["hot", "water", "valve", "cmd"],
      },

      {
        name: "Supply Air Temperature",
        type: "sensor",
        abbr: "SAT",
        pointType: "Numeric",
        range: "40°-150°",
        haystackTags: ["discharge", "air", "temp", "sensor"],
      },

      {
        name: "Room Temperature",
        type: "sensor",
        abbr: "RMT",
        pointType: "Numeric",
        range: "60°-85°",
        haystackTags: ["zone", "air", "temp", "sensor"],
      },
    ],
  },

  boiler: {
    id: "boiler",
    label: "Chiller",
    description:
      "Equipment to remove heat from a liquid. Chillers typically use a vapor-compression or an absorption refrigeration cycle.",
    points: [
      {
        name: "Chilled Water Supply Temperature",
        type: "sensor",
        abbr: "CHWST",
        pointType: "Numeric",
        range: "40°-60°",
        haystackTags: ["temp", "chiller", "water", "leaving"],
      },
      {
        name: "Chilled Water Temperature Setpoint",
        type: "setpoint",
        abbr: "CHWSP",
        pointType: "Numeric",
        range: "40°-60°",
        haystackTags: ["temp", "chiller", "water", "leaving", "sp"],
      },
      {
        name: "Condenser Water Temperature",
        type: "command",
        abbr: "CWS",
        pointType: "Numeric",
        range: "70°-100°",
        haystackTags: ["entering", "condensor", "water", "temp", "sensor"],
      },
      {
        name: "Chilled Water Flow",
        type: "sensor",
        abbr: "Chiller-Flow",
        pointType: "Numeric",
        range: "0-3000 gpm",
        haystackTags: ["sensor", "flow", "water", "chilled"],
      },
      {
        name: "Chiller Run Command",
        type: "command",
        abbr: "Chiller-CMD",
        pointType: "Boolean",
        range: "True/False",
        haystackTags: ["chiller", "run", "cmd"],
      },
      {
        name: "Chilled Water Return Temperature",
        type: "sensor",
        abbr: "CWHRT",
        pointType: "Numeric",
        range: "40°-60°",
        haystackTags: ["chiller", "water", "temp", "sensor", "entering"],
      },
    ],
  },

  heat: {
    id: "heat",
    label: "Heat Exchanger",
    description: "Equipment to transfer heat between two working fluids.",
    points: [
      {
        name: "Hot Water Return Temperature",
        type: "sensor",
        abbr: "HWRT",
        pointType: "Numeric",
        range: "50°-212°",
        haystackTags: ["hot", "water", "temp", "entering", "sensor"],
      },
      {
        name: "Hot Water Leaving Temperature ",
        type: "sensor",
        abbr: "HWT",
        pointType: "Numeric",
        range: "50°-212°",
        haystackTags: ["hot", "water", "temp", "leaving", "sensor"],
      },
      {
        name: "Boiler Command ",
        type: "command",
        abbr: "Boiler-CMD",
        pointType: "Boolean",
        range: "True/False",
        haystackTags: ["cmd", "boiler"],
      },
    ],
  },
};
