import moment from "moment";
// import { gql } from "@apollo/client";

// export const LOAD_ALL_SITES = gql`
//   query($siteCategory: String) {
//     site(
//       where: { source: { _has_key: $siteCategory } }
//       order_by: { area: desc }
//     ) {
//       id
//       geoLat
//       geoLon
//       area
//       steamMeterId
//       elecMeterId
//       navName
//       source
//       floors {
//         id
//       }
//     }
//   }
// `;

export const LOAD_ALL_SITES_QUERY = (siteCategory) => {
  return {
    query: `
    query($siteCategory: String) {
      site(
        where: { source: { _has_key: $siteCategory } }
        order_by: { area: desc }
      ) {
        id
        geoLat
        geoLon
        area
        steamMeterId
        elecMeterId
        navName
        source
        floors {
          floor_id: id
          dis 
          description
        }
      }
    }
  `,
    variables: {
      siteCategory: siteCategory,
    },
  };
};

export const LOAD_EQUIPMENT_COUNT_QUERY = (siteId, siteCat) => {
  console.log({
    query: `
    query ($siteId: String, $siteCat: String) {
      equip_aggregate(where: {floor: {site: {source: {_has_key: $siteCat}, id: {_eq: $siteId}}}}) {
        aggregate {
          count(distinct: true)
        }
        nodes {
          floor_id
          mod
          id
          navName
          source
        }
      }
    }
  `,
    variables: {
      siteId: siteId,
      siteCat: siteCat,
    },
  });
  return {
    query: `
    query ($siteId: String, $siteCat: String) {
      equip_aggregate(where: {floor: {site: {source: {_has_key: $siteCat}, id: {_eq: $siteId}}}}) {
        aggregate {
          count(distinct: true)
        }
        nodes {
          floor_id
          mod
          id
          navName
          source
        }
      }
    }
  `,
    variables: {
      siteId: siteId,
      siteCat: siteCat,
    },
  };
};

export const LOAD_POINTS_COUNT = (siteId, siteCat) => {
  return {
    query: `
    query ($siteId: String, $siteCat: String) {
      kwhPoints: point_aggregate(where: {equip: {source: {_has_key: "elecMeter"}, floor: {site: {id: {_eq: $siteId}, source: {_has_key: $siteCat}}}}}) {
        aggregate {
          count
        }
      }
      steamPoints: point_aggregate(where: {equip: {source: {_has_key: "steamMeter"}, floor: {site: {id: {_eq: $siteId}, source: {_has_key: $siteCat}}}}}) {
        aggregate {
          count
        }
      }
      vavPoints: point_aggregate(where: {equip: {source: {_has_key: "vav"}, floor: {site: {id: {_eq: $siteId}, source: {_has_key: $siteCat}}}}}) {
        aggregate {
          count
        }
      }
      fcuPoints: point_aggregate(where: {equip: {source: {_has_key: "fcu"}, floor: {site: {id: {_eq: $siteId}, source: {_has_key: $siteCat}}}}}) {
        aggregate {
          count
        }
      }
      ahuPoints: point_aggregate(where: {equip: {source: {_has_key: "ahu"}, floor: {site: {id: {_eq: $siteId}, source: {_has_key: $siteCat}}}}}) {
        aggregate {
          count
        }
      }
      boilerPoints: point_aggregate(where: {equip: {source: {_has_key: "boilerPlant"}, floor: {site: {id: {_eq: $siteId}, source: {_has_key: $siteCat}}}}}) {
        aggregate {
          count
        }
      }
      chillerPoints: point_aggregate(where: {equip: {source: {_has_key: "chiller"}, floor: {site: {id: {_eq: $siteId}, source: {_has_key: $siteCat}}}}}) {
        aggregate {
          count
        }
      }
    }
    
    `,
    variables: {
      siteId: siteId,
      siteCat: siteCat,
    },
  };
};

export const LOAD_WEATHER = (day) => {
  return {
    query: `
    query ($startDate: String, $endDate: String, $limit: Int, $siteId: String, $siteCat: String) {
      weather: point(where: {id: {_eq: "p:uRichmond:r:1b740dbb-3ab381a7"}}) {
        navName
        pointHistoryAggregates(where: {timestamp: {_gte: $startDate, _lte: $endDate}, bucket_type: {_eq: "hour"}}, order_by: {timestamp: asc}) {
          bucket_type
          count
          max
          sum
          timestamp
        }
      }
    }
    `,

    variables: {
      startDate: `${day}T00:00:00`,
      endDate: `${day}T23:59:59`,
    },
  };
};

export const WEATHER_QUERY = (startDate, endDate, bucketType) => {
  return {
    query: `
    query MyQuery($startDate: String!, $endDate: String!, $bucketType: String!) {
      point(where: {navName: {_eq: "OA_Temp"}}) {
        navName
        pointHistoryAggregates(order_by: {timestamp: asc}, where: {bucket_type: {_eq: $bucketType}, timestamp: {_lte: $endDate, _gte: $startDate}}) {
          sum
          timestamp
          min
          max
          count
          bucket_type
          avg
          point {
            equip_id
          }
        }
        
      }
    }
    
    `,
    variables: {
      startDate: bucketType == "day" ? `${startDate}` : `${startDate}T00`,
      endDate: bucketType == "day" ? `${endDate}` : `${endDate}T23`,
      bucketType: bucketType,
    },
  };
};

// export const LOAD_EQUIPMENT_COUNT = gql`
//   query($siteId: String) {
//     equip_aggregate(where: { floor: { site_id: { _eq: $siteId } } }) {
//       aggregate {
//         count(distinct: true)
//       }
//       nodes {
//         source
//       }
//     }
//   }
// `;

export const LOAD_WT_AND_KWH_FOR_A_DAY_QUERY = (
  startDate,
  endDate,
  siteId,
  siteCat,
  bucketType
) => {
  return {
    query: `
    query ($startDate: String, $endDate: String, $limit: Int, $siteId: String, $siteCat: String, $bucketType: String) {
      meterData: building_meters(where: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}) {
        navName
        navName
        floor{
          site{
            navName
            geoLat
            geoLon
            source
          }
        }
        points(where: {navName: {_eq: "kWhTotal"}}) {
          navName
          __typename
          pointHistoryAggregates(order_by: {timestamp: asc}, where: {bucket_type: {_eq: $bucketType}, timestamp: {_lte: $endDate , _gte: $startDate}}) {
            sum
            timestamp
            min
            max
            count
            bucket_type
            avg
            point {
              navName
            }
          }
        }
        __typename
      }



    }
    
  `,
    variables: {
      startDate: bucketType == "day" ? `${startDate}` : `${startDate}T00`,
      endDate: bucketType == "day" ? `${endDate}` : `${endDate}T23`,
      siteId: siteId,
      siteCat: siteCat,
      bucketType: bucketType,
    },
  };
};

export const LOAD_WT_AND_STEAM_FOR_A_DAY_QUERY = (
  startDate,
  endDate,
  siteId,
  siteCat,
  bucketType
) => {
  console.log("VARIABLES", {
    startDate: `${startDate}T00`,
    endDate: `${endDate}T23`,
    siteId: siteId,
    siteCat: siteCat,
    bucketType: bucketType,
  });
  return {
    query: `query ($startDate: String, $endDate: String, $limit: Int, $siteId: String, $siteCat: String, $bucketType: String) {
      meterData: steam_meters(where: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}) {
        floor {
          site {
            navName
            source
          }
        }
        points(where: {navName: {_eq: "SteamTotalFlow"}}) {
          navName
          pointHistoryAggregates(order_by: {timestamp: asc}, where: {bucket_type: {_eq: $bucketType}, timestamp: {_lte: $endDate, _gte: $startDate}}) {
            sum
            timestamp
            min
            max
            count
            bucket_type
            avg
            point {
              navName
            }
          }
        }
      }
    }
    `,

    variables: {
      startDate: `${startDate}T00`,
      endDate: `${endDate}T23`,
      siteId: siteId,
      siteCat: siteCat,
      bucketType: bucketType || "hour",
    },
  };
};

export const LOAD_VAV_EQUIPMET_DATA_QUERY = (
  startDate,
  endDate,
  siteId,
  siteCat,
  equipTags
) => {
  return {
    query: `
    query ($startDate: String, $endDate: String, $limit: Int, $siteId: String, $siteCat: String, $equipTags: [String!], $pointTags: [String!]) {
      vav_air_damper_sensor: vav_air_damper_sensor(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}}) {
        unit: source(path: "unit")
        source
        navName
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
        
      }

      vav_effective_sp_temp_zone: vav_effective_sp_temp_zone(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}}) {
        unit: source(path: "unit")
        source
        navName
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
      }
      equip(where: {source: {_has_keys_all: $equipTags}, floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}) {
        id
        navName
        source
        navName
        floor {
          site {
            id
            dis
          }
        }
        unit: source(path: "unit")
        points(where: {source: {_has_keys_all: $pointTags}}) {
          navName
          pointHistories(order_by: {ts: asc}, limit: $limit, where: {_and: {ts: {_gte: $startDate, _lte: $endDate}}}) {
            ts
            value
            val
            hour
            day
            year
          }
        }
      }
      vav_air_sensor_temp_zone: vav_air_sensor_temp_zone(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}}) {
        unit: source(path: "unit")
          source
        navName
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
      }
      vav_cmd_hot_valve_water: vav_cmd_hot_valve_water(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}}) {
        unit: source(path: "unit")
          source
        navName
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
      }
      vav_air_flow_inlet_sensor: vav_air_flow_inlet_sensor(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}}) {
        unit: source(path: "unit")
          source
        navName
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
      }
      vav_air_discharge_sensor_temp: vav_air_discharge_sensor_temp(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}}) {
        unit: source(path: "unit")
          source
        navName
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
      }
    }
    
    `,
    variables: {
      startDate: `${startDate}T00`,
      endDate: `${endDate}T23`,
      siteId: siteId,
      siteCat: siteCat,
      equipTags: [...equipTags],
      pointTags: ["occ", "cmd"],
    },
  };
};

export const LOAD_AHU_EQUIPMET_DATA_QUERY = (
  startDate,
  endDate,
  siteId,
  siteCat,
  equipTags
) => {
  return {
    query: `
    query ($startDate: String, $endDate: String, $limit: Int, $siteId: String, $siteCat: String, $equipTags: [String!], $pointTags: [String!]) {
      ahu_air_cmd_discharge_fan_speed: ahu_air_cmd_discharge_fan_speed(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}}) {
        unit: source(path: "unit")
        source
        navName
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
      }
      ahu_air_cmd_discharge_fan_run: ahu_air_cmd_discharge_fan_run(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}}) {
        unit: source(path: "unit")
        source
        navName
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
      }
      ahu_air_mixed_sensor_temp: ahu_air_mixed_sensor_temp(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}}) {
        unit: source(path: "unit")
        source
        navName
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
      }
      ahu_air_entering_sensor_temp: ahu_air_entering_sensor_temp(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}}) {
        unit: source(path: "unit")
        source
        navName
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
      }
      ahu_chilled_cmd_valve_water: ahu_chilled_cmd_valve_water(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}}) {
        unit: source(path: "unit")
        source
        navName
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
      }
      ahu_air_cmd_damper_exhaust: ahu_air_cmd_damper_exhaust(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}}) {
        unit: source(path: "unit")
        source
        navName
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
      }
      ahu_air_cmd_damper_entering: ahu_air_cmd_damper_entering(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: "auxSite"}}}}}) {
        unit: source(path: "unit")
        source
        navName
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
      }
      ahu_air_discharge_flow_sensor: ahu_air_discharge_flow_sensor(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}}) {
        unit: source(path: "unit")
        source
        navName
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
      }
      ahu_cmd_hot_valve_water: ahu_cmd_hot_valve_water(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}}) {
        unit: source(path: "unit")
        source
        navName
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
      }
      ahu_air_discharge_sensor_temp: ahu_air_discharge_sensor_temp(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}}) {
        unit: source(path: "unit")
        source
        navName
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
      }
      equip(where: {source: {_has_keys_all: $equipTags}, floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}) {
        id
        navName
        source
        navName
        floor {
          site {
            id
            dis
          }
        }
        unit: source(path: "unit")
        points(where: {source: {_has_keys_all: $pointTags}}) {
          navName
          pointHistories(order_by: {ts: asc}, limit: $limit, where: {_and: {ts: {_gte: $startDate, _lte: $endDate}}}) {
            ts
            value
            val
            hour
            day
            year
          }
        }
      }
    }
    
    
`,
    variables: {
      startDate: `${startDate}T00`,
      endDate: `${endDate}T23`,
      siteId: siteId,
      siteCat: siteCat,
      equipTags: [...equipTags],
      pointTags: ["occ", "cmd"],
    },
  };
};

export const LOAD_FCU_EQUIPMET_DATA_QUERY = (
  startDate,
  endDate,
  siteId,
  siteCat,
  equipTags
) => {
  return {
    query: `
    query ($startDate: String, $endDate: String, $limit: Int, $siteId: String, $siteCat: String, $equipTags: [String!], $pointTags: [String!]) {
      fcu_air_cmd_discharge_fan_run: fcu_air_cmd_discharge_fan_run(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}}) {
        unit: source(path: "unit")
        source
        navName
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
      }
      fcu_air_discharge_sensor_temp: fcu_air_discharge_sensor_temp(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}}) {
        unit: source(path: "unit")
        source
        navName
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
      }
      fcu_cmd_hot_valve_water: fcu_cmd_hot_valve_water(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}}) {
        unit: source(path: "unit")
        source
        navName
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
      }
      fcu_air_sensor_sp_temp_zone: fcu_air_sensor_sp_temp_zone(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}}) {
        unit: source(path: "unit")
        source
        navName
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
      }
      fcu_air_flow_inlet_sensor: fcu_air_flow_inlet_sensor(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}}) {
        unit: source(path: "unit")
        source
        navName
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
      }
      equip(where: {source: {_has_keys_all: $equipTags}, floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}) {
        id
        navName
        source
        navName
        floor {
          site {
            id
            dis
          }
        }
        unit: source(path: "unit")
        points(where: {source: {_has_keys_all: $pointTags}}) {
          navName
          pointHistories(order_by: {ts: asc}, limit: $limit, where: {_and: {ts: {_gte: $startDate, _lte: $endDate}}}) {
            ts
            value
            val
            hour
            day
            year
          }
        }
      }
      fcu_chilled_cmd_valve_water: fcu_chilled_cmd_valve_water(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}}) {
        unit: source(path: "unit")
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
      }
    }
    
    
`,

    variables: {
      startDate: `${startDate}T00`,
      endDate: `${endDate}T23`,
      siteId: siteId,
      siteCat: siteCat,
      equipTags: [...equipTags],
      pointTags: ["occ", "cmd"],
    },
  };
};

export const LOAD_CHILLER_EQUIPMET_DATA_QUERY = (
  startDate,
  endDate,
  siteId,
  siteCat,
  equipTags
) => {
  return {
    query: `

    query ($startDate: String, $endDate: String, $limit: Int, $siteId: String, $siteCat: String, $equipTags: [String!], $pointTags: [String!]) {
      chiller_chilled_entering_sensor_temp_water: chiller_chilled_entering_sensor_temp_water(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}}) {
        unit: source(path: "unit")
        source
        navName
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
      }
      chiller_chilled_flow_sensor_water: chiller_chilled_flow_sensor_water(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}}) {
        unit: source(path: "unit")
        source
        navName
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
      }
      chiller_condenser_entering_sensor_temp_water: chiller_condenser_entering_sensor_temp_water(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}}) {
        unit: source(path: "unit")
        source
        navName
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
      }
      chiller_chiller_cmd_run: chiller_chiller_cmd_run(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}}) {
        unit: source(path: "unit")
        source
        navName
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
      }
      chiller_chilled_leaving_sp_temp_water: chiller_chilled_leaving_sp_temp_water(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}}) {
        unit: source(path: "unit")
        source
        navName
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
      }
      equip(where: {source: {_has_keys_all: $equipTags}, floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}) {
        id
        navName
        source
        navName
        floor {
          site {
            id
            dis
          }
        }
        unit: source(path: "unit")
        points(where: {source: {_has_keys_all: $pointTags}}) {
          navName
          pointHistories(order_by: {ts: asc}, limit: $limit, where: {_and: {ts: {_gte: $startDate, _lte: $endDate}}}) {
            ts
            value
            val
            hour
            day
            year
          }
        }
      }
    }

`,
    variables: {
      startDate: `${startDate}T00`,
      endDate: `${endDate}T23`,
      siteId: siteId,
      siteCat: siteCat,
      equipTags: [...equipTags],
      pointTags: ["occ", "cmd"],
    },
  };
};

export const LOAD_BOILER_EQUIPMENT_DATA_QUERY = (
  startDate,
  endDate,
  siteId,
  siteCat,
  equipTags
) => {
  return {
    query: `
  
    query ($startDate: String, $endDate: String, $limit: Int, $siteId: String, $siteCat:String, $equipTags: [String!], $pointTags: [String!]) {

      boilerPlant_boiler_cmd: boilerPlant_boiler_cmd(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}}) {
        unit: source(path: "unit")
        source
        navName
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
      }

      boilerPlant_entering_hot_sensor_temp_water: boilerPlant_entering_hot_sensor_temp_water(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}}) {
        unit: source(path: "unit")
        source
        navName
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
      }


      boilerPlant_hot_leaving_sensor_temp_water: boilerPlant_hot_leaving_sensor_temp_water(where: {equip: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}}) {
        unit: source(path: "unit")
        source
        navName
        pointHistoryAggregates_aggregate(order_by: {timestamp: desc}, where: {bucket_type: {_eq: "hour"}, timestamp: {_gte: $startDate, _lte: $endDate}}) {
          nodes {
            avg
            timestamp
          }
        }
      }

           
      equip(where: {source: {_has_keys_all: $equipTags}, floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}) {
        id
        navName
        source
        navName
        floor {
          site {
            id
            dis
          }
        }
        unit: source(path: "unit")
        points(where: {source: {_has_keys_all: $pointTags}}) {
          navName
          pointHistories(order_by: {ts: asc}, limit: $limit, where: {_and: {ts: {_gte: $startDate, _lte: $endDate}}}) {
            ts
            value
            val
            hour
            day
            year
          }
        }
      }
      
    }
      
      `,
    variables: {
      startDate: `${startDate}T00`,
      endDate: `${endDate}T23`,
      siteId: siteId,
      siteCat: siteCat,
      equipTags: [...equipTags],
      pointTags: ["occ", "cmd"],
    },
  };
};

export const LOAD_EQUIPMENT_OCCUPIED_STATUS_QUERY = (siteId) => {
  return {
    query: `
    query vav(
      $limit: Int = 96
      $startDate: String
      $endDate: String
      $siteId: String
      $equipTags: [String!]
      $pointTags: [String!]
    ) {
      equip(
        where: {
          floor: { site_id: { _eq: $siteId } }
          source: { _has_keys_any: $equipTags }
        }
      ) {
        id
        navName
        source
  
        points(where: { source: { _has_keys_all: $pointTags } }) {
          navName
          mod
          id
          source
        }
      }
    }`,
    variables: {
      siteId: siteId,
      pointTags: ["occ", "cmd"],
    },
  };
};

export const loadMeterInfoQuery = (meter) => {
  return {
    query: `  query meterInfo {
      meterInfo: info_${meter} {
        key
        value
      }
    }
    `,
  };
};

export const LOAD_TOTAL_KWH = (siteId, siteCat, date) => {
  return {
    query: `
    query ($startDate: String, $endDate: String, $limit: Int, $siteId: String, $siteCat: String, $day: String, $month: String, $year: String) {
      total: building_meters(where: {floor: {site_id: {_eq: $siteId}, site: {source: {_has_key: $siteCat}}}}) {
        points(where: {navName: {_eq: "kWhTotal"}}) {
          navName
          __typename
          pointHistories_aggregate(where: {day: {_eq: $day}, month: {_eq: $month}, year: {_eq: $year}}) {
    
            aggregate {
              count
              sum {
                val
              }
            }
          }
        }
        __typename
      }
    }
    
    `,
    variables: {
      siteId: siteId,
      siteCat: siteCat,
      day: moment(date).format("DD"),
      month: moment(date).format("MM"),
      year: moment(date).format("YYYY"),
    },
  };
};

export const GET_METERS_BY_TAG = (tags) => {
  return {
    query: `
    query MyQuery($tags: jsonb) {
      utility_meters_test(where: {tags: {_contains: $tags}}) {
        meter_number
        tags
      }
    }    
    `,
    variables: {
      tags: tags,
    },
  };
};

export const ADD_METER_BILLS = (bills) => {
  return {
    query: `

    mutation MyMutation($insertObjects: [utility_meters_data_insert_input!]!) {
      insert_utility_meters_data(objects: $insertObjects){
        returning{
          timestamp
          meter_number
          cost
          consumption
        }
      }
    }

    `,
    variables: {
      insertObjects: bills,
    },
  };
};

export const GET_BILLS = (meter_number, month, tags, year) => {
  return {
    query: `
    query MyQuery($meter_number: String, $month: String, $tags: String, $year: String) {
      utility_meters_data(where: {meter_number: {_eq: $meter_number}, month: {_eq: $month}, tags: {_eq: $tags}, year: {_eq: $year}}) {
        consumption
        cost
        meter_number
        month
        timestamp
        year
      }
    }
    

    `,
    variables: {
      meter_number: meter_number,
      month: month,
      tags: tags,
      year: year,
    },
  };
};

export const formUpdateQuery = (i, meter_number, uid, updateObject) => {
  let a = JSON.stringify(updateObject);
  a = a.replace(/"(\w+)"\s*:/g, "$1:");

  return `update_${i}: update_utility_meters_data(where: {uid: {_eq: "${uid}"}}, _set: ${a}) {
    affected_rows
  }
    `;
};

export const INSERT_VIEW = (
  viewId,
  properties,
  createdAt,
  tags,
  title,
  userId,
  pageId,
  date,
  status,
  lanes
) => {
  return {
    query: `
    mutation MyMutation($createdAt: String!, $date: jsonb, $viewId: String!, $title: jsonb!, $tags: jsonb, $status: jsonb, $properties: jsonb, $userId: String!, $pageId: String!, $lanes: jsonb) {
      insert_views(objects: {createdAt: $createdAt, date: $date, pageId: $pageId, properties: $properties, status: $status, tags: $tags, title: $title, userid: $userId, viewId: $viewId, lanes: $lanes}, on_conflict: {constraint: views_pkey, update_columns: [properties, title, tags, status, date, lanes]}) {
        affected_rows
      }
    }
    
    
    
    `,
    variables: {
      properties: properties,
      viewId: viewId,
      createdAt: createdAt,
      title: title,
      tags: tags,
      userId: userId,
      pageId: pageId,
      date: date,
      status: status,
      lanes: lanes,
    },
  };
};

export const INSERT_USER_VIEWS = (userId, viewIds) => {
  return {
    query: `
    mutation MyMutation($userid: String!, $viewIds: jsonb!) {
      insert_user_views(objects: {userid: $userid, viewIds: $viewIds}, on_conflict: {constraint: user_views_pkey, update_columns: viewIds}){
        affected_rows
      } 
    }
    `,
    variables: {
      userid: userId,
      viewIds: viewIds,
    },
  };
};

export const GET_VIEWS_BY_USER_AND_TYPE_AND_PAGEID = (userId, pageId, type) => {
  return {
    query: `
    query MyQuery($userId: String,  $pageId: String) {
      views(where: {pageId: {_eq: $pageId},  userid: {_eq: $userId}}) {
        title
        viewId
        createdAt
        date
        status  
        lanes
        ${type == "table" ? "properties" : ""}
      }
    }
    `,
    variables: {
      userId: userId,
      pageId: pageId,
    },
  };
};

export const GET_VIEWS_BY_VIEW_ID = (viewId) => {
  return {
    query: `
    query MyQuery($viewId: String) {
      views(where: {viewId: {_eq: $viewId}}) {
        title
        createdAt
        properties
        tags
        viewId
        date
        status
        lanes
      }
    }
    
    `,
    variables: {
      viewId: viewId,
    },
  };
};

export const DELETE_VIEW = (viewId) => {
  return {
    query: `
    mutation MyMutation($viewId: String) {
      delete_views(where: {viewId: {_eq: $viewId}}) {
        affected_rows
      }
    }
    
    
    `,
    variables: {
      viewId: viewId,
    },
  };
};

export const GET_PAGES_BY_USERID = (userid) => {
  return {
    query: `
    query MyQuery($userid: String!) {
      user_views(where: {userid: {_eq: $userid }}) {
        userid
        pageIds
      }
    }
    
    
    
    `,
    variables: {
      userid: userid,
    },
  };
};

export const SAVE_PAGE = (userid, pageIds) => {
  return {
    query: `
      mutation MyMutation($userid: String!, $pageIds: jsonb!) {
        insert_user_views(objects: {userid: $userid, pageIds: $pageIds}, on_conflict: {constraint: user_views_pkey, update_columns: pageIds}) {
          affected_rows
        }
      }
    `,
    variables: {
      userid: userid,
      pageIds: pageIds,
    },
  };
};

export const UPDATE_PAGE_LANES = (pageId, lanes) => {
  return {
    query: `
     mutation MyMutation($pageId: String!, $lanes: jsonb!) {
  update_views(where: {pageId: {_eq: $pageId}}, _set: {lanes: $lanes}) {
    affected_rows
  }
}

    `,
    variables: {
      pageId: pageId,
      lanes: lanes,
    },
  };
};

export const INSERT_INTO_EQ_TEMPLATES = (
  equipmentName,
  equipmentType,
  equipmentProperties,
  sites
) => {
  return {
    query: `
    mutation MyMutation($equipmentName: String!, $equipmentType: String!, $equipmentProperties: jsonb!, $sites: jsonb!) {
      insert_equipment_templates(objects: {equipment_name: $equipmentName, equipment_type: $equipmentType, properties: $equipmentProperties, sites: $sites}) {
        affected_rows
      }
    }
    
    `,
    variables: {
      equipmentName,
      equipmentType,
      equipmentProperties,
      sites,
    },
  };
};

export const GET_VIEWS_BY_SITE_AND_EQ = (equipmentType, site) => {
  return {
    query: `
    query MyQuery($site: jsonb, $equipmentType: String){
      views(where: {sites: {_contains: $site}, eq_type: {_ilike: $equipmentType}}) {
        title
        status
        properties
      }
    }
    
    `,
    variables: {
      equipmentType,
      site,
    },
  };
};

export const ASSIGN_SITE_AND_EQUIPMENTS = (views) => {
  return {
    query: `
    mutation MyMutation($views: [views_insert_input!]!) {
      insert_views(objects: $views, on_conflict: {constraint: views_pkey, update_columns: [sites, eq_type]}) {
        affected_rows
      }
    }
    `,
    variables: {
      views,
    },
  };
};

export const EQUIPMENT_OCC_STATUSES = () => {
  return {
    query: `
    query MyQuery {
      vavTotal: equip_aggregate(where: {source: {_has_key: "vav"}}) {
        aggregate {
          count
        }
      }
      vavOcc: equip_aggregate(where: {source: {_has_key: "vav"}, points: {source: {_has_key: "occ"}}}) {
        aggregate {
          count
        }
      }
      
       fcuTotal: equip_aggregate(where: {source: {_has_key: "fcu"}}) {
        aggregate {
          count
        }
      }
      fcuOcc: equip_aggregate(where: {source: {_has_key: "fcu"}, points: {source: {_has_key: "occ"}}}) {
        aggregate {
          count
        }
      }
      
      
       ahuTotal: equip_aggregate(where: {source: {_has_key: "ahu"}}) {
        aggregate {
          count
        }
      }
      ahuOcc: equip_aggregate(where: {source: {_has_key: "ahu"}, points: {source: {_has_key: "occ"}}}) {
        aggregate {
          count
        }
      }
      
      boilerTotal: equip_aggregate(where: {source: {_has_key: "boilerPlant"}}) {
        aggregate {
          count
        }
      }
      boilerOcc: equip_aggregate(where: {source: {_has_key: "boilerPlant"}, points: {source: {_has_key: "occ"}}}) {
        aggregate {
          count
        }
      }
      
       chillerTotal: equip_aggregate(where: {source: {_has_key: "chiller"}}) {
        aggregate {
          count
        }
      }
      chillerOcc: equip_aggregate(where: {source: {_has_key: "chiller"}, points: {source: {_has_key: "occ"}}}) {
        aggregate {
          count
        }
      }
    }
    `,
  };
};
