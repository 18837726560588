import useSelection from "antd/lib/table/hooks/useSelection";
import React, { useState, useEffect, useRef } from "react";
import { LOAD_EQUIPMENT_OCCUPIED_STATUS } from "../../GraphQl/Queries";
import { classNames } from "../../helpers/classNames";
import ProgressBar from "../../Resueables/ProgressBar/ProgressBar";
import { useParams } from "react-router-dom";
import { getAllEquipCountsAndOccupyStatus } from "../../helpers/getAllEquipCounts";
import Loader from "../../Resueables/Loader/Loader";
import { connect } from "react-redux";
import { getEqStatus } from "../API/API";
import BuildingSelector from "../BuildingSelector/BuildingSelector";
import NeumorphBox from "../../Resueables/NeumorphBox/NeumorphBox";
import "./BuildingDetails.css";

function BuildingDetailsScreen({ selectedSite, allEquipCounts, buildings }) {
  const tabs = [
    { name: "Basic", href: "#", current: true },
    { name: "Equipments", href: "#", current: false },
    // { name: "Connections", href: "#", current: false },
  ];
  const colorPallette = ["#9ba3af", "#3c82f6", "#12b981"];

  const [activeTab, setActiveTab] = useState("basic");
  const [occupyAndCount, setOccupyAndCount] = useState({});

  const [autoHeight, setAutoHeight] = useState(false);

  const params = useParams();

  const buildingListRef = useRef();

  useEffect(() => {
    try {
      (async () => {
        if (!Object.keys(occupyAndCount).length) {
          const eqOccAndCount = await getEqStatus(selectedSite.id);
          setOccupyAndCount(
            getAllEquipCountsAndOccupyStatus(eqOccAndCount.data.data.equip)
          );
        }
      })();
    } catch (e) {
      console.log(e);
    }
  }, []);

  const selectedGroup =
    buildings[selectedSite.value ? selectedSite.value : "allSites"];
  let selectedGroupArea = 0;
  selectedGroup.forEach((site) => {
    selectedGroupArea += parseInt(site.area.replace(/\D/g, ""));
  });

  useEffect(() => {
    setAutoHeight(false);
  }, [selectedGroup, selectedGroup]);

  return (
    <>
      <article>
        <div>
          <div>
            <img
              className="h-32 w-full  object-cover lg:h-48"
              src={
                "https://az589735.vo.msecnd.net/images/profilepics/1022700/104.jpg"
              }
              alt=""
            />
          </div>
          <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
              <div
                style={{
                  backgroundImage:
                    'url("https://images.static-collegedunia.com/public/image/2a63cae5d6f3047267286f770f0b0ebb.jpeg?tr=w-1100,h-550,c-force")',
                }}
                className="flex border rounded rounded-full h-32 w-32 bg-center bg-cover"
              ></div>
              <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                <div className="sm:hidden 2xl:block mt-6 min-w-0 flex-1">
                  <h1 className="text-2xl font-bold text-gray-900 truncate">
                    {selectedSite.navName}
                  </h1>
                </div>

                <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                  {Object.keys(allEquipCounts).length &&
                    Object.keys(allEquipCounts).map((x) =>
                      allEquipCounts[x] ? (
                        <span
                          className={classNames(
                            `${x.split("Count")[0].toLowerCase()}`,
                            "badge  p-1 text-sm  font-medium px-2 mr-2 rounded-full capitalize"
                          )}
                        >
                          {x.split("Count")[0]}
                        </span>
                      ) : null
                    )}
                </div>
              </div>
            </div>
            <div className="hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1">
              <h1 className="text-2xl font-bold text-gray-900 truncate">
                {selectedSite.navName}
              </h1>
            </div>
          </div>
        </div>

        {/* Tabs */}
        <div className="mt-6 sm:mt-2 2xl:mt-5">
          <div className="border-b border-gray-200">
            <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {tabs.map((tab) => (
                  <div
                    onClick={() => setActiveTab(tab.name.toLocaleLowerCase())}
                    key={tab.name}
                    className={classNames(
                      activeTab == tab.name.toLowerCase()
                        ? "border-pink-500 text-gray-900"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                      "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer"
                    )}
                  >
                    {tab.name}
                  </div>
                ))}
              </nav>
            </div>
          </div>
        </div>
        {/* Description list */}

        {activeTab == "basic" && (
          <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Name</dt>
                <dd className="mt-1 text-lg font-semibold text-gray-900">
                  {selectedSite.navName}
                </dd>
              </div>

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {selectedSite.isAGroup ? "No of Sites" : "Floors"}
                </dt>

                <dd className="mt-1 text-lg font-semibold text-gray-900">
                  {selectedSite.source && !selectedSite.isAGroup && (
                    <>{selectedSite.floors.length}</>
                  )}
                  {selectedSite.isAGroup && <>{selectedGroup.length}</>}
                </dd>
              </div>

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Sqft</dt>
                <dd className="mt-1 text-lg font-semibold text-gray-900">
                  {selectedSite.isAGroup && (
                    <>
                      {selectedGroupArea.toLocaleString()} ft<sup>2</sup>
                    </>
                  )}
                  {selectedSite.area}
                </dd>
              </div>

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Location</dt>
                <dd className="mt-1 text-lg font-semibold text-gray-900">
                  {selectedSite.source && !selectedSite.isAGroup && (
                    <>
                      {selectedSite.source.geoCity} ,
                      {selectedSite.source.geoCountry}
                    </>
                  )}

                  {selectedSite.isAGroup && <>Richmond, VA</>}
                </dd>
              </div>

              {selectedGroup && selectedSite.isAGroup && (
                <div className="sm:col-span-2">
                  <dt className="text-sm font-medium text-gray-500">
                    Buildings
                  </dt>
                  <dd className="mt-1  font-semibold text-gray-900">
                    <div>
                      <div className="flow-root mt-6">
                        <ul
                          ref={buildingListRef}
                          role="list"
                          className={classNames(
                            "-my-5 divide-y divide-gray-200 flex flex-wrap w-100 justify-between building-list overflow-hidden transition-all",
                            autoHeight ? "h-auto" : "h-40"
                          )}
                        >
                          {selectedGroup.map((site) => (
                            <li
                              key={site.navName}
                              className="py-4"
                              style={{ width: "40%" }}
                            >
                              <div className="flex items-center space-x-4">
                                <div className="flex-shrink-0">
                                  {/* <img className="h-8 w-8 rounded-full" src={person.imageUrl} alt="" /> */}
                                </div>
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate">
                                    {site.navName}
                                  </p>
                                  {/* <p className="text-sm text-gray-500 truncate">{'@' + site.handle}</p> */}
                                </div>
                                <div>
                                  {site.elecMeterId && (
                                    <a className="badge kwh inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50">
                                      Kwh
                                    </a>
                                  )}
                                  {site.steamMeterId && (
                                    <a className="badge steam inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50">
                                      Steam
                                    </a>
                                  )}
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="mt-6">
                        <a
                          onClick={() => setAutoHeight((prev) => !prev)}
                          className="w-1/2 mx-auto mt-10 flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50  my-5"
                        >
                          {autoHeight ? "View Less" : "View More"}
                        </a>
                      </div>
                    </div>
                  </dd>
                </div>
              )}

              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">About</dt>
                <dd className="mt-1 max-w-prose  text-gray-900 space-y-5">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Facilis labore aut non? Quasi illo dolor culpa accusantium
                  sint distinctio aliquam dolorum harum possimus? Eum rerum est
                  magni provident, harum labore. Possimus ea, necessitatibus
                  cumque veritatis eaque facilis facere, quas laudantium nulla
                  id voluptatum sequi! Illo in consectetur consequatur magni
                  dignissimos sit magnam sequi fugiat suscipit, impedit soluta
                </dd>
              </div>
            </dl>
          </div>
        )}

        {activeTab == "equipments" && (
          <div className="mt-6 max-w-7xl mx-auto">
            <ul
              role="list"
              className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3  w-full"
            >
              {Object.keys(occupyAndCount).length ? (
                Object.keys(occupyAndCount).map((x) => (
                  <li
                    key={x}
                    className="col-span-1 bg-white rounded-lg  divide-y divide-gray-200"
                  >
                    <div className="w-full flex items-center justify-between  space-x-6">
                      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            {x.toUpperCase()}
                          </h3>
                          <p className="mt-1 max-w-2xl text-sm text-gray-500">
                            Short Desc about Vav
                          </p>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                          <dl className="sm:divide-y sm:divide-gray-200">
                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-gray-500">
                                Equip Name
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {x.toUpperCase()}
                              </dd>
                            </div>

                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-gray-500">
                                Count
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {occupyAndCount[x].count}
                              </dd>
                            </div>

                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-gray-500">
                                Active
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {occupyAndCount[x].occCount}
                              </dd>
                            </div>

                            <div className="py-4 sm:py-5 sm:gap-4 sm:px-6">
                              <ProgressBar
                                makeTransparent
                                progress={
                                  (occupyAndCount[x].occCount /
                                    occupyAndCount[x].count) *
                                  100
                                }
                                fillColor={
                                  colorPallette[
                                    Math.floor(
                                      Math.random() *
                                        (colorPallette.length - 0) +
                                        0
                                    )
                                  ]
                                }
                                backGroundColor=""
                                text={x.toUpperCase()}
                              ></ProgressBar>
                              <dt className="text-gray-500 -mt-3">
                                {occupyAndCount[x].occCount} of{" "}
                                {occupyAndCount[x].count} Active{" "}
                              </dt>
                            </div>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <div className="flex items-center flex-col  justify-center w-fulll">
                  <Loader size="50px" />
                  <div className="mt-5" />
                  <div>Loading Equipment Stasues</div>
                </div>
              )}
            </ul>
          </div>
        )}

        {activeTab == "connections" && (
          <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              CONNECTIONS
            </dl>
          </div>
        )}
      </article>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    selectedSite: state.buildings.selectedSite,
    buildings: state.buildings,
    allEquipCounts: state.buildings.equipmentCounts.count,
  };
};
export default connect(mapStateToProps)(BuildingDetailsScreen);
