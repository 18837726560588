import React, { Fragment, useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { IoMdAddCircleOutline } from "react-icons/io";
import { connect, useStore } from "react-redux";
import {
  addMeterBills,
  getBills,
  getMetersByTags,
  saveCostAndCons,
  saveUtilityMeterData,
  updateBillByUid,
} from "../API/API";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";

import { allMeterOptions } from "../../redux/reducers/search.reducers";

import { Menu, RadioGroup, Transition } from "@headlessui/react";
import { classNames } from "../../helpers/classNames";
import { FiChevronDown } from "react-icons/fi";
import dayjs from "dayjs";

const people = [
  {
    name: "Jane Cooper",
    title: "Regional Paradigm Technician",
    department: "Optimization",
    role: "Admin",
    email: "jane.cooper@example.com",
    image:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
  },
  // More people...
];

function AddData({
  universalSearchState,
  allBills,
  currentBill,
  selectedMeter,
}) {
  const [rows, setRows] = useState([{ id: 1 }]);
  const [allRowsData, setAllRowsData] = useState({});
  const [allMeters, setAllMeters] = useState([]);
  const [costConsMap, setCostConsMap] = useState({});
  const [toUpdate, setToUpdate] = useState(true);
  const [activeMeterNumber, setActiveMeterNumber] = useState("");

  const [currentMonthBills, setCurrentMonthBills] = useState([]);

  const [currentSelectedMonth, setCurrentSelectedMonth] = useState(
    moment().format("MMMM")
  );

  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const meters = await getMetersByTags(params.utilityMeter);

      if (meters.data.data.utility_meters_test) {
        // meters.data.data.utility_meters_test.forEach((meter) => {
        //   meter.buildings = meter.buildings.split(",");
        // });

        setAllMeters(meters.data.data.utility_meters_test);
      }
    })();

    // getMetersByTags();
  }, [params]);

  useEffect(() => {
    (async () => {
      let currentMonthBill = await getBills(
        null,
        currentSelectedMonth,
        params.utilityMeter,
        dayjs(universalSearchState.time.endData).year().toString()
      );
      const meterData = currentMonthBill.data.data.utility_meters_data;

      setCurrentMonthBills(meterData);

      if (!meterData.length) {
        return setCostConsMap({});
      }

      const costConsMapCopy = JSON.parse(JSON.stringify(costConsMap));

      for (let i in meterData) {
        costConsMapCopy[meterData[i].meter_number] = {
          meter_number: meterData[i].meter_number,
          cost: meterData[i]?.cost,
          consumption: meterData[i]?.consumption,
        };
      }
      console.log("COST CONS COPY", costConsMap);

      setCostConsMap(costConsMapCopy);
    })();
  }, [
    selectedMeter,
    universalSearchState.time.label,
    params,
    currentSelectedMonth,
  ]);

  const checkNumber = (number) => {
    const reg = /^[0-9\b]+$/;
    let preval = number;
    if (number === "" || reg.test(number)) return true;
    else number = preval.substring(0, preval.length - 1);
  };

  const onInputChange = (meter_number, input) => {
    setActiveMeterNumber(meter_number);
    if (!checkNumber(input.target.value)) return;
    const costConsMapCopy = JSON.parse(JSON.stringify(costConsMap));
    costConsMapCopy[meter_number] = {
      ...costConsMap[meter_number],
      meter_number: meter_number,
      [input.target.name]: input.target.value,
    };
    setCostConsMap(costConsMapCopy);
  };

  const saveData = async (input, meter_number) => {
    if (!meter_number) return;
    if (!costConsMap[meter_number]) return;
    const cost = costConsMap[meter_number].cost;
    const consumption = costConsMap[meter_number].consumption;

    console.log(cost, consumption);
    // console.log(input.target.value, input.target.name, meter_number);

    const saveRes = await saveCostAndCons(
      meter_number,
      cost || null,
      consumption || null,
      `${meter_number}_${currentSelectedMonth}_${universalSearchState.time.label}`,
      universalSearchState.meter.value,
      universalSearchState.time.label,
      currentSelectedMonth
    );

    setActiveMeterNumber(null);
    console.log(saveRes);
  };

  const handleMonthChange = (month, monthNumber) => {
    setCurrentSelectedMonth(month);
  };

  const unit =
    params.utilityMeter == "propane" || params.utilityMeter == "oil"
      ? "Gal"
      : "CCF";

  return (
    <>
      <div className="flex  flex-col">
        <div className="metrics-data-from flex">
          <div className="mt-14">
            <div className="mt-4  items-center">
              <h1 className="text-xl font-bold mb-7">Bill type</h1>
              {allMeterOptions
                .filter((x) => x.isUtility && x.value !== "kwh")
                .map((utility, index) => (
                  <div key={utility.value} className="flex items-center my-2">
                    <input
                      id={utility.value}
                      value={utility.value}
                      type="radio"
                      name="utilityMetricSelect"
                      className="hidden"
                      onChange={(e) =>
                        history.push(`/dashboard/utilities/${utility.value}`)
                      }
                    />
                    <label
                      className={classNames(
                        "border border-1 rounded rounded-full w-4 h-4 radio-btn inline-block mr-3  border-box cursor-pointer"
                      )}
                      htmlFor={utility.value}
                    >
                      <div
                        className={classNames(
                          utility.value == params.utilityMeter
                            ? " active-radio-btn"
                            : "",
                          "w-full h-full rounded rounded-full"
                        )}
                      ></div>
                    </label>
                    <span className="text-lg">{utility.label}</span>
                  </div>
                ))}

              <h1 className="text-xl font-bold mt-12 mb-7">Change Month</h1>

              <Menu as="div" className="relative inline-block w-40 text-left">
                <div>
                  <Menu.Button className="inline-flex justify-around w-full rounded-md border border-gray-300 shadow-sm px-3 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                    <span>{currentSelectedMonth}</span>
                    <FiChevronDown
                      className="-mr-1 ml-2 h-5 w-5"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-left absolute left-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {moment.months().map((month, i) => (
                        <Menu.Item
                          onClick={() => handleMonthChange(month, i + 1)}
                        >
                          {({ active }) => (
                            <a
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              {" "}
                              {month}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
          <div className="flex-1 mt-10 ml-16">
            <form>
              <div>
                <div className="headings text-center uppercase font-bold text-gray-300 flex w-100 justify-around">
                  <div className="flex-1">
                    {params.utilityMeter == "oil" ? "Location" : "Meter"}
                  </div>
                  {/* <div className="flex-1">Buildings</div> */}
                  <div className="flex-1">Total Cost</div>
                  <div className="flex-1">Total Consumption</div>
                </div>

                <div className="rounded rounded-lg shadow shadow-sm bg-white mt-5 text-center">
                  {allMeters.length ? (
                    allMeters.map((meter) => (
                      <div
                        className={classNames(
                          "flex",
                          activeMeterNumber == meter.meter_number
                            ? "active_entry"
                            : ""
                        )}
                      >
                        <div className="flex-1 p-4 font-bold border-r border-b text-gray-500 ">
                          {params.utilityMeter == "oil" ? "" : "#"}{" "}
                          {meter.meter_number}
                        </div>

                        {/* <div className="flex-1 border-r border-b  w-full flex p-4">
                          <BuildingsPopOver buildings={meter.buildings} />
                        </div> */}

                        <div className="flex-1 p-4 border-r border-b">
                          <span className="unit primary-text-color font-bold">
                            $
                          </span>

                          <input
                            name="cost"
                            pattern="[0-9]{1,5}"
                            onBlur={(e) => {
                              saveData(e, meter.meter_number);
                            }}
                            onChange={(e) =>
                              onInputChange(meter.meter_number, e)
                            }
                            type="text"
                            className="border-0 focus:outline-none text-center font-bold primary-text-color w-34"
                            placeholder="Type Amount"
                            value={
                              costConsMap[meter.meter_number]
                                ? costConsMap[meter.meter_number].cost
                                : ""
                            }
                          />
                        </div>

                        <div className="flex-1 p-4 border-b">
                          <input
                            pattern="[0-9]{1,5}"
                            onBlur={(e) => {
                              saveData(e, meter.meter_number);
                            }}
                            onChange={(e) =>
                              onInputChange(meter.meter_number, e)
                            }
                            type="text"
                            name="consumption"
                            className="border-0 focus:outline-none text-center font-bold primary-text-color w-34"
                            placeholder="Type Consumption"
                            value={
                              costConsMap[meter.meter_number]
                                ? costConsMap[meter.meter_number].consumption
                                : ""
                            }
                          />
                          <span className="unit primary-text-color font-bold">
                            {unit}
                          </span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <h1 className="text-center py-3">No meters found</h1>
                  )}
                </div>
              </div>

              <div className="flex justify-end mt-10">
                {/* <button
                  type="button"
                  class="nextMetricBtn mr-10"
                  onClick={nextMetric}
                >
                  NEXT METRIC
                </button> */}
                {/* <button
                  // onClick={handleSubmitBtnClick}
                  type="submit"
                  class=" saveDataBtn"
                >
                  Save
                </button> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  console.log("STATE", state);
  return {
    universalSearchState: state.search.universalSearchState,
  };
};

export default connect(mapStateToProps, null)(AddData);
