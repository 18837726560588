import React, { Fragment, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "./Display.css";
import { getAllSites, getDisplayTable } from "../API/API";
import DisplayHeader from "./DisplayHeader/DisplayHeader";
import DisplaySlide from "./DisplaySlide/index";
import DisplaySlideHeading from "./DisplaySlide/DisplaySlideHeading";
import DisplaySlideContent from "./DisplaySlide/DisplaySlideContent/DisplaySlideContent";

function Display() {
  const [sites, setSites] = useState([]);

  const [activeSlide, setActiveSlide] = useState(0);

  const [currentSite, setCurrentSite] = useState(sites[0]);

  const [allSlides, setAllSlides] = useState([]);

  const settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    infinite: true,
    afterChange: (index) => {
      setActiveSlide(index);
    },
    // autoplay: true,
    // autoplaySpeed: 10000,
  };

  useEffect(() => {
    (async () => {
      const sitesRes = await getAllSites();
      const onlyValidSites = sitesRes.data.data.site.filter((x) =>
        x.navName ? true : false
      );

      setSites(onlyValidSites);

      const res = await getDisplayTable();
      setAllSlides(res.data);
    })();
  }, []);

  return (
    <div className="relative display">
      <DisplayHeader />

      <div className="container absolute vh-center mx-auto w-10/12">
        <Slider {...settings} className=" display-slider text-center mt-20">
          {allSlides.map((x, idx) => (
            <DisplaySlide>
              <DisplaySlideHeading
                headingText={x.name}
                type="heading1"
                activeSlide={activeSlide}
                index={idx}
              />

              <div className=" text-center">
                <DisplaySlideContent
                  index={idx}
                  sites={sites}
                  activeSlide={activeSlide}
                  id={x.id}
                />
              </div>
            </DisplaySlide>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Display;
