import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { handleMeterStateChange } from "../../redux/actions";

import Loader from "../../Resueables/Loader/Loader";
import ProgressBar from "../../Resueables/ProgressBar/ProgressBar";

function BuildingDetailsCard({
  setOpen,
  open,
  selectedSite,
  allDayData,
  hourlyData,
  equipmentCounts,
  dispatch,
  handleMeterStateChange,
}) {
  const history = useHistory();
  // let kwhTotal = 0,
  //   eqCount = 0,
  //   occEqCount = 0;al = 0,
  //   steamTot

  // if (allDayData.kwh) {
  //   allDayData.kwh.forEach((x) => (kwhTotal += x.val));
  // }

  // if (allDayData.steam) {
  //   allDayData.steam.forEach((x) => (steamTotal += x.val));
  // }
  // if (allEquipCounts) {
  //   Object.values(allEquipCounts).forEach((x) => (eqCount += x));
  // }
  if (!equipmentCounts) {
    return <></>;
  }

  return (
    <div
      onClick={() => {
        handleMeterStateChange({
          label: "Building Details",
          value: "buildingdetails",
          description: "See all the details of the current Building",
          image:
            "https://images.static-collegedunia.com/public/image/2a63cae5d6f3047267286f770f0b0ebb.jpeg?tr=w-1100,h-550,c-force",
        });
        history.push(
          `/dashboard/buildingdetails/${
            selectedSite && selectedSite.id ? selectedSite.id : ""
          }`
        );
      }}
    >
      <div className="bg-white shadow  metercard mx-auto border px-6 py-4 rounded rounded-xl mx-auto">
        <div>
          <div className="flex justify-between">
            <span className="color-darkblue font-bold">Building Details</span>
            <span className="color-grey">Today</span>
          </div>

          <div className="my-8">
            <div className="relative ">
              {!open && (
                <>
                  <a
                    style={{
                      backgroundColor: "rgba(13,164,231, 0.8)",
                      zIndex: 2,
                    }}
                    className="absolute inset-0 text-center flex flex-col items-center justify-center opacity-0 hover:opacity-100 bg-opacity-90 duration-300"
                  >
                    <p className="mx-7 text-white">
                      Click to see much more details, about the selected
                      building "{selectedSite.navName}" and meta data like.
                      Occupied Equipments, Floors, and its connections with
                      other buildings
                    </p>
                  </a>
                </>
              )}

              <a href="#" className="relative">
                <div
                  style={{
                    backgroundImage:
                      "url('https://images.static-collegedunia.com/public/image/2a63cae5d6f3047267286f770f0b0ebb.jpeg?tr=w-1100,h-550,c-force')",
                    backgroundSize: "200%",
                  }}
                  className=" metercard-chartwrapper flex flex-wrap content-center bg-center bg-no-repeat"
                ></div>
              </a>
            </div>
          </div>
          <div className="mt-7 mb-2 flex justify-between">
            <div>
              <h3 className="text-xl mb-5 text-left">{selectedSite.navName}</h3>
              <div className="text-left">
                <>
                  Total Equipment Count:{" "}
                  <span className="font-bold text-lg">
                    {Object.values(equipmentCounts).length
                      ? Object.values(equipmentCounts).reduce((a, b) => a + b)
                      : ""}
                  </span>
                  <p className="flex items-center mt-2">
                    <Loader size="25px" />
                    <span className="ml-2">Loading occupied Status</span>
                  </p>
                </>
              </div>
            </div>
            <div className="">
              {/* <p className=" text-sm mb-2 flex items-center  justify-between  text-right">
                <span className="badge kwh p-1 text-xs  font-medium px-2 ml-2 rounded-full mr-2">
                  Kwh
                </span>
                <span>
                  <small className="ml-1">kwh</small>
                </span>
              </p>
              <p className=" text-sm mb-2 flex justify-between items-center text-right">
                <span className="badge steam text-xs p-1 px-2 font-medium  rounded-full">
                  Steam
                </span>
                <span>
                  <small className="ml-1">lb/h</small>
                </span>
              </p>
              <p className=" text-sm mb-2 flex items-center justify-between  text-right">
                <span className="badge text-xs p-1 px-2 font-medium  rounded-full bg-pink-100 text-pink-800 ml-1">
                  Area
                </span>
                <span>{selectedSite.area}</span>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    count: state.count,
    selectedSite: state.buildings.selectedSite,
    equipmentCounts: state.buildings.equipmentCounts.count,
  };
};

const mapDispatchToProps = {
  handleMeterStateChange: handleMeterStateChange,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuildingDetailsCard);
