import React, { useEffect, useState } from "react";
import moment from "moment";
import Modal from "antd/lib/modal/Modal";
import NewItemModal from "./NewItemModal";
import {
  deleteViewById,
  getAllViewsByUserIdAndViewType,
  getViewsByViewId,
} from "../../API/API";
import { BsTrash } from "react-icons/bs";
import { Empty, notification, Spin } from "antd";
import Loader from "../../../Resueables/Loader/Loader";
import { IoMdHeartEmpty } from "react-icons/io";

function List({ list, gallery, selectedPage }) {
  const [lists, setLists] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);

  const [currentlySelectedList, setCurrentlySelectedList] = useState({});

  const [loadingItemDetails, setLoadingItemDetails] = useState("");

  const handleItemClick = async (item) => {
    if (item.viewId || item.id) {
      setLoadingItemDetails(item.viewId);
      const res = await getViewsByViewId(item.viewId || item.id);
      await setCurrentlySelectedList(res.data.data.views[0]);
      setLoadingItemDetails("");
    } else {
      await setCurrentlySelectedList({});
    }
    setModalOpen(true);
  };

  useEffect(() => {
    fetchAllLists();
  }, []);

  const fetchAllLists = async () => {
    const res = await getAllViewsByUserIdAndViewType(
      JSON.parse(localStorage.getItem("user")).sub,
      selectedPage.pageId
    );

    setLists(res?.data?.data?.views || []);
  };

  const handleItemDelete = async (item, index) => {
    let res = await deleteViewById(item.id || item.viewId);

    if (res?.data?.data?.delete_views.affected_rows == 1) {
      const listsCopy = [...lists];
      listsCopy.splice(index, 1);
      console.log();
      setLists(listsCopy);
      notification.success({
        message: "Deleted SuccessFully",
      });
    } else {
      notification.error({
        message: "Error Deletion",
      });
    }
  };

  return (
    <>
      {list && (
        <ul role="list" className="space-y-3">
          <NewItemModal
            setCurrentlySelectedList={setCurrentlySelectedList}
            setLists={setLists}
            currentlySelectedList={currentlySelectedList}
            open={modalOpen}
            setOpen={setModalOpen}
            lists={lists}
            selectedPage={selectedPage}
            fetchAllLists={fetchAllLists}
          />

          {lists.length ? (
            lists.map((item, index) => (
              <li
                key={item.viewid}
                className="bg-white shadow overflow-hidden rounded-md px-6 py-4 flex items-center"
              >
                <span
                  className="w-30 cursor-pointer flex items-center"
                  onClick={() => handleItemClick(item)}
                >
                  <span>{item.title.value}</span>
                  {loadingItemDetails == item.viewId && (
                    <span className="ml-2 -mb-1">
                      <Spin size="small" />
                    </span>
                  )}
                </span>
                <BsTrash
                  onClick={() => handleItemDelete(item, index)}
                  className="ml-auto cursor-pointer"
                  size="20px"
                  color="red"
                />
              </li>
            ))
          ) : (
            <Empty />
          )}

          <button
            type="button"
            className="p-1 border rounded rounded-lg px-3 mt-3 text-xs"
            onClick={() => handleItemClick({})}
          >
            + New
          </button>
        </ul>
      )}

      {gallery && (
        <ul
          role="list"
          className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
        >
          <NewItemModal
            setCurrentlySelectedList={setCurrentlySelectedList}
            setLists={setLists}
            currentlySelectedList={currentlySelectedList}
            open={modalOpen}
            setOpen={setModalOpen}
            lists={lists}
            selectedPage={selectedPage}
            fetchAllLists={fetchAllLists}
          />

          {lists.length ? (
            lists.map((item, index) => (
              <li
                onClick={() => handleItemClick(item)}
                key={item.title}
                className="relative rounded-lg border border-gray-300 bg-white  shadow-sm  items-center space-x-3 hover:border-gray-400  cursor-pointer"
              >
                <div className="flex-shrink-0 h-32 border-b border-gray-300"></div>

                <div className="flex-1 min-w-0 p-2">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="text-sm  m-0 font-medium text-gray-900">
                    <span>{item.title.value}</span>
                    {loadingItemDetails == item.viewId && (
                      <span className="ml-2 -mb-1">
                        <Spin size="small" />
                      </span>
                    )}
                  </p>
                </div>
              </li>
            ))
          ) : (
            <Empty />
          )}

          <li
            onClick={() => handleItemClick({})}
            key="new"
            className="relative rounded-lg border border-gray-300 bg-white  shadow-sm  items-center space-x-3 hover:border-gray-400  cursor-pointer"
          >
            <div className="flex-1 min-w-0 p-2 border h-full flex items-center justify-center">
              <p className="text-sm  m-0 font-medium text-gray-400"> + New</p>
            </div>
          </li>
        </ul>
      )}
    </>
  );
}

export default List;
