import React, { useEffect, useState } from "react";
import moment from "moment";

import { Calendar, Modal, Spin } from "antd";
import { MdAdd } from "react-icons/md";
import NewItemModal from "./NewItemModal";
import {
  getAllViewsByUserIdAndViewType,
  getViewsByViewId,
} from "../../API/API";

function dateFullCellRender(
  date,
  setOpen,
  lists,
  setCurrentlySelectedList,
  setOpenedDate
) {
  const [loadingItemDetails, setLoadingItemDetails] = useState("");

  const handleItemClick = async (item) => {
    if (item.viewId || item.id) {
      setLoadingItemDetails(item.viewId);
      const res = await getViewsByViewId(item.viewId);
      await setCurrentlySelectedList(res.data.data.views[0]);
      setLoadingItemDetails("");
    } else {
      await setCurrentlySelectedList({});
    }
    setOpen(true);
  };

  return (
    <>
      <div className="datebox w-full h-full overflow-y-scroll">
        <div
          onClick={() => {
            setOpenedDate(date);
            setOpen(true);
          }}
          className="absolute hover:bg-gray-200 addicon text-gray-500 shadow rounded rounded-sm top-2 left-2"
        >
          <MdAdd size="20px" />
        </div>

        <div className="m-2">{date.date()}</div>
        <ul role="list" className="space-y-3">
          {lists.map((item) =>
            Object.keys(item.date || {}).length ? (
              date.format("DD-MM-YYYY") ==
              moment(item?.date?.value).format("DD-MM-YYYY") ? (
                <li
                  key={item.viewid}
                  onClick={() => handleItemClick(item)}
                  className="bg-white shadow  cursor-pointer overflow-hidden rounded-xs mx-2 px-2 flex items-center text-xs"
                >
                  <span className="w-full  flex items-center">
                    {item.title.value}
                    {loadingItemDetails == item.viewId && (
                      <span className="ml-2 -mb-1">
                        <Spin size="small" />
                      </span>
                    )}
                  </span>
                </li>
              ) : null
            ) : null
          )}
        </ul>
      </div>
    </>
  );
}

function CalendarLayout({ selectedPage }) {
  const [open, setOpen] = useState(false);
  const [openedDate, setOpenedDate] = useState(null);
  const [lists, setLists] = useState([]);
  const [currentlySelectedList, setCurrentlySelectedList] = useState({});

  useEffect(() => {
    fetchAllLists();
  }, []);

  const fetchAllLists = async () => {
    const res = await getAllViewsByUserIdAndViewType(
      JSON.parse(localStorage.getItem("user")).sub,
      selectedPage.pageId
    );

    console.log(res);

    setLists(res?.data?.data.views);
  };

  return (
    <>
      <NewItemModal
        lists={lists}
        open={open}
        setOpen={setOpen}
        currentlySelectedList={currentlySelectedList}
        setLists={() => console.log("sdfsdf")}
        selectedPage={selectedPage}
        fetchAllLists={fetchAllLists}
        openedDate={openedDate}
      />
      {/* <Modal
        title=""
        visible={open}
        width={1000}
        onOk={() => {
          // setDropdownVisible(false);
          // setDropdownNumber(null);
          setOpen(false);
        }}
        onCancel={() => {
          // setDropdownNumber(null);
          // setDropdownVisible(false);
          setOpen(false);
        }}
        footer={[]}
      >
        <input
          type="text"
          placeholder="Untitled"
          className="text-4xl border-0 outline-none font-bold tracking-wide listName my-3 ml-2"
          name="tableName"
        />
      </Modal> */}
      <Calendar
        dateFullCellRender={(date) =>
          dateFullCellRender(
            date,
            setOpen,
            lists,
            setCurrentlySelectedList,
            setOpenedDate
          )
        }
      />
    </>
  );
}

export default CalendarLayout;
