import React from "react";

interface Props {}

function ProgressBar(props: Props) {
  const {
    text,
    backGroundColor,
    fillColor,
    progress,
    makeTransparent,
    extraBadgeClasses,
  } = props;

  return (
    <div className="relative w-100 pt-1">
      <span
        style={
          makeTransparent
            ? {
                border: "1px solid " + fillColor,
                backgroundColor: "transparent",
                color: fillColor,
              }
            : {}
        }
        className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full mb-3  ${extraBadgeClasses}`}
      >
        {text}
      </span>
      <span className="ml-1" />

      <span
        className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full  mb-3  ${extraBadgeClasses}`}
      >
        {Math.round(progress) || 0}%
      </span>

      <div
        style={{ backgroundColor: backGroundColor }}
        className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-pink-200"
      >
        <div
          style={{ width: `${progress}%`, backgroundColor: fillColor }}
          className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-pink-500"
        ></div>
      </div>
    </div>
  );
}

export default ProgressBar;
