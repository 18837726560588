import {
  ADD_LANE,
  ADD_TASK,
  DELETE_LANE,
  DELETE_TASK,
  EDIT_LANE,
  EDIT_TASK,
  GET_ALL_TASKS,
  SET_LANES,
  SET_TASKS,
} from "../actionTypes";

const initialState = {
  lanes: [
    {
      value: 1,
      name: "No Tags",
      show: false,
    },
  ],

  tasks: [],
};

export default function notionCloneReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_LANE:
      return {
        ...state,
        lanes: [...state.lanes, action.payload],
      };

    case DELETE_LANE:
      const newLanes = state.lanes.filter(
        (x) => x.value !== action.payload.value
      );

      return {
        ...state,
        lanes: newLanes,
      };

    case EDIT_LANE:
      let stateCopy = JSON.parse(JSON.stringify(state));

      let laneIdx = stateCopy.lanes.findIndex(
        (x) => x.value == action.payload.lane.value
      );

      stateCopy.lanes[laneIdx] = {
        ...stateCopy.lanes[laneIdx],
        name: action.payload.newName,
      };

      return {
        ...stateCopy,
      };

    case SET_LANES:
      const notagsLane = state.lanes.filter((x) => x.show == false);
      return {
        ...state,
        lanes: [...action.payload],
      };

    case ADD_TASK:
      return {
        ...state,
        lanes: [...state.lanes],
        tasks: [...state.tasks, action.payload],
      };

    case DELETE_TASK:
      const newTasks = state.tasks.filter((x) => x.id !== action.payload.id);
      return {
        ...state,
        lanes: [...state.lanes],
        tasks: [...newTasks],
      };

    case EDIT_TASK:
      return {
        ...state,
        lanes: [...state.lanes],
        tasks: [...newTasks],
      };

    case SET_TASKS:
      return {
        ...state,
        tasks: action.payload,
      };
    default:
      return state;
  }
}
