import React, { useEffect, useState } from "react";
import { IoMdHome } from "react-icons/io";
import { RiMoneyDollarBoxFill } from "react-icons/ri";
import "./Sidebar.css";
import { Link, useParams, useLocation } from "react-router-dom";

function Sidebar() {
  const loc = useLocation();

  const { pathname } = loc;

  return (
    <div className="w-20 sidebar  top-0   h-screen p-1">
      <div className="w-full text-center">
        <div className="text-center flex justify-center ">
          <Link to="/dashboard">
            <IoMdHome
              size="25px"
              color={
                pathname == "/dashboard" ? "#fff" : "rgba(255, 255, 255, 0.6)"
              }
            ></IoMdHome>
          </Link>
        </div>

        <div className="text-center flex justify-center mt-7">
          <Link to="/utilities">
            <RiMoneyDollarBoxFill
              color={
                pathname == "/utilities" ? "#fff" : "rgba(255, 255, 255, 0.6)"
              }
              size="25px"
            ></RiMoneyDollarBoxFill>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
