import shortid from "shortid";
const splitRegEx = /[ .:;?!~,`"&|()<>{}\[\]\r\n/\\]+/;
export const sitesByGroup = (
  utilitiesSite,
  dormSite,
  auxSite,
  athleticSite,
  acadSite
) => {
  let string = "graph LR;\n";
  const allSiteGroups = {
    utilitiesSite,
    dormSite,
    auxSite,
    athleticSite,
    acadSite,
  };

  for (let i in allSiteGroups) {
    const values = allSiteGroups[i];
    for (let j in values) {
      if (values[j] && values[j].navName) {
        string += `${i.toUpperCase()} --->  ${values[j].navName
          .split(splitRegEx)
          .join("_")};\n`;
      }
    }
  }

  return string;
};

export const equipmentsByFloor = (allSites) => {
  let str = "graph LR;";

  let eqCounts = {};

  allSites.forEach((site, i) => {
    if (site.floors && site.navName) {
      site.floors.forEach((floor, j) => {
        str += `${site.id}[${site?.navName
          ?.split(splitRegEx)
          .join("_")}] ---> ${floor.floor_id}[${floor.dis
          .split(splitRegEx)
          .join("_")}] ; \n`;
        if (floor.equips) {
          eqCounts = {};
          floor.equips.forEach((equip, k) => {
            if (Object.keys(equip.source).includes("vav")) {
              eqCounts["vav"] = {
                count: eqCounts["vav"]?.count ? eqCounts["vav"]?.count + 1 : 1,
                id: equip.id,
              };
            }
            if (Object.keys(equip.source).includes("ahu")) {
              eqCounts["ahu"] = {
                count: eqCounts["ahu"]?.count ? eqCounts["ahu"]?.count + 1 : 1,
                id: equip.id,
              };
            }
            if (Object.keys(equip.source).includes("fcu")) {
              eqCounts["fcu"] = {
                count: eqCounts["fcu"]?.count ? eqCounts["fcu"]?.count + 1 : 1,
                id: equip.id,
              };
            }
            if (Object.keys(equip.source).includes("chiller")) {
              eqCounts["chiller"] = {
                count: eqCounts["chiller"]?.count
                  ? eqCounts["chiller"]?.count + 1
                  : 1,
                id: equip.id,
              };
            }
            if (Object.keys(equip.source).includes("heatExchanger")) {
              eqCounts["heatExchanger"] = {
                count: eqCounts["heatExchanger"]?.count
                  ? eqCounts["heatExchanger"]?.count + 1
                  : 1,
                id: equip.id,
              };
            }
          });

          if (eqCounts.vav) {
            str += `${floor.floor_id}[${floor.dis
              .split(splitRegEx)
              .join("_")}] ---> ${eqCounts.vav.id}[VAV:${eqCounts.vav.count}];`;
          }

          if (eqCounts.ahu) {
            str += `${floor.floor_id}[${floor.dis
              .split(splitRegEx)
              .join("_")}] ---> ${eqCounts.ahu.id}[AHU:${eqCounts.ahu.count}];`;
          }

          if (eqCounts.fcu) {
            str += ` ${floor.floor_id}[${floor.dis
              .split(splitRegEx)
              .join("_")}] ---> ${eqCounts.fcu.id}[FCU:${eqCounts.fcu.count}];`;
          }

          if (eqCounts.chiller) {
            str += ` ${floor.floor_id}[${floor.dis
              .split(splitRegEx)
              .join("_")}] ---> ${eqCounts.chiller.id}[CHILLER:${
              eqCounts.chiller.count
            }];`;
          }

          if (eqCounts.heatExchanger) {
            str += ` ${floor.floor_id}[${floor.dis
              .split(splitRegEx)
              .join("_")}] ---> ${eqCounts.heatExchanger.id}[Boiler:${
              eqCounts.heatExchanger.count
            }];`;
          }
        }
      });
    }
  });

  return str.trim();
};

export const eqToeqRelation = (equips) => {
  let str = `graph LR; \n`;
  equips.forEach((equip) => {
    // if (Object.keys(equip.source).includes("vavRef")) {
    //   str += `${equip.id}[${equip.navName}] ---> ${
    //     equip.source.id
    //   }[${equip.source.vavRef.substring(
    //     equip.source.vavRef.indexOf(" ") + 1
    //   )}]; \n`;
    // }

    // if (Object.keys(equip.source).includes("ahuRef")) {
    //   str += `${equip.id}[${equip.navName}] ---> ${
    //     equip.source.id
    //   }[${equip.source.ahuRef.substring(
    //     equip.source.ahuRef.indexOf(" ") + 1
    //   )}]; \n`;
    // }

    // if (Object.keys(equip.source).includes("fcuRef")) {
    //   str += `${equip.id}[${equip.navName}] ---> ${
    //     equip.source.id
    //   }[${equip.source.fcuRef.substring(
    //     equip.source.fcuRef.indexOf(" ") + 1
    //   )}]; \n`;
    // }
    // if (Object.keys(equip.source).includes("boilerPlantRef")) {
    //   str += `${equip.id}[${equip.navName}] ---> ${
    //     equip.source.id
    //   }[${equip.source.boilerPlantRef.substring(
    //     equip.source.boilerPlantRef.indexOf(" ") + 1
    //   )}]; \n`;
    // }
    // if (Object.keys(equip.source).includes("chillerPlantRef")) {
    //   str += `${equip.id}[${equip.navName}] ---> ${
    //     equip.source.id
    //   }[${equip.source.chillerPlantRef.substring(
    //     equip.source.chillerPlantRef.indexOf(" ") + 1
    //   )}]; \n`;
    // }

    if (Object.keys(equip.source).includes("vavRef")) {
      let receiver = equip.source.vavRef
        .substring(equip.source.vavRef.indexOf(" ") + 1)
        .split(" ")
        .join("_");

      str += `${equip.navName.split(" ").join("_")} ---> ${receiver}; \n`;
    }

    if (Object.keys(equip.source).includes("ahuRef")) {
      let receiver = equip.source.ahuRef
        .substring(equip.source.ahuRef.indexOf(" ") + 1)
        .split(" ")
        .join("_");

      str += `${equip.navName.split(" ").join("_")} ---> ${receiver}; \n`;
    }

    if (Object.keys(equip.source).includes("fcuRef")) {
      let receiver = equip.source.fcuRef
        .substring(equip.source.fcuRef.indexOf(" ") + 1)
        .split(" ")
        .join("_");

      str += `${equip.navName.split(" ").join("_")} ---> ${receiver}; \n`;
    }
    if (Object.keys(equip.source).includes("boilerPlantRef")) {
      let receiver = equip.source.boilerPlantRef
        .substring(equip.source.boilerPlantRef.indexOf(" ") + 1)
        .split(" ")
        .join("_");

      str += `${equip.navName.split(" ").join("_")} ---> ${receiver}; \n`;
    }
    if (Object.keys(equip.source).includes("chillerPlantRef")) {
      let receiver = equip.source.chillerPlantRef
        .substring(equip.source.chillerPlantRef.indexOf(" ") + 1)
        .split(" ")
        .join("_");

      str += `${equip.navName.split(" ").join("_")} ---> ${receiver}; \n`;
    }
  });
  console.log(str);
};
