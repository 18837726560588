import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Chart } from "chart.js";
import { randomNumber } from "../../../helpers/getRandomNumber";
import { options } from "../displayCharOptions";

function SteamToday({ activeSlide, sites, index }) {
  const [steamChartInstance, setSteamChartInstance] = useState({});
  useEffect(() => {
    if (sites) {
      const rand10Sites = sites
        .map((x) => ({ x, r: Math.random() }))
        .sort((a, b) => a.r - b.r)
        .map((a) => a.x)
        .slice(0, 10);
      plotSteamChart(rand10Sites);
    }
  }, [sites]);

  const plotSteamChart = (sites) => {
    try {
      if (Object.keys(steamChartInstance).length) {
        steamChartInstance.destroy();
      }

      const ctxDOM = document.querySelector("#steamChart");
      const data = {
        labels: sites.map((x) => (x.navName ? x.navName : null)),
        datasets: [
          {
            data: sites.map((x) => randomNumber(0, 500)),
            backgroundColor: "#ffba69",
            borderWidth: 1,
          },
        ],
      };

      var myChart = new Chart(ctxDOM, {
        type: "bar",
        data: data,
        options: options,
      });

      setSteamChartInstance(myChart);
    } catch (err) {
      if (Object.keys(steamChartInstance).length) {
        steamChartInstance.destroy();
      }
    }
  };

  return (
    <motion.div
      animate={activeSlide == index ? { opacity: 1, scaleY: 1 } : {}}
      initial={{ opacity: 0, scaleY: 0 }}
      transition={{ duration: 1 }}
      className="card p-3"
      style={{ height: "50vh", originY: 1 }}
    >
      <canvas className="chartcanvas" id="steamChart"></canvas>
    </motion.div>
  );
}

export default SteamToday;
