import React from "react";
import AnimatedText from "../AnimatedText";

function DisplaySlideHeading({ headingText, type, activeSlide, index }) {
  return (
    <div className="text-center slide1-header mb-2">
      <AnimatedText
        animate={activeSlide == index}
        activeSlide={activeSlide}
        text={headingText}
        type={type}
      />
    </div>
  );
}

export default DisplaySlideHeading;
