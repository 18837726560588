import React from "react";
import { FcLeftDown, FcRightUp } from "react-icons/fc";
function RightSidePanel() {
  return (
    <div className="h-full bg-white  overflow-y-auto">
      <div className="pb-16 space-y-6">
        <div>
          <div className=" flex items-start justify-between">
            <div>
              <h2 className="text-2xl font-medium text-gray-900">
                Connections
              </h2>
            </div>
          </div>
        </div>

        <div className="shadow p-5">
          <h3 className="font-medium text-gray-900">Belongs to group</h3>
          <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
            <div className="py-3 flex items-center justify-between text-sm font-medium">
              <dt className="text-gray-500">Campus</dt>
              <dd className="text-gray-900 ">Richmond Campus</dd>
            </div>

            <div className="py-3 flex items-center  justify-between text-sm font-medium">
              <dt className="text-gray-500">Dept</dt>
              <dd className="text-gray-900">Science Department</dd>
            </div>

            <div className="py-3 flex items-center  justify-between text-sm font-medium">
              <dt className="text-gray-500">Site Type</dt>
              <dd className="text-gray-900 ">Academic</dd>
            </div>
          </dl>
        </div>

        <div className="shadow p-5">
          <h3 className="font-medium text-gray-900 flex items-center">
            Sends <div className="ml-2" /> <FcRightUp />
          </h3>
          <dl className="mt-2 border-t border-b border-gray-100 divide-y divide-gray-200">
            <div className="py-3  text-sm font-medium">
              1. <span className="text-red-500">Hot water</span> to{" "}
              <span className="text-blue-500">5</span> other buildings
            </div>
          </dl>
        </div>

        <div className="shadow p-5">
          <h3 className="font-medium text-gray-900 flex items-center">
            Receives <div className="ml-2" /> <FcLeftDown />
          </h3>
          <dl className="mt-2 border-t border-b border-gray-100 divide-y divide-gray-200">
            <div className="py-3  text-sm font-medium">
              1. <span className="text-blue-300">Chilled water</span> from
              central plant
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}

export default RightSidePanel;
