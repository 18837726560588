import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
// import { CheckIcon } from "@heroicons/react/outline";
import BraftEditor from "braft-editor";
import "braft-editor/dist/index.css";
import moment from "moment";
import {
  Menu,
  Dropdown,
  Popover,
  notification,
  DatePicker,
  Select,
} from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { propertyTypes } from "../configs";
import Modal from "antd/lib/modal/Modal";
import InputRenderer from "../InputRenderer";
import { insertIntoUserView, insertIntoView } from "../../API/API";
import shortid from "shortid";
import MenuItem from "antd/lib/menu/MenuItem";
import { IoMdTrash } from "react-icons/io";
import { connect } from "react-redux";

function NewItemModal({
  lists,
  open,
  setOpen,
  currentlySelectedList,
  setLists,
  setCurrentlySelectedList,
  selectedPage,
  lanes,
  fetchAllLists,
  openedDate,
}) {
  let editing = false;

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownNumber, setDropdownNumber] = useState(null);
  const [editorState, setEditorState] = useState(
    BraftEditor.createEditorState("<p>Hello <b>World!</b></p>")
  );

  const [somethingChanged, setSomethingChanged] = useState(false);

  if (Object.keys(currentlySelectedList).length) {
    editing = true;
  }

  const [modalState, setModalState] = useState({
    id: currentlySelectedList?.id,
    title: currentlySelectedList?.title?.value,
    createdAt: currentlySelectedList?.createdAt,
    tags: currentlySelectedList?.tags,
    properties: currentlySelectedList?.properties,
    date: currentlySelectedList?.date,
    status: currentlySelectedList?.status,
  });

  const addNewProperty = () => {
    const propertiesCopy = JSON.parse(
      JSON.stringify(modalState?.properties || [])
    );

    propertiesCopy.push({
      name: `Property ${propertiesCopy.length + 1}`,
      typeLabel: "Text",
      type: "text",
      value: "",
    });

    setModalState({ ...modalState, properties: propertiesCopy });
  };

  const handleOnPropertyTypeChange = (type, index) => {
    const propertiesCopy = JSON.parse(
      JSON.stringify(modalState?.properties || [])
    );

    propertiesCopy[index].type = type.type;
    propertiesCopy[index].typeLabel = type.name;
    propertiesCopy[index].icon = type.icon;

    setModalState({
      ...modalState,

      properties: propertiesCopy,
    });
  };

  const CustomMenu = ({ property, index }) => (
    <Menu triggerSubMenuAction="click" className="m-0 shadow">
      <Menu.Item className="property-name-input-container">
        <input
          type="text"
          placeholder="Property Name"
          className="p-1 property-name-input"
          onChange={(e) =>
            handleOnInputChange(property.name, e.target.value, property, index)
          }
          name="property-name"
          value={property.name}
          autoFocus
        />
      </Menu.Item>
      <Menu.ItemGroup title="PROPERTY TYPE" className="text-xs type-selector">
        <SubMenu title={property.typeLabel}>
          {propertyTypes.map((type) => (
            <Menu.Item
              style={{
                paddingLeft: "5px",
                display: "flex",
              }}
              className="text-xs text-left "
              key={type.name}
              onClick={() => {
                setDropdownNumber(null);
                handleOnPropertyTypeChange(type, index);
              }}
            >
              <div className="flex py-1 items-center">
                <div className="text-lg">{type.icon}</div>
                <div className="ml-2">{type.name}</div>
              </div>
            </Menu.Item>
          ))}
        </SubMenu>

        <MenuItem className="deleteMenu">
          <div
            onClick={() => removeProperty(index)}
            className="flex items-center"
          >
            <span>
              <IoMdTrash />
            </span>
            <span className="ml-2">Delete Property</span>
          </div>
        </MenuItem>
      </Menu.ItemGroup>
    </Menu>
  );

  const handleOnInputChange = (
    name,
    value,
    property,
    index,
    fieldType,
    options
  ) => {
    if (editing) {
      setSomethingChanged(true);
    }

    if (property && !fieldType) {
      const propertiesCopy = JSON.parse(
        JSON.stringify(modalState.properties || [])
      );

      // PROPERRT NAME CHANGE OR Property Value Change

      if (property == "value") {
        propertiesCopy[index].value = value;
      } else {
        propertiesCopy[index].name = value;
      }

      // If its a select field then also store options
      if (name == "select") {
        propertiesCopy[index].options = options;
      }

      setModalState({
        ...modalState,
        properties: propertiesCopy,
      });
    } else if (fieldType) {
      setModalState({
        ...modalState,
        [name]: {
          value,
          type: fieldType,
          name,
        },
      });
    }
  };

  useEffect(() => {
    setSomethingChanged(false);
  }, [currentlySelectedList]);

  useEffect(() => {
    if (open == true && editing == false) {
      setModalState({
        ...modalState,
        createdAt: moment().format("DD-MM-YYYY hh:mm a"),
        id: shortid.generate(),
      });
    }

    if (open == false && editing == true) {
      handleViewInsert();
    }

    if (open == false && modalState?.title?.value.length && editing == false) {
      handleViewInsert();
    }

    if (open == true && editing == true) {
      return setModalState(currentlySelectedList);
    }
  }, [open]);

  const handleViewInsert = async () => {
    if (!somethingChanged && editing) return;
    console.log(modalState?.properties);
    const filteredPorperties = modalState?.properties?.map(
      ({ typeLabel, type, value, name, options }) => {
        return { typeLabel, type, value, name, options };
      }
    );
    modalState.properties = filteredPorperties;

    let res = await insertIntoView(
      modalState.id || modalState.viewId,
      modalState.properties,
      modalState.createdAt,
      modalState.tags,
      modalState.title,
      JSON.parse(localStorage.getItem("user")).sub,
      selectedPage.pageId,
      modalState.date || {
        type: "date",
        name: "Date",
        value: openedDate,
      },
      modalState.status,
      lanes
    );

    if (res?.data?.data?.insert_views?.affected_rows) {
      setModalState({});
      notification.success({
        message: editing ? " Changes Saved Successfully" : "Successfully Saved",
      });
      fetchAllLists();
    } else {
      setModalState({});
      notification.error({
        message: "Something went wrong: Please try again",
      });
    }
  };

  const removeProperty = (index) => {
    setSomethingChanged(true);
    const modalStateProperties = JSON.parse(
      JSON.stringify(modalState.properties || [])
    );
    modalStateProperties.splice(index, 1);

    setModalState({
      ...modalState,
      properties: [...modalStateProperties],
    });
  };

  const lanesToDisplay = currentlySelectedList.lanes
    ? lanes.length > currentlySelectedList?.lanes?.length
      ? lanes
      : currentlySelectedList?.lanes || []
    : lanes;

  const lanesInitialValue = lanesToDisplay.filter(
    (x) => x.value == modalState?.status?.value
  ).length
    ? lanesToDisplay.filter((x) => x.value == modalState?.status?.value)[0].name
    : null;

  return (
    <>
      <Modal
        title=""
        visible={open}
        width={1000}
        onOk={() => {
          setDropdownVisible(false);
          setDropdownNumber(null);
          setOpen(false);
        }}
        onCancel={() => {
          setDropdownNumber(null);
          setDropdownVisible(false);
          setOpen(false);
        }}
        footer={[]}
      >
        <div className="overflow-y-scroll">
          <div className="flex">
            <button
              type="button"
              className="p-1 border rounded rounded-lg px-3 mt-3 text-xs"
              //   onClick={handleListAdd}
            >
              Add Icon
            </button>
            <button
              type="button"
              className="p-1 border rounded rounded-lg px-3 mt-3 text-xs ml-2"
              //   onClick={handleListAdd}
            >
              Add Cover
            </button>
          </div>
          <div className="my-4">
            <input
              type="text"
              placeholder="Untitled"
              onChange={(e) =>
                handleOnInputChange(
                  e.target.name,
                  e.target.value,
                  null,
                  null,
                  "text"
                )
              }
              value={modalState?.title?.value || ""}
              className="text-4xl border-0 outline-none font-bold tracking-wide listName my-3 ml-2"
              name="title"
            />
            <ul className="properties text-sm">
              <li className="property my-2 flex items-center ">
                <div className="editable">Date: </div>
                <div>
                  <DatePicker
                    defaultValue={
                      openedDate
                        ? openedDate
                        : modalState?.date
                        ? moment(modalState?.date.value)
                        : null
                    }
                    value={
                      openedDate
                        ? openedDate
                        : modalState?.date
                        ? moment(modalState?.date.value)
                        : null
                    }
                    name="date"
                    onChange={(e) =>
                      handleOnInputChange("date", e, null, null, "date")
                    }
                  />
                </div>
              </li>

              <li className="property my-2 flex items-center ">
                <div className="editable">Tags </div>

                {modalState?.tags?.length && editing ? (
                  <div>
                    {modalState?.tags?.split(",").map((x) => (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800 mx-1">
                        {x}
                      </span>
                    ))}
                  </div>
                ) : (
                  <div className="editable">
                    <input
                      type="text"
                      placeholder="Empty"
                      className="w-full h-full bg-transparent block p-1 outline-none text-black"
                      name="tags"
                      onChange={(e) =>
                        handleOnInputChange(
                          e.target.name,
                          e.target.value,
                          null,
                          null,
                          "text"
                        )
                      }
                      value={modalState?.tags?.value.toString() || ""}
                    />
                  </div>
                )}
              </li>

              <li className="property my-2 flex items-center ">
                <div className="editable">Status </div>

                <div className="editable">
                  <Select
                    style={{ width: 240 }}
                    placeholder="Select"
                    name="select"
                    onChange={(e) =>
                      handleOnInputChange("status", e, null, null, "select")
                    }
                    value={lanesInitialValue}
                  >
                    {lanesToDisplay.length
                      ? lanesToDisplay
                          .filter((x) => x.show == true)
                          .map((lane) => (
                            <Option key={lane.value}>{lane.name}</Option>
                          ))
                      : null}
                  </Select>
                </div>
              </li>

              {modalState?.properties?.length
                ? modalState?.properties?.map((property, i) => (
                    <li className="property my-2 flex items-center transition-all">
                      <div
                        className="editable  text-left"
                        style={{
                          padding: "3px",
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                      >
                        <Dropdown
                          overlayStyle={{ position: "fixed" }}
                          trigger={["click"]}
                          visible={dropdownVisible && dropdownNumber == i}
                          onVisibleChange={(x) => {
                            setDropdownNumber(i);
                            setDropdownVisible(x);
                          }}
                          overlay={<CustomMenu property={property} index={i} />}
                        >
                          <div className="ant-dropdown-link editable py-2 flex items-center">
                            <div>
                              {propertyTypes.filter(
                                (x) => x.type == property.type
                              )[0]?.icon || propertyTypes[0].icon}
                            </div>
                            <div className="ml-1"> {property.name}</div>
                          </div>
                        </Dropdown>
                      </div>
                      <div className="editable">
                        <InputRenderer
                          propertyIndex={i}
                          handleOnInputChange={handleOnInputChange}
                          type={property.type}
                          value={property.value}
                        />
                      </div>
                    </li>
                  ))
                : null}

              <button
                type="button"
                onClick={() => addNewProperty()}
                className="p-1 border rounded rounded-lg px-3 mt-3 text-xs ml-2"
              >
                + New Property
              </button>
            </ul>

            <div className=" mb-10" />
            <div className="h-24">
              <BraftEditor language="en" value={editorState} />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    lanes: state.notion.lanes,
  };
};

export default connect(mapStateToProps)(NewItemModal);
