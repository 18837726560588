import { Dropdown, Menu } from "antd";
import MenuItem from "antd/lib/menu/MenuItem";
import SubMenu from "antd/lib/menu/SubMenu";
import { React, useState } from "react";
import { IoMdTrash } from "react-icons/io";
import { MdClose } from "react-icons/md";
import { propertyTypes } from "../../configs";
import InputRenderer from "../../InputRenderer";

const CustomMenu = ({
  index,

  handleColumnTypeChange,
}) => (
  <Menu
    triggerSubMenuAction="click"
    className="m-0   z-10 top-0 left-0 bg-transparent"
  >
    <Menu.ItemGroup
      title="PROPERTY TYPE"
      className="text-xs type-selector table-header-options"
    >
      <SubMenu title="skndfjksdn">
        {propertyTypes.map((type) => (
          <Menu.Item
            style={{
              paddingLeft: "5px",
              display: "flex",
            }}
            className="text-xs text-left"
            key={type.name}
            onClick={() => handleColumnTypeChange(index, type.type)}
          >
            <div className="flex py-1 items-center">
              <div className="text-lg">{type.icon}</div>
              <div className="ml-2">{type.name}</div>
            </div>
          </Menu.Item>
        ))}
      </SubMenu>
    </Menu.ItemGroup>
  </Menu>
);

export const tableHead = (
  x,
  i,
  handleColumnDelete,
  handleColumsNameChange,
  handleColumnTypeChange
) => {
  return (
    <div className="flex  items-center">
      <div>
        <Dropdown
          overlayStyle={{ position: "fixed" }}
          trigger={["click"]}
          overlay={
            <CustomMenu
              index={i}
              handleColumnTypeChange={handleColumnTypeChange}
            />
          }
        >
          <div className="ant-dropdown-link editable py-2 flex items-center">
            <div className="flex items-center">
              {propertyTypes.filter((prop) => prop.type == x.type)[0]?.icon ||
                propertyTypes[0].icon}

              <input
                disabled={i == 0}
                autoFocus
                onChange={(e) => handleColumsNameChange(e, i, x)}
                type="text"
                value={x.name}
                className="bg-transparent p-1 outline-none ml-1"
              />
            </div>
          </div>
        </Dropdown>
      </div>

      {i !== 0 && (
        <div
          onClick={() => handleColumnDelete(i)}
          className="cursor-pointer close-btn ml-auto text-black"
        >
          <MdClose className="text-sm"></MdClose>
        </div>
      )}
    </div>
  );
};

export const tableBody = (
  x,
  i,
  handleRowInputChange,
  record,
  text,
  index,
  rows
) => {
  const keyName = x.id || x.name.split(" ").join("");
  return (
    <>
      {record[keyName] ? (
        <InputRenderer
          handleOnInputChange={(
            type,
            value,
            property,
            propertyIndex,
            fieldType,
            options
          ) => {
            handleRowInputChange(keyName, type, value, index, options);
          }}
          options={record[keyName].options}
          type={record[keyName].type}
          value={record[keyName].value}
        />
      ) : null}

      {/* <input
        onChange={(e) =>
          handleRowTextChange(e, i, Object.keys(record)[i], text, index)
        }
        value={rows[index][x]}
        placeholder="Enter Value"
        type="text"
        className="bg-transparent border p-1"
      /> */}
    </>
  );
};
