import axios from "axios";
import React, { useEffect, useState } from "react";
import { getStatus } from "../API/API";

function Status() {
  const [allPoints, setAllPoints] = useState([]);
  const [allPointAggregrates, setAllPointsAggregrates] = useState([]);
  useEffect(() => {
    (async () => {
      const status = await getStatus();
      const statusResp = status.data.data;
      setAllPoints(Object.keys(statusResp));
      setAllPointsAggregrates(Object.values(statusResp));
    })();
  }, []);

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Point Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Query
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Count
                  </th>
                </tr>
              </thead>
              <tbody>
                {allPoints.length
                  ? allPoints?.map((point, i) => (
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {point}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {`
                             ${point}: point_aggregate(where: {navName: {_eq: "${point}"}, pointHistoryAggregates: {bucket_type: {_eq: "hour"}}}) {
                                aggregate {
                                  count
                                }
                              }
                            `}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {allPointAggregrates[i]?.aggregate.count}
                        </td>
                      </tr>
                    ))
                  : null}
                <tr>
                  <td className="px-6 text-lg py-4 whitespace-nowrap text-sm font-medium">
                    Total VAV points
                  </td>

                  <td align="right"></td>
                  <td className="px-6 text-lg py-4 whitespace-nowrap text-sm font-medium">
                    {allPointAggregrates.reduce(
                      (n, { aggregate }) =>
                        Math.round(
                          parseFloat(n) + parseFloat(aggregate?.count)
                        ),
                      0
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Status;
