import React, { useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import BoardContainer from "./BoardContainer";

function BoardLayout({ selectedPage }) {
  return (
    <DndProvider backend={HTML5Backend}>
      <BoardContainer selectedPage={selectedPage} />
    </DndProvider>
  );
}

export default BoardLayout;
