import {
  AiOutlineCalendar,
  AiOutlineEdit,
  AiOutlineNumber,
  AiOutlineTable,
  AiOutlineUnorderedList,
} from "react-icons/ai";
import { BiSelectMultiple } from "react-icons/bi";
import { BsFillPersonFill, BsKanban, BsLink, BsTextLeft } from "react-icons/bs";
import { CgAttachment, CgSelect } from "react-icons/cg";
import { FaPhoneAlt } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { MdDateRange, MdTimeline } from "react-icons/md";
import { RiCheckboxLine, RiGalleryFill } from "react-icons/ri";

export const dbOptions = [
  {
    name: "Table",
    value: "table",
    icon: <AiOutlineTable />,
  },
  {
    name: "Board",
    value: "board",
    icon: <BsKanban></BsKanban>,
  },
  {
    name: "List",
    value: "list",
    icon: <AiOutlineUnorderedList></AiOutlineUnorderedList>,
  },
  {
    name: "Calendar",
    value: "calendar",
    icon: <AiOutlineCalendar></AiOutlineCalendar>,
  },
  {
    name: "Gallery",
    value: "gallery",

    icon: <RiGalleryFill></RiGalleryFill>,
  },
  // {
  //   name: "Timeline",
  //   name: "imeline",
  //   icon: <MdTimeline></MdTimeline>,
  // },
];

export const tableData = [
  {
    key: "1",
    name: "Mike",
    age: 32,
    address: "10 Downing Street",
  },
];

export const propertyTypes = [
  {
    name: "Text",
    type: "text",
    icon: <BsTextLeft />,
  },
  { name: "Number", type: "number", icon: <AiOutlineNumber /> },
  { name: "Select", type: "select", icon: <CgSelect /> },
  { name: "Multi Select", type: "multiselect", icon: <BiSelectMultiple /> },
  { name: "Date", type: "date", icon: <MdDateRange /> },
  { name: "Person", type: "person", icon: <BsFillPersonFill /> },
  { name: "Files and Media", type: "file", icon: <CgAttachment /> },
  { name: "Checkbox", type: "checkbox", icon: <RiCheckboxLine /> },
  { name: "Link", type: "link", icon: <BsLink /> },
  { name: "Email", type: "email", icon: <HiOutlineMail /> },
  { name: "Phone", type: "phone", icon: <FaPhoneAlt /> },
];
