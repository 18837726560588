import React from "react";
import { MdEmail } from "react-icons/md";
import "./NuemorphBox.css";

function NeumorphBox({ icon, isInput, title, placeholder, type }) {
  return (
    <div className="neu-box flex items-center">
      <div className="neu-icon ml-5 flex items-center justify-center mr-10">
        <img src={icon} width="20px" />
        {/* <MdEmail size="20px" /> */}
      </div>
      <div className="relative m-0  h-full w-1/2  flex items-center">
        {/* sdfsd */}
        {isInput ? (
          <input type={type || "text"} placeholder={placeholder} />
        ) : (
          <p className="m-0">{title}</p>
        )}
      </div>
    </div>
  );
}

export default NeumorphBox;
