import { setDate } from 'date-fns';
import React ,{ createContext, useState} from 'react';
export const BuildingsContext =  createContext();

export const BuildingsProvider = (props)  => {
 

    const [allSites, setAllSites]  = useState([]);
    const [acadSites, setAcadSites] = useState([])
    const [utilitiesSites, setUtilitiesSites] = useState([])
    const [dormSites, setDormSites] = useState([])
    const [auxSites, setAuxSites] = useState([])
    const [athleticSites, setAthleticSites] = useState([])
    const [sitesToShow, setSitesToShow] = useState([])
    const [sitesToShowUnchnaged, setSitesToShowUnchanged]  = useState([])
    

    return (
            <BuildingsContext.Provider value={
                {   
                    all:  [allSites, setAllSites],
                    acad: [acadSites, setAcadSites],
                    utility: [utilitiesSites, setUtilitiesSites],
                    dorm: [dormSites, setDormSites],
                    aux: [auxSites, setAuxSites],
                    athletic: [athleticSites, setAthleticSites],
                    toShow: [sitesToShow, setSitesToShow],
                    toShowUnchanged: [sitesToShowUnchnaged, setSitesToShowUnchanged] 
                }
               
            }>
                {props.children}
            </BuildingsContext.Provider>
    )
}


