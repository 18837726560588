import React, { useEffect, useRef, useState } from "react";

import { GraphConfig, sample } from "./Config";
import { GraphView } from "react-digraph";
import { connect } from "react-redux";
import { Footer } from "antd/lib/layout/layout";
import { addEdge, addNode, rotate } from "./NodeFunctions";
import Toggle from "../../Resueables/UniversalSearch/Toggle/Toggle";
import { Switch } from "@headlessui/react";
import { graphqlSync } from "graphql";
import { handleMetricMultiSelect } from "../../redux/actions";

function ForcedGraph({
  universalSearchState,
  selectedSite,
  equipments,
  buildings,
  selectedMetrics,
  setSelectedMetric,
}) {
  const NODE_KEY = "id";
  let GraphViewRef = useRef();
  const [graphState, setGraphState] = useState({
    graph: { nodes: [], edges: [] },
  });
  const [selected, setSelected] = useState({});

  const [showFloors, setShowFloors] = useState(false);
  const [showEquipments, setShowEquipments] = useState(false);

  const nodes = graphState.graph.nodes;
  const edges = graphState.graph.edges;
  const NodeTypes = GraphConfig.NodeTypes;
  const NodeSubtypes = GraphConfig.NodeSubtypes;
  const EdgeTypes = GraphConfig.EdgeTypes;

  useEffect(() => {
    const graphStateCopy = JSON.parse(JSON.stringify(graphState));

    // if (Object.values(equipments?.count).length) {
    //   const totalEquips = Object.values(equipments?.count).reduce(
    //     (a, b) => a + b
    //   );
    // }

    graphStateCopy.graph.nodes.push({
      id: 1,
      for: "site",
      title: `${selectedSite.navName}`,
      x: 453,
      y: -545,
      type: "site",
    });

    graphStateCopy.graph.nodes.push({
      id: 2,
      for: "equipment",
      title: `${
        equipments.count[`${universalSearchState.meter.value}Count`]
      } ${universalSearchState.meter.value.toUpperCase()}`,
      x: 300,
      y: -400,
      type: "equipment",
    });

    graphStateCopy.graph.edges.push(addEdge(1, 2, graphStateCopy.graph.edges));
    setGraphState(graphStateCopy);

    return () => {
      setSelectedMetric("reset");
    };
  }, []);

  useEffect(() => {
    let x = 480;
    let y = -400;
    let j = 1;
    const graphStateCopy = JSON.parse(JSON.stringify(graphState));
    console.log(graphStateCopy.graph);
    if (!graphStateCopy.graph.nodes.length) return;

    console.log("GRAPH STATE COPY", graphStateCopy);

    graphStateCopy.graph.nodes = graphStateCopy.graph.nodes.filter(
      (x) => x.type != "equipment"
    );
    graphStateCopy.graph.edges = graphStateCopy.graph.edges.filter(
      (x) => x.type != "equipment"
    );

    selectedMetrics.map((metric, index) => {
      graphStateCopy.graph.nodes.push(
        addNode(
          "equipment",
          metric.value,
          `${
            equipments.count[`${metric.value}Count`]
          } ${metric.value.toUpperCase()}`,
          x,
          y
        )
      );

      graphStateCopy.graph.edges.push(
        addEdge(1, metric.value, graphStateCopy.graph.edges, "equipment")
      );

      const position = rotate(445, -300, x, y, 20);
      x = Math.round(position[0]);
      y = Math.round(position[1]);

      if (index > 0 && index % 8 === 0) {
        x = 200 - 120 * j;
        y = nodes[2].y + 30 * j;
        j = j + 1;
      }
    });

    setGraphState(graphStateCopy);
  }, [selectedMetrics]);

  // //This function can give you x,y cordinates of node when node is moved.s
  // const onMoveNodeHandler = (viewNode) => {
  //   // if (viewNode.for == "floorLabel") {
  //   //   handleShowFloors();
  //   // }
  //   // if (viewNode.title.includes(":")) {
  //   //   handleEquipShow(
  //   //     viewNode,
  //   //     viewNode.title.split(":")[0].toString().replace("Count", "")
  //   //   );
  //   // }
  // };

  // const handleEquipShow = (viewNode, whichEquip) => {
  //   const graphStateCopy = JSON.parse(JSON.stringify(graphState));

  //   let x = -50;
  //   let y = -211;
  //   let j = 1;

  //   equipments.equips[whichEquip].map((point, index) => {
  //     //make sure to you use the id's of respective site or floor or equipment instead of generating id's by count+1, It will help to identify
  //     // whether a node is site, floor or equipment.This way no additional field is required to determine node type(site, floor or equip)
  //     graphStateCopy.graph.nodes.push(
  //       addNode("equipAhu", point.id, point.navName, x, y)
  //     );

  //     graphStateCopy.graph.edges.push(
  //       addEdge(viewNode.id, point.id, graphStateCopy.graph.edges, "equipAhu")
  //     );
  //     //This function gives you x, y cordinates provided an angle. this stackoverflow link can give you more clarification
  //     //https://stackoverflow.com/questions/17410809/how-to-calculate-rotation-in-2d-in-javascript
  //     const position = rotate(445, -300, x, y, 20);
  //     x = Math.round(position[0]);
  //     y = Math.round(position[1]);
  //     //Below code is to change x and y cordinates so that nodes will not overlap and nodes get plotted one below the other not in circle
  //     //try to comment and uncomment this if condition, so that you get an idea.
  //     if (index > 0 && index % 8 === 0) {
  //       x = 200 - 120 * j;
  //       y = nodes[2].y + 30 * j;
  //       j = j + 1;
  //     }
  //   });

  //   setGraphState(graphStateCopy);
  // };

  // const handleEquipHide = (whichEquip) => {
  //   const graphStateCopy = JSON.parse(JSON.stringify(graphState));

  //   if (
  //     !graphStateCopy.graph.nodes.length ||
  //     !graphStateCopy.graph.edges.length
  //   )
  //     return;
  //   const { edges, nodes } = graphStateCopy.graph;

  //   const filteredEdges = edges.filter((edge) => edge.type !== whichEquip);
  //   const filteredNodes = nodes.filter((node) => node.type !== whichEquip);

  //   console.log(
  //     "FITERED NODES, AND EDGES",
  //     whichEquip,
  //     filteredNodes,
  //     filteredEdges
  //   );
  //   graphStateCopy.graph.nodes = filteredNodes;
  //   graphStateCopy.graph.edges = filteredEdges;

  //   setGraphState(graphStateCopy);
  // };

  // const handleShowFloors = () => {
  //   const graphStateCopy = JSON.parse(JSON.stringify(graphState));

  //   // const floorNode = graphStateCopy.graph.nodes.filter(
  //   //   (x) => x.for == "floorLabel"
  //   // );

  //   let x = -50;
  //   let y = -211;
  //   let j = 1;

  //   if (selectedSite.isAGroup) {
  //     // console.log(
  //     //   "BUIDLINGS",
  //     //   buildings,
  //     //   "seleced site",
  //     //   selectedSite,
  //     //   buildings[selectedSite.value == null ? ]
  //     // );

  //     buildings[
  //       selectedSite.value == null ? "allSites" : selectedSite.value
  //     ].map((site, index) => {
  //       graphStateCopy.graph.nodes.push(
  //         addNode("building", site.id, site.navName, x, y)
  //       );
  //       graphStateCopy.graph.edges.push(
  //         addEdge(
  //           graphStateCopy.graph.nodes[2].id,
  //           site.id,
  //           graphStateCopy.graph.edges,
  //           "building"
  //         )
  //       );
  //       //This function gives you x, y cordinates provided an angle. this stackoverflow link can give you more clarification
  //       //https://stackoverflow.com/questions/17410809/how-to-calculate-rotation-in-2d-in-javascript
  //       const position = rotate(445, -300, x, y, 20);
  //       x = Math.round(position[0]);
  //       y = Math.round(position[1]);
  //       //Below code is to change x and y cordinates so that nodes will not overlap and nodes get plotted one below the other not in circle
  //       //try to comment and uncomment this if condition, so that you get an idea.
  //       if (index > 0 && index % 8 === 0) {
  //         x = 200 - 120 * j;
  //         y = nodes[2].y + 30 * j;
  //         j = j + 1;
  //       }
  //     });
  //   } else {
  //     selectedSite.floors.map((floor, index) => {
  //       //make sure to you use the id's of respective site or floor or equipment instead of generating id's by count+1, It will help to identify
  //       // whether a node is site, floor or equipment.This way no additional field is required to determine node type(site, floor or equip)
  //       graphStateCopy.graph.nodes.push(
  //         addNode("floor", floor.floor_id, floor.dis, x, y)
  //       );
  //       graphStateCopy.graph.edges.push(
  //         addEdge(
  //           graphStateCopy.graph.nodes[2].id,
  //           floor.floor_id,
  //           graphStateCopy.graph.edges,
  //           "floor"
  //         )
  //       );
  //       //This function gives you x, y cordinates provided an angle. this stackoverflow link can give you more clarification
  //       //https://stackoverflow.com/questions/17410809/how-to-calculate-rotation-in-2d-in-javascript
  //       const position = rotate(445, -300, x, y, 20);
  //       x = Math.round(position[0]);
  //       y = Math.round(position[1]);
  //       //Below code is to change x and y cordinates so that nodes will not overlap and nodes get plotted one below the other not in circle
  //       //try to comment and uncomment this if condition, so that you get an idea.
  //       if (index > 0 && index % 8 === 0) {
  //         x = 200 - 120 * j;
  //         y = nodes[2].y + 30 * j;
  //         j = j + 1;
  //       }
  //     });
  //   }

  //   // selectedSite.floors.map((floor, index) => {
  //   //   //make sure to you use the id's of respective site or floor or equipment instead of generating id's by count+1, It will help to identify
  //   //   // whether a node is site, floor or equipment.This way no additional field is required to determine node type(site, floor or equip)
  //   //   graphStateCopy.graph.nodes.push(
  //   //     addNode("floor", floor.floor_id, floor.dis, x, y)
  //   //   );
  //   //   graphStateCopy.graph.edges.push(
  //   //     addEdge(
  //   //       graphStateCopy.graph.nodes[2].id,
  //   //       floor.floor_id,
  //   //       graphStateCopy.graph.edges,
  //   //       "floor"
  //   //     )
  //   //   );
  //   //   //This function gives you x, y cordinates provided an angle. this stackoverflow link can give you more clarification
  //   //   //https://stackoverflow.com/questions/17410809/how-to-calculate-rotation-in-2d-in-javascript
  //   //   const position = rotate(445, -300, x, y, 20);
  //   //   x = Math.round(position[0]);
  //   //   y = Math.round(position[1]);
  //   //   //Below code is to change x and y cordinates so that nodes will not overlap and nodes get plotted one below the other not in circle
  //   //   //try to comment and uncomment this if condition, so that you get an idea.
  //   //   if (index > 0 && index % 8 === 0) {
  //   //     x = 200 - 120 * j;
  //   //     y = nodes[2].y + 30 * j;
  //   //     j = j + 1;
  //   //   }
  //   // });

  //   setGraphState(graphStateCopy);
  // };

  // const handleHideFloors = () => {
  //   const graphStateCopy = JSON.parse(JSON.stringify(graphState));

  //   if (
  //     !graphStateCopy.graph.nodes.length ||
  //     !graphStateCopy.graph.edges.length
  //   )
  //     return;
  //   let { edges, nodes } = graphStateCopy.graph;

  //   const comapreWith = selectedSite.isAGroup ? "building" : "floor";

  //   const filteredEdges = edges.filter((edge) => {
  //     if (edge.type !== comapreWith) {
  //       return edge;
  //     }
  //   });
  //   const filteredNodes = nodes.filter((node) => {
  //     if (node.type !== comapreWith) {
  //       return node;
  //     }
  //   });

  //   graphStateCopy.graph.edges = filteredEdges;
  //   graphStateCopy.graph.nodes = filteredNodes;
  //   setGraphState(graphStateCopy);
  // };

  // useEffect(() => {
  //   if (showEquipments == true) {
  //     const viewNode = graphState.graph.nodes.filter((node) =>
  //       node.title.includes("ahu")
  //     );

  //     handleEquipShow(viewNode[0], "ahu");
  //   } else {
  //     const viewNode = graphState.graph.nodes.filter((node) =>
  //       node.title.includes("ahu")
  //     );

  //     handleEquipHide("equipAhu");
  //   }
  // }, [showEquipments]);

  // useEffect(() => {
  //   if (showFloors == true) {
  //     handleShowFloors();
  //   } else {
  //     handleHideFloors();
  //   }
  // }, [showFloors]);

  return (
    <>
      <div id="graph" style={{ width: "100%", height: "600px" }}>
        <GraphView
          ref={GraphViewRef}
          nodeKey={NODE_KEY}
          nodes={nodes}
          edges={edges}
          selected={selected}
          nodeTypes={NodeTypes}
          nodeSubtypes={NodeSubtypes}
          edgeTypes={EdgeTypes}
          //   onUpdateNode={updateNode}
          allowMultiselect={true} // true by default, set to false to disable multi select.
          // onUpdateNode={onMoveNodeHandler}
          // onSelect={this.onSelect}
          // onCreateNode={this.onCreateNode}
          // onUpdateNode={this.onUpdateNode}
          // onDeleteNode={this.onDeleteNode}
          // onCreateEdge={this.onCreateEdge}
          // onSwapEdge={this.onSwapEdge}
          // onDeleteEdge={this.onDeleteEdge}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    universalSearchState: state.search.universalSearchState,
    selectedSite: state.buildings.selectedSite,
    equipments: state.buildings.equipmentCounts,
    buildings: state.buildings,
    selectedMetrics: state.search.selectedMetrics,
  };
};

const mapDispatchToProps = {
  setSelectedMetric: handleMetricMultiSelect,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForcedGraph);
