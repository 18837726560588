import { motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import { getKwh } from "../../API/API";
import GoogleMapReact from "google-map-react";
import { FaMapMarkerAlt } from "react-icons/fa";

function EfficentSitesByKwh({ activeSlide, index }) {
  const [kwhSorted, setKwhSorted] = useState([]);

  useEffect(() => {
    (async () => {
      const kwhRes = await getKwh(
        { startDate: "2022-03-02", endDate: "2022-03-02", breakdown: "day" },
        null,
        null
      );
      const kwhData = kwhRes.data.data.meterData
        .map((x) => {
          if (x.floor && x.floor.site && x.floor.site.navName) {
            if (
              x.points.length &&
              x.points[0].pointHistoryAggregates.length &&
              x.points[0].pointHistoryAggregates[0].sum !== 0
            ) {
              return {
                name: x.floor.site.navName,
                sum: Math.floor(x.points[0].pointHistoryAggregates[0].sum),
                max: Math.floor(x.points[0].pointHistoryAggregates[0].max),
                min: Math.floor(x.points[0].pointHistoryAggregates[0].min),
                avg: Math.floor(x.points[0].pointHistoryAggregates[0].avg),
                lat: x.floor.site.source.geoCoord
                  ? parseFloat(
                      x.floor.site.source.geoCoord
                        .replace("c:", "")
                        .split(",")[0]
                    )
                  : null,
                lon: x.floor.site.source.geoCoord
                  ? parseFloat(
                      x.floor.site.source.geoCoord
                        .replace("c:", "")
                        .split(",")[1]
                    )
                  : null,
              };
            }
          }
        })
        .filter((x) => (x ? true : false));
      kwhData.sort((a, b) => (a.sum > b.sum ? 1 : b.sum > a.sum ? -1 : 0));
      setKwhSorted(kwhData);
    })();
  }, []);

  return (
    <div className="card">
      <motion.div
        initial={{ opacity: 0 }}
        animate={activeSlide == index ? { opacity: 1 } : {}}
        transition={{ duration: 0.8 }}
        className="min-kwhs   w-full flex"
      >
        <div className="min-kwhs-buildings">
          {kwhSorted &&
            kwhSorted.map((building, idx) => {
              if (idx > 4) return;

              return (
                <div className="">
                  <div className="">
                    <h2>{building.name}</h2>
                  </div>
                  <div className="flex items-center">
                    {new Array(idx + 1).fill("").map((x) => (
                      <div>
                        <div className="kwh-visual" />
                      </div>
                    ))}
                    <span className="ml-2">({building.sum} Kwh)</span>
                  </div>
                </div>
              );
            })}
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={activeSlide == index ? { opacity: 1 } : {}}
        transition={{ duration: 0.8 }}
        style={{ height: "60vh" }}
        className="w-full border border-1  overflow-hidden"
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyBYSAlO-VejkTOjhXFrN6H11Crbo_-KWFY",
          }}
          center={{
            lat: 37.577192,
            lng: -77.538557,
          }}
          zoom={15}
          options={{
            disableDefaultUI: true,
          }}
          opacity={0.1}
        >
          {kwhSorted &&
            kwhSorted.map((x, idx) => {
              if (idx > 4) return;

              return (
                <div
                  lat={x.lat}
                  lng={x.lon}
                  className="marker-container cursor-pointer"
                >
                  <FaMapMarkerAlt color="#383874" size="32px" />
                </div>
              );
            })}
        </GoogleMapReact>
      </motion.div>
    </div>
  );
}

export default EfficentSitesByKwh;
