import React, { useEffect, Fragment, useState } from "react";
import {
  NotionRenderer,
  Equation,
  Code as NotionCode,
  CollectionRow,
  Collection,
} from "react-notion-x";
import axios from "axios";
import { Link, useParams, withRouter } from "react-router-dom";
import "react-notion-x/src/styles.css";
import "prismjs/themes/prism-tomorrow.css";
import "rc-dropdown/assets/index.css";
import "katex/dist/katex.min.css";
import Header from "../../Resueables/Header/Header";
import UniversalSearch from "../../Resueables/UniversalSearch";
import { Spin } from "antd";
import "./Notion.css";
import Loader from "../../Resueables/Loader/Loader";
import { connect } from "react-redux";
import { notionConfig, integrationKey } from "./notionConfig";
import mermaid from "mermaid";
import { getAllEquipCounts } from "../../helpers/getAllEquipCounts";
import { getEquipmentCount } from "../API/API";
import {
  eqToeqRelation,
  equipmentsByFloor,
  sitesByGroup,
} from "./buildRealtionshipDiagrams";
import Sync from "./Sync/Sync";

function Notion({
  selectedSite,
  dormSite,
  utilitiesSite,
  auxSite,
  athleticSite,
  acadSite,
  allSites,
}) {
  const params = useParams();
  const [blockMap, setBlockMap] = useState(null);
  const [collection, setCollection] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchPage(notionConfig[selectedSite.value || "allSites"]);
  }, []);

  useEffect(() => {
    // fetchPage(params.notionid);
    // fetchPage("f9b6271be4084d668633e361a8ff9fde");
  }, [params]);

  const fetchPage = async (notionid) => {
    setLoading(true);
    axios
      .post("https://hhfgqr.sse.codesandbox.io/", {
        notionid: notionid || notionConfig[selectedSite.value || "allSites"],
        // notionid: notionid,
      })
      .then((res) => {
        setBlockMap(res.data.page);
        setCollection(res.data.collection.recordMap);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const mapPageUrl = (params) => {
    return `/notionrender/${params}`;
  };

  const pageLink = (params) => {
    return (
      <Link className="text-black hover:text-black" to={`${params.href}`}>
        {params.children}
      </Link>
    );
  };

  const Code = (props) => {
    useEffect(() => {
      mermaid.contentLoaded();
    }, []);

    mermaid.initialize({
      startOnLoad: true,
      theme: "default",
      securityLevel: "loose",
      fontFamily: "Fira Code",
    });

    if (props.language == "Mermaid") {
      return (
        <div className="mermaid">{`
              ${props.code}          
        `}</div>
      );
    } else {
      return <NotionCode language={props.language} code={props.code} />;
    }
  };

  useEffect(() => {
    // equipmentsByFloor(allSites);
    // (async () => {
    //   const equipmentCountRes = await getEquipmentCount(null, null);
    //   eqToeqRelation(equipmentCountRes?.data?.data?.equip_aggregate.nodes);
    // })();
  }, []);

  return (
    <Fragment>
      <div className="flex">
        {/* <div>
          <Sidebar />
        </div> */}
        <div className="container mx-auto w-10/12">
          <Header forLogin={false} />

          <div className="mt-8" />

          <UniversalSearch
            auotSwitch={false}
            setZoom={() => console.log("ZOOM")}
            showTimePanel={true}
            setShowTimePanel={() => console.log("SET SHOW TIME PANEL")}
          />
          <div className="mt-8" />
          {params.notionid == "sync" ? (
            <Sync />
          ) : blockMap ? (
            <div className="relative overflow-hidden">
              {loading && (
                <div className="absolute w-full h-full notion-loader z-10 flex items-center justify-center ">
                  <Loader color="white" size="30px" />
                </div>
              )}

              <NotionRenderer
                mapPageUrl={mapPageUrl}
                components={{
                  equation: Equation,
                  pageLink: pageLink,
                  code: Code,
                  collection: Collection,
                  collectionRow: CollectionRow,
                }}
                header={false}
                fullPage
                recordMap={blockMap}
              />
            </div>
          ) : (
            <div className="relative text-center justify-center ">
              <Spin tip="Loading your Notion Page" />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    allSites: state.buildings.allSites,
    utilitiesSite: state.buildings.utilitiesSite,
    acadSite: state.buildings.acadSite,
    athleticSite: state.buildings.athleticSite,
    auxSite: state.buildings.auxSite,
    dormSite: state.buildings.dormSite,
    selectedSite: state.buildings.selectedSite,
  };
};

export default connect(mapStateToProps)(withRouter(Notion));
