import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { randomNumber } from "../../../helpers/getRandomNumber";
import { Chart } from "chart.js";
import { options } from "../displayCharOptions";

function CostCons({ activeSlide, index }) {
  const [costConsChart, setCostConsChart] = useState({});

  useEffect(() => {
    plotCostConsChart();
  }, []);

  const plotCostConsChart = () => {
    try {
      if (Object.keys(costConsChart).length) {
        costConsChart?.destroy();
      }
      const months = moment.months();

      const ctxDOM = document.querySelector("#contConsChart").getContext("2d");

      const data = {
        labels: months,
        datasets: [
          {
            data: months.map((x) => randomNumber(0, 500)),
            backgroundColor: "#ffba69",
            borderWidth: 1,
            type: "line",
            borderColor: "#64E480",
            pointRadius: 2,
            pointHoverRadius: 5,
            borderWidth: 3,
            lineTension: 0.5,
          },
          {
            data: months.map((x) => randomNumber(0, 500)),
            backgroundColor: "#ffba69",
            borderWidth: 1,
            type: "line",
            borderColor: "#0EA4E7",
            pointRadius: 2,
            pointHoverRadius: 5,
            borderWidth: 3,
            lineTension: 0.5,
            yAxisID: "rightYaxis",
          },
        ],
      };

      const optionsForCostCons = { ...options };

      optionsForCostCons["scales"]["rightYaxis"] = {
        position: "right",
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          precision: 1,
          beginAtZero: true,

          font: {
            style: "normal",
            weight: "100",
          },
        },
      };

      var myChart = new Chart(ctxDOM, {
        type: "bar",
        data: data,
        options: { ...optionsForCostCons },
      });

      setCostConsChart(myChart);
    } catch (err) {
      if (Object.keys(costConsChart).length) {
        costConsChart?.destroy();
      }
    }
  };

  return (
    <div className="card p-3">
      <motion.h2
        animate={activeSlide == index ? { x: "0", opacity: 1 } : {}}
        initial={{ x: "30px", opacity: 0 }}
        transition={{ duration: 1 }}
        className="flex justify-end items-center ml-auto"
      >
        <div
          className="w-3 h-3 rounded-full mr-2"
          style={{ backgroundColor: "#64E480" }}
        />{" "}
        Cost
        <div className="mx-3" />
        <div
          className="w-3 h-3 rounded-full mr-2"
          style={{ backgroundColor: "#0EA4E7" }}
        />{" "}
        Consumption
      </motion.h2>

      <motion.div
        animate={activeSlide == index ? { opacity: 1, scaleX: 1 } : {}}
        initial={{ opacity: 0, scaleX: 0 }}
        transition={{ duration: 1 }}
        style={{ height: "50vh", originX: 0 }}
      >
        <canvas id="contConsChart"></canvas>
      </motion.div>
    </div>
  );
}

export default CostCons;
