import React from "react";

function DisplayHeader() {
  return (
    <div className="flex items-center justify-between px-20">
      <div className="text-center mt-8">
        <img
          style={{ width: "100px", margin: "0 auto" }}
          src="https://upload.wikimedia.org/wikipedia/en/thumb/d/d0/UR_Shield.svg/1200px-UR_Shield.svg.png"
        />
      </div>

      <div>
        <img
          style={{ width: "200px", margin: "0 auto" }}
          src="https://richmond.novo.cloud/images/novo%20logo.svg"
        />
      </div>
    </div>
  );
}

export default DisplayHeader;
