import React, { useEffect, useState } from "react";
import { Chart } from "chart.js";
import { allCharts } from "../../helpers/equipmentCharts";
import "./MultipleCharts.css";
import Loader from "../../Resueables/Loader/Loader";
import { getRandomColor } from "../../helpers/getColors";
import MaxMinAvgFeed from "../../Resueables/MaxMinAvgFeed/MaxMinAvgFeed";
import moment from "moment";
import tConvert from "../../helpers/tConvert";
import { setErrorModal } from "../../redux/actions";
import { connect } from "react-redux";
import Modal from "../../Resueables/Modals/Modal";
import { BiErrorAlt } from "react-icons/bi";

function MultipleCharts({
  equipCharts,
  equipDataForADay,
  detailsPanelOpen,
  loadingSomething,
  errorModal,
  setErrorModal,
}) {
  const [charts, setCharts] = useState([]);
  const [chartInst, setChartInst] = useState({});

  useEffect(() => {
    try {
      if (!equipCharts.filter((x) => x.length).length && !loadingSomething) {
        return setErrorModal(true);
      }

      for (let i in equipCharts) {
        if (Chart.getChart(`equimentchart-${i}`) !== undefined) {
          Chart.getChart(`equimentchart-${i}`);
        }

        if (!equipCharts[i].length) continue;

        const ctx = document
          .getElementById(`equimentchart-${i}`)
          .getContext("2d");

        const gradient = ctx.createLinearGradient(0, 0, 0, 450);

        gradient.addColorStop(0, getRandomColor());
        gradient.addColorStop(0.5, "rgba(255, 255, 255, 0)");
        gradient.addColorStop(1, "rgba(255, 0, 0, 0)");

        const chart = new Chart(ctx, {
          data: {
            labels: equipCharts[i].map((x) => x.timestamp),
            datasets: [
              {
                fill: true,
                borderWidth: 2,
                backgroundColor: gradient,
                label: equipCharts[i][0].name,
                data: equipCharts[i].map((x) => (x.isOcc ? x.occPer : x.avg)),
              },
            ],
          },
          type: "line",
          options: {
            tooltips: {
              callbacks: {},
            },
            maintainAspectRatio: false,
            scales: {
              x: {
                grid: {
                  display: false,
                },
                ticks: {
                  callback: (value, index, values) => {
                    return tConvert(value < 9 ? `0${value}:00` : `${value}:00`);
                  },
                },
              },
              y: {
                grid: {
                  display: false,
                },
                ticks: {
                  callback: (value, index, values) => {
                    return equipCharts[i] &&
                      equipCharts[i][index] &&
                      equipCharts[i][index].unit
                      ? "%"
                      : null;
                  },
                },
              },
            },
          },
        });

        setChartInst(chart);
      }
    } catch (e) {
      console.log(e);
    }
  }, [equipCharts]);

  if (!equipCharts) {
    return <h1 className="text-center">No Data available</h1>;
  }
  return (
    <>
      <Modal
        open={errorModal && !loadingSomething}
        setOpen={setErrorModal}
        modalHeading="No data available"
        modalText="We are having trouble, fetching data at this time stamp"
        modalType="error"
        modalIcon={<BiErrorAlt className="" />}
      />
      <div id="charts" className="multi-charts">
        {loadingSomething && (
          <div className="w-full justify-center items-center">
            <Loader center size="120px" />
          </div>
        )}

        {equipCharts.map((chart, idx) =>
          chart.length ? (
            <div className="chart">
              <div className="w-full" style={{ height: "200px" }}>
                <canvas id={`equimentchart-${idx}`}></canvas>
              </div>
            </div>
          ) : null
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    errorModal: state.buildings.errorModal,
  };
};

const mapDispatchToProps = {
  setErrorModal: setErrorModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(MultipleCharts);
